function UserStats({ stats, title, change }) {
  const getPercentageChange = () => {
    if (!change) return;

    let symbol;

    if (change === "yesterday") {
      symbol = stats?.change_percentage_day > 0 ? "+" : "-";
      return `${symbol}${stats?.change_percentage_day}`;
    } else {
      symbol = stats?.change_percentage_week > 0 ? "+" : "-";
      return `${symbol}${stats?.change_percentage_week}`;
    }
  };

  return (
    <div className="users-panel">
      <h3>{title}</h3>

      <div className="panel-details">
        <div className="total-number">{stats?.total}</div>

        <div className="variation">{`${getPercentageChange()}% of ${change}`}</div>
      </div>
    </div>
  );
}

export default UserStats;
