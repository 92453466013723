import downArrow from "../../Team/TableTopicsAccess/down-arrow.svg";
import { useState } from "react";

function MessageColumn({ message }) {
  const [showFullMessage, setShowFullMessage] = useState(false);

  return (
    <div className="message-column">
      <span className={`${showFullMessage && "show-full-text"}`}>
        {message}
      </span>
      <div className="show-more">
        <div
          className={`show-more-button ${showFullMessage && "open-button"}`}
          role="button"
          onClick={() => setShowFullMessage(!showFullMessage)}
        >
          <img src={downArrow} alt="" />
        </div>
      </div>
    </div>
  );
}

export default MessageColumn;
