import errorImage from "./error-image.png";

function ResetPassword() {
  return (
    <div className="not-found child-component">
      <div className="error-image">
        <img src={errorImage} alt="404 error"/>
      </div>

      <h1 className="section-title less-padding">PAGE NOT FOUND</h1>

      <div className="main-content">
        <span>The Page you are looking for doesn’t exist or another error occured.</span>
      </div>
    </div>
  )
}

export default ResetPassword;