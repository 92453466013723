import { getMediaLetter } from "../../CardAssets/QuestionOptions";
import { sourceUrl } from "@/utils/utils";

function ChooseImagePreview ({ card }) {
  return (
    <div className="preview-option">
      <div className="preview-multi-image-section">
        {card?.options?.map((option, i) => {
          return (
            <div className="preview-multi-media" key={i}>
              <img src={sourceUrl(option?.url || option.value)} alt="" />

              <span>{getMediaLetter(i + 1).toLowerCase()}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ChooseImagePreview;
