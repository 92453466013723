import "./PersonalData.css";
import Switch from "@/components/Fields/Switch";

function PersonalData({ memberData, roles, setCanAddTopic }) {
  const getRoleLabel = () => {
    return roles?.find((role) => role.value === memberData?.role)?.label;
  };

  return (
    <div className="user-details">
      <div className="details-item">
        <span className="label">First Name</span>
        <span className="value">{memberData?.first_name ?? "-"}</span>
      </div>

      <div className="details-item">
        <span className="label">Last Name</span>
        <span className="value">{memberData?.last_name ?? "-"}</span>
      </div>

      <div className="details-item">
        <span className="label">Role</span>
        <span className="value">{getRoleLabel() ?? "-"}</span>
      </div>

      <div className="details-item">
        <span className="label">Email</span>
        <span className="value">{memberData?.email ?? "-"}</span>
      </div>

      <div className="topic-adding-section details-item">
        <span className="label">Topic Adding</span>

        <div className="switch-container">
          <Switch
            checked={memberData?.can_add_topic}
            // disabled={memberData?.role === 'super-admin'}
            name="can_add_topic"
            onChange={() => setCanAddTopic(!memberData?.can_add_topic)}
          />
        </div>
      </div>
    </div>
  );
}

export default PersonalData;
