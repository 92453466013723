import "./Stats.css";
import Charts from "./Charts/Charts";
import { useCallback, useContext, useEffect, useState } from "react";
import axios from '@/services/axios';
import { ErrorContext } from "../../../App";
import Loading from "../../Loading/Loading";
import UserStats from "./UserStats/UserStats";
import H2 from "@/components/headings/H2";
import Header from "@/components/Header/Header";
import H1 from "@/components/headings/H1";
import { useTranslation } from "react-i18next";

function Stats() {
  const [userStats, setUserStats] = useState(null);
  const [loading, setLoading] = useState(false);
  const { setErrors } = useContext(ErrorContext);
  const {t} = useTranslation()
  const getStats = useCallback(() => {
    setLoading(true);

    axios
      .get(`/stats`)
      .then((response) => {
        setUserStats(response.data);
      })
      .catch((_) => {
        setErrors(["Something went wrong. Please, try again."]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setUserStats, setLoading, setErrors]);

  useEffect(() => {
    if (!userStats) {
      getStats();
    }
  }, [userStats, getStats]);

  if (loading) return <Loading />;

  return (
    <div className="admin-panel-item stats">
      <div className="users">
        <Header>
        <H1>
          {t('nav.statistics')}
        </H1>
        <div className="invisible h-[50px]"></div>
        </Header>
        <H2>{t('statistics.users')}</H2>

        <div className="user-section">
          <UserStats
            stats={userStats?.users}
            title={t('statistics.new_users')}
            change={t('statistics.last_week')}
          />
          <UserStats
            stats={userStats?.app_downloads}
            title={t('statistics.app_downloads')}
            change={t('statistics.yesterday')}
          />
          <UserStats
            stats={userStats?.premium_subscribers}
            title={t('statistics.premium_subscribers')}
            change={t('statistics.yesterday')}
          />
        </div>
      </div>

      <div className="chart-component">
        <Charts />
      </div>
    </div>
  );
}

export default Stats;
