import ButtonBlack from "@/components/buttons/ButtonBlack";
import TextField from "@/components/Fields/TextField";
import Icon from "@/components/Icons/Icon";
import { useState,useEffect,useContext } from "react";
import { ErrorContext } from "@/App";
export default function ExtraWordsItem ({ disabled, value, onChange, onDelete,index }) {
  const { errors } = useContext(ErrorContext);
  const [localError, setLocalError] = useState();
  useEffect(() => {
    if(errors.find((el)=>el.field==="extra"&&el.index===index)){
      setLocalError(errors.find((el)=>el.field==="extra"&&el.index===index))
    }else{
      setLocalError(undefined)
    }
  }, [errors,index])
  
  return (
    <div className="flex gap-2">
      <TextField
        disabled={disabled}
        setValue={onChange}
        value={value}
        localError={localError}
        name={"extra"}
        index={index}
      />
      <div>
        <ButtonBlack className="w-6 h-6 p-1 rounded" onClick={onDelete}>
          <Icon icon="trash" />
        </ButtonBlack>
      </div>
    </div>
  )
}
