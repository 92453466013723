import { cn } from '@/utils/utils';

export default function toggle ({ labelTrue, labelFalse, value, onChange }) {
  return (
    <div>
      <label className="toggle">
        <input type="checkbox" className="toggle__input" checked={value} onChange={onChange} />
        <span className={cn('toggle__slider', value ? 'toggle__slider--true' : 'toggle__slider--false')}></span>
        {labelFalse ? <span className="toggle__label toggle__label--false">{labelFalse}</span> : null}
        {labelTrue ? <span className="toggle__label toggle__label--true">{labelTrue}</span> : null}
      </label>
    </div>
  )
}
