import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { FieldLanguageContext } from '@/context';

import Button from '@/components/buttons/Button';
import ButtonBlack from '@/components/buttons/ButtonBlack';
import ExtraWordsItem from './ExtraWordsItem';

import { v4 as uuidv4 } from 'uuid';

export default function ExtraWords ({ disabled, options, onChange, onConfirm }) {
  const { fieldLanguage } = useContext(FieldLanguageContext);

  const { t } = useTranslation();

  const words = options?.[fieldLanguage] || [];
  const blanks = words?.filter((word) => word.is_blank || word.is_extra) || [];

  if (!blanks.some((word) => word.is_extra)) {
    onChange({ ...options, [fieldLanguage]: [...words, { id: uuidv4(), value: '', is_extra: true }] });
  }

  const add = () => onChange({ ...options, [fieldLanguage]: [...words, { id: uuidv4(), value: '', is_extra: true }] });
  const remove = (index) => onChange({ ...options, [fieldLanguage]: words.filter((_, idx) => idx !== index) });
  const update = (value, index) => onChange({ ...options, [fieldLanguage]: words.map((word, idx) => idx === index ? { ...word, value } : word) });

  return (
    <>
      {blanks && blanks?.length ? (
        <div className="chosen-blanks mb-6">
          {blanks.map((word, index) => (
            word.value ? (
              <div key={index} className="split-word blank">
                {word.value}
              </div>
            ) : null
          ))}
        </div>
      ) : null}

      <div className="space-y-6 mb-6">
        {words ? words.map((word, index) => (
          word.is_extra ? (
              <ExtraWordsItem
                disabled={disabled}
                key={index}
                onChange={(value) => update(value, index)}
                onDelete={() => remove(index)}
                value={word.value}
                index={index}
              />
          ) : null
        )) : null}
      </div>

      {!disabled ? (
        <>
          <ButtonBlack className="block w-full mb-6" onClick={add}>
            + {t('button.add_answer')}
          </ButtonBlack>

          <Button className="block w-full" onClick={() => onConfirm()}>
            {t('button.confirm')}
          </Button>
        </>
      ) : null}
    </>
  )
}
