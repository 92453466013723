import AdminPanel from "../components/AdminPanel/AdminPanel";
import Stats from "../components/AdminPanel/Stats/Stats";
import HOC from "../components/HOC/HOC";

const route = {
  path: "/stats",
  element: <HOC><AdminPanel><Stats /></AdminPanel></HOC>
};

export default route;
