import { useCallback, useEffect, useState } from "react";
import axios from '@/services/axios';
import { useNavigate, useLocation } from "react-router-dom";

let cache = [];

function useTopics({ fullData }) {
  const [topics, setTopics] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const fetchTopics = useCallback(async () => {
    try {
      const response = await axios.get(`/topics`);
      return response.data.topics;
    } catch ({ response }) {
      if (response?.status === 401) {
        navigate("/login");
      }

      return null;
    }
  }, [navigate])

  const getTopics = useCallback(async () => {
    let topics;

    if (cache && cache.length) {
      topics = cache;
    } else {
      topics = await fetchTopics();
      if (topics) {
        cache = topics;
      }
    }

    if (topics) {
      if (!fullData) {
        topics = topics.map((topic) => ({
          value: topic.id,
          label: topic.name,
          subtopics: topic?.subtopics?.map((subtopic) => ({
            value: subtopic.id,
            label: subtopic.name,
          })),
        }));
      }
    }

    setTopics(topics);
  }, [fetchTopics, fullData]);

  useEffect(() => {
    cache = [];
    getTopics();
  }, [location, getTopics]);

  return topics;
}

export default useTopics;
