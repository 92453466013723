import Dropdown from "../../../AdminPanelAssets/Dropdown/Dropdown";
import SelectIcon from "../SelectIconModal/SelectIcon";
import {
  COLORS,
  TYPES,
} from "../../TopicsAssets/TopicConstantValues/TopicConstantValues";
import MediaSelectorButton from "../../TopicsAssets/MediaSelectorButton/MediaSelectorButton";
import { icons } from "../../TopicsAssets/TopicIcons/Icons";
import { useContext, useEffect, useState } from "react";
import { ModalContext } from "../../../../../App";
import EditTopic from "../EditTopic/EditTopic";
import AddTopic from "../AddTopic/AddTopic";
import editIcon from "../../../AdminPanelAssets/Icons/editIcon.svg";
import { getIcon } from "../../TopicsAssets/Utils/topicUtils";
import TextField from '@components/Fields/TextField';
import { useTranslation } from "react-i18next";
import ButtonMain from "@/components/buttons/ButtonMain";
import Button from "@/components/buttons/Button";

function ModalContent({
  buttonText,
  currentTopic,
  onSubmit,
  updateTopic,
  createdTopic,
}) {
  const [topic, setTopic] = useState(null);
  const { setModal } = useContext(ModalContext);
  const { t } = useTranslation();

  const openSelectIconModal = () => {
    setModal(
      <SelectIcon
        onIconSelect={onIconSelect}
        icons={icons}
        topic={topic}
        parent={currentTopic ? "edit" : "add"}
        updateTopic={updateTopic}
        selectedColor={topic?.color || null} // Pass the selected color here
      />,
    );
  };

  const onIconSelect = (topic, icon) => {
    if (currentTopic) {
      setModal(
        <EditTopic
          topic={{ ...topic, icon }}
          updatedIcon={icon}
          updateTopic={updateTopic}
        />,
      );
    } else {
      setModal(
        <AddTopic createdTopic={{ ...topic, icon }} updatedIcon={icon} />,
      );
    }
  };

  const handleColorSelection = (selectedValues) => {
    setTopic({ ...topic, color: selectedValues[0] });
  };

  const handleTypeSelection = (selectedValues) => {
    setTopic({ ...topic, type: selectedValues[0] });
  };

  useEffect(() => {
    if (!topic) {
      if (currentTopic) {
        setTopic(currentTopic);
      } else if (createdTopic) {
        setTopic(createdTopic);
      }
    }
  }, [topic, setTopic, currentTopic, createdTopic]);

  return (
    <div className="topic-modal-content">
      <div className="input-section">
        <form method="post" onSubmit={(e) => onSubmit(e, topic, "active")}>
          <div className="input-forms space-y-4">
            <TextField
              label={t('field.topic_name')}
              name="topic-name"
              placeholder={t('field.topic_name')}
              translatable
              value={topic?.name}
              setValue={(newValue) => setTopic({ ...topic, name: newValue})}
            />

            <TextField
              label={t('field.description')}
              name="description"
              placeholder={t('field.description')}
              translatable
              value={topic?.description}
              setValue={(newValue) => setTopic({ ...topic, description: newValue})}
            />

            <div className="field-group">
              {currentTopic && <label>Type</label>}

              <Dropdown
                options={TYPES}
                selectedValues={topic?.type ? [topic.type] : []}
                onChange={handleTypeSelection}
                allowMultipleSelections={false}
                defaultLabel="Type"
              />
            </div>

            <div className="field-group">
              {currentTopic && <label>Color</label>}

              <Dropdown
                options={COLORS}
                selectedValues={topic?.color ? [topic.color] : []}
                onChange={handleColorSelection}
                allowMultipleSelections={false}
                defaultLabel="Color"
              />
            </div>
          </div>

          <div className="topic-icon-section">
            <span>Topic icon:</span>

            {topic?.icon ? (
              <div className="topic-selected-icon">
                <img src={getIcon(topic?.icon)} alt="" />

                <button
                  type="button"
                  className="edit-topic-icon"
                  onClick={openSelectIconModal}
                >
                  <img src={editIcon} alt="" />
                </button>
              </div>
            ) : (
              <MediaSelectorButton onClick={openSelectIconModal} />
            )}
          </div>

          <ButtonMain
            className="w-full mb-6"
            type="submit"
          >
            {buttonText}
          </ButtonMain>

          <div className="flex mb-6">
            <Button
              className="mx-auto"
              onClick={(e) => onSubmit(e, topic, "draft")}
            >
              {t('button.save_draft')}
            </Button>
          </div>

          <div className="flex">
            <Button
              className="mx-auto"
              onClick={() => setModal(null)}
            >
              {t('button.cancel')}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ModalContent;
