import TextField from '@components/Fields/TextField';
import ButtonBlack from '@components/buttons/ButtonBlack';
import Icon from '@components/Icons/Icon';

export default function QuestionFormOption ({ className, disabled, onChange, onDelete, value,index }) {
  // className={`${!isEditing && fixedAnswers && value.length > 0 ? 'letter-answer' : ''}`}
  // disabled={!isEditing}
  // name={`answer-${index}`}
  // placeholder={`${!fixedAnswers ? t('placeholder.answer_number', { number: index + 1 }) : `${getMediaLetter(index + 1)}.`}`}
  // setValue={handleInput}

  return (
     <div className="flex gap-2">
      <TextField
        className={className}
        disabled={disabled}
        translatable
        value={value}
        setValue={onChange}
        index={index}
        name={"option"}
      />
      {!disabled ? (
        <div>
          <ButtonBlack className="w-6 h-6 p-1 rounded" onClick={onDelete}>
            <Icon icon="trash" />
          </ButtonBlack>
        </div>
      ) : null}
    </div>
  )
}
