import useAuthenticationStatus from "../../hooks/useAuthenticationStatus";
import SidebarNavigation from "./SidebarNavigation/SidebarNavigation";
import "./AdminPanel.css";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../Loading/Loading";
import axios from '@/services/axios';
import { ErrorContext, UserContext } from "../../App";

function AdminPanel({ children }) {
  const authenticationStatus = useAuthenticationStatus();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { setErrors } = useContext(ErrorContext);
  const { personalData, setPersonalData } = useContext(UserContext);

  // const getValue = (array, key) => {
  //   return array.find((element) => element.name === key)?.entry;
  // };

  const getPersonalData = useCallback(() => {
    axios
      .get(`/account/info`)
      .then((response) => {
        // const info = response?.data?.info;
        const data = {
          firstName: response?.data?.first_name ?? null,
          lastName: response?.data?.last_name ?? null,
          position: response?.data?.role ?? null,
        };

        setPersonalData(data);
      })
      .catch(({ response }) => {
        if (typeof response?.data?.errors !== "undefined") {
          let errs = [];

          for (const error in response.data.errors) {
            errs.push([...response.data.errors[error]]);
          }

          setErrors(errs);
        }
      });
  }, [setErrors, setPersonalData]);

  useEffect(() => {
    const validateAuth = () => {
      if (authenticationStatus === "NOT-OK") {
        navigate("/login");
      } else {
        setLoading(false);

        if (!personalData) {
          getPersonalData();
        }
      }
    };

    validateAuth();
  }, [authenticationStatus, navigate, personalData, getPersonalData]);

  if (loading) return <Loading />;

  return (
    <div className="admin-panel">
      <SidebarNavigation />

      <div className="admin-panel-content h-full mb-16">{children}</div>
    </div>
  );
}

export default AdminPanel;
