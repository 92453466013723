import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { UserContext } from "../App";

function Index() {
  const navigate = useNavigate();
  const { setAuthToken } = useContext(UserContext);

  useEffect(() => {
    if (localStorage.getItem("auth_token")) {
      setAuthToken(localStorage.getItem("auth_token"));
      navigate("/stats");
    } else {
      navigate("/login");
    }
  });

  return null;
}

const route = {
  path: "/",
  element: <Index />,
};

export default route;
