import "./TopicInfo.css";
import useTranslatableContent from "@hooks/useTranslatableContent";
import { useTranslation } from "react-i18next";
import { cn } from "@/utils/utils";

function TopicInfo ({ className, topic, icon, onStatusChange }) {
  const { t } = useTranslation();
  const maybeTranslate = useTranslatableContent();
  return (
    <div
      className={cn('topic-details-section', className)}
      style={{ background: `var(--secondary-${topic?.color || 'blue'})` }}
    >
      <div className="topic-icon">
        <img src={icon} alt="" />
      </div>

      <div className="topic-info">
        <div className="item">
          <span className="item-label">{t('field.name')}:</span>
          <span className="item-value uppercase">{maybeTranslate(topic.name)}</span>
        </div>

        <div className="item">
          <span className="item-label">{t('field.description')}:</span>
          <span className="item-value">{maybeTranslate(topic.description)}</span>
        </div>

        {topic?.parent_id
          ? (
            <div className="item">
              <span className="item-label">{t('field.cards_count')}:</span>
              <span className="item-value">{topic?.cards_count || 0}</span>
            </div>
          )
            : (
            <div className="item">
              <span className="item-label">{t('field.subtopics_count')}:</span>
              <span className="item-value">{topic?.subtopics_count || 0}</span>
            </div>
          )
        }

        <div className="item">
          <span className="item-label">{t('field.type')}:</span>
          <span className="item-value uppercase">{topic.type}</span>
        </div>

        <div className="item">
          <span className="item-label">{t('field.color')}:</span>
          <span className="item-value uppercase">{topic.color}</span>
        </div>
      </div>

      <div className="topic-status">
        <div className="status-buttons">
          <button
            type="button"
            onClick={() => onStatusChange("draft")}
            className={`text-center ${topic.status === 'draft' ? 'active-button' : ''}`}
          >
            {t('button.draft')}
          </button>
          <button
            type="button"
            className={`text-center ${topic.status === 'active' ? 'active-button' : ''}`}
            onClick={() => onStatusChange("active")}
          >
            {t('button.active')}
          </button>
        </div>
      </div>
    </div>
  );
}

export default TopicInfo;
