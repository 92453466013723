import logo from "../../../assets/logo_black.png";
import "./SidebarNavigation.css";
import notificationsIcon from "./notifications.svg";
import settingsIcon from "./settings.svg";
import statisticsIcon from "./statistics.svg";
import teamIcon from "./team.svg";
import topicsIcon from "./topics.svg";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "@/App";
import { useTranslation } from "react-i18next";

function SidebarNavigation() {
  const [activeItem, setActiveItem] = useState("stats");
  const { personalData } = useContext(UserContext);
  const navigate = useNavigate();
  const currentRoute = useLocation();

  const { t } = useTranslation();

  useEffect(() => {
    const updatedRoute = currentRoute.pathname.slice(1);
    setActiveItem(updatedRoute);
  }, [currentRoute]);

  const onItemClick = (itemName) => {
    setActiveItem(itemName);
    navigate(`/${itemName}`);
  };

  const getName = () => {
    if (!personalData?.firstName) return;

    return `${personalData.firstName} ${personalData.lastName ?? ""}`;
  };

  const getInitials = () => {
    const firstNameParts = personalData.firstName?.split(" ") ?? [];
    const lastNameParts = personalData.lastName?.split(" ") ?? [];

    if (firstNameParts.length && lastNameParts.length) {
      return `${firstNameParts[0].charAt(0)}${lastNameParts[0].charAt(0)}`;
    }

    if (firstNameParts.length) {
      return `${firstNameParts[0].charAt(0)}`;
    }

    return "";
  };

  return (
    <div className="side-navigation">
      <div className="navigation-logo cursor-pointer" onClick={() => onItemClick("stats")}>
        <img className="logo" src={logo} alt="Learn Med Pro" />
      </div>

      <div className="nav-items">
        <div
          className={`nav-item ${
            activeItem.includes("stats") && "active-item"
          }`}
          onClick={() => onItemClick("stats")}
        >
          <img className="icon" src={statisticsIcon} alt="Statistics Icon" />
          <span className="item-name" role="button">
            {t('nav.statistics')}
          </span>
        </div>

        <div
          className={`nav-item ${
            activeItem.includes("topics") && "active-item"
          }`}
          onClick={() => onItemClick("topics")}
        >
          <img className="icon" src={topicsIcon} alt="Topics Icon" />
          <span className="item-name" role="button">
            {t('nav.topics')}
          </span>
        </div>

        <div
          className={`nav-item ${
            activeItem.includes("settings") && "active-item"
          }`}
          onClick={() => onItemClick("settings")}
        >
          <img className="icon" src={settingsIcon} alt="Settings Icon" />
          <span className="item-name" role="button">
            {t('nav.settings')}
          </span>
        </div>

        <div
          className={`nav-item ${activeItem.includes("team") && "active-item"}`}
          onClick={() => onItemClick("team")}
        >
          <img className="icon" src={teamIcon} alt="Team Icon" />
          <span className="item-name" role="button">
            {t('nav.team')}
          </span>
        </div>

        <Link
          to="/users"
          className={`nav-item ${activeItem.includes("user") && "active-item"}`}
        >
          <img className="icon" src={teamIcon} alt="Team Icon" />
          <span className="item-name" role="button">
            {t('nav.users')}
          </span>
        </Link>

        <div
          className={`nav-item ${
            activeItem.includes("notifications") && "active-item"
          }`}
          onClick={() => onItemClick("notifications")}
        >
          <img
            className="icon"
            src={notificationsIcon}
            alt="Notifications Icon"
          />
          <span className="item-name" role="button">
            {t('nav.notifications')}
          </span>
        </div>
      </div>

      <div className="name-container">
        {personalData?.firstName && (
          <div className="user-name-section">
            <span className="initials">{getInitials()}</span>
            <span className="user-name">{getName()}</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default SidebarNavigation;
