import { useTranslation } from 'react-i18next';

import ListItem from './ListItem';
import Table, { TableCol, TableRow } from '@/components/AdminPanel/AdminPanelAssets/Table/Table';

export default function List ({ users }) {
  const { t } = useTranslation();

  return users?.length
    ? (
      <div className="members-table">
        <Table>
          <thead>
            <TableRow>
              <TableCol heading={true}>{t('list.image')}</TableCol>
              <TableCol heading={true}>{t('list.name')}</TableCol>
              <TableCol heading={true}>{t('list.paid')}</TableCol>
              <TableCol heading={true}>{t('list.email')}</TableCol>
              <TableCol heading={true}>{t('list.registered_at')}</TableCol>
            </TableRow>
          </thead>

          <tbody>
            {users.map((user) => (
              <ListItem key={user.id} user={user} />
            ))}
          </tbody>
        </Table>
      </div>
    )
    : (
      <div className="no-team">{t('text.no_users_found')}</div>
    );
};
