import "../TopicModalContent/ModalContent.css";
import ModalContent from "../TopicModalContent/ModalContent";
import { useContext, useState } from "react";
import axios from '@/services/axios';
import Loading from "../../../../Loading/Loading";
import { ErrorContext, ModalContext, ParentIdContext } from "@/App";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "@/components/LanguageSwitcher/LanguageSwitcher";


function EditTopic({ topic, updateTopic }) {
  const [loading, setLoading] = useState(false);
  const { setErrors } = useContext(ErrorContext);
  const { setModal } = useContext(ModalContext);
  const { parentId } = useContext(ParentIdContext);

  const { t } = useTranslation();

  const showInfoToUser = (message) => toast.success(message);

  const saveChanges = (e, topic, status) => {
    e.preventDefault();
    setLoading(true);

    const topicToSave = { ...topic, status };

    axios
      .put(`/topics/${topic.id}`, topicToSave)
      .then((response) => {
        if (response.status === 200) {
          setModal(null);
          updateTopic(topicToSave);
          showInfoToUser("The topic changes were saved!");
        }
      })
      .catch((err) => {
        if (typeof err.response === "undefined") {
          setErrors(["Something went wrong ... please try again."]);
        }

        if (typeof err.response?.data.errors !== "undefined") {
          let errs = [];

          for (const error in err.response.data.errors) {
            errs.push([...err.response.data.errors[error]]);
          }

          setErrors(errs);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) return <Loading />;

  return (
    <div className="modal-form-wrapper">
      <div className="col-start-2 add-topic-view content-view">
        <h1>{t(parentId ?  'heading.edit_subtopic' : 'heading.edit_topic')}</h1>

        {topic && (
          <ModalContent
            buttonText={"save changes"}
            currentTopic={topic}
            onSubmit={saveChanges}
            updateTopic={updateTopic}
          />
        )}
      </div>
      <div className="col-start-3">
        <LanguageSwitcher />
      </div>
    </div>

  );
}

export default EditTopic;
