import "./PreviewContent.css";
import { useTranslation } from 'react-i18next';

function DragDropPreview ({ card }) {
  const { i18n } = useTranslation();

  const options = card?.options?.[i18n.language] || [];

  return (
    <div className="preview-option">
      <div className="drag-drop-container">
        <div className="full-text">
          <span>
            {options.map((option, index) =>
              option.is_blank
                ? (
                  <span key={index} className="blank-space"></span>
                )
                : (
                  <span key={index} className="original-word">
                    {option.original}
                  </span>
                )
            )}
          </span>
        </div>

        <div className="preview-blanks">
          {options.map((option, index) =>
            option.is_blank || option.is_extra
              ? (
                <div key={index} className="preview-blank">
                  { option.value }
                </div>
              ) : null
          )}
        </div>
      </div>
    </div>
  );
}

export default DragDropPreview;
