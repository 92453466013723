import { useContext,useEffect,useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FieldLanguageContext } from '@/context';

import Button from '@/components/buttons/Button';
import { ErrorContext } from '@/App';
import { cn } from '@/utils/utils';

export default function BlankWordSelect ({ disabled, options, onChange, onConfirm }) {
  const { fieldLanguage } = useContext(FieldLanguageContext);
  const { t } = useTranslation();
  const { errors } = useContext(ErrorContext);
  const [localError, setLocalError] = useState();
  const toggle = (index) => {
    if (disabled) return;
    setLocalError(undefined)
    const newOptions = options[fieldLanguage]?.map((option, idx) => idx === index ? { ...option, is_blank: !option.is_blank } : option);

    console.log('%cNew options: ', 'color: orange;', { ...options, [fieldLanguage]: newOptions });

    onChange({ ...options, [fieldLanguage]: newOptions });
  };
  useEffect(() => {
    console.log(options)
  }, [options])
  useEffect(() => {
    if(errors.find((el)=>el.lng===fieldLanguage&&el.field==="answer")){
      setLocalError(errors.find((el)=>el.lng===fieldLanguage&&el.field==="answer"))
    }
  }, [errors,fieldLanguage])
  
  return (
    <>
      {localError&&
      <p className='text-red-700'>Please select a blank option</p>
      }
      {options?.[fieldLanguage]?.length
        ? (
          <div className={cn('blanks-grid', disabled ? 'editing-blanks' : null)}>
            {options[fieldLanguage].map((word, index) => !word?.is_extra ? (
              <button
                type="button"
                key={index}
                className={cn('split-word', word.is_blank ? 'selected' : null, disabled ? 'disabled-blanks' : null)}
                onClick={() => toggle(index)}
                disabled={disabled}
              >
                {word.value}
              </button>
            ) : null)}
          </div>
        ) : null}

      {!disabled ? (
        <div className="flex">
          <Button className="mx-auto" onClick={onConfirm}>
            {t('button.confirm')}
          </Button>
        </div>
      ) : null}
    </>
  )
}
