import React from 'react';

import IconBook from './IconBook';
import IconClose from './IconClose';
import IconEdit from './IconEdit';
import IconFlip from './IconFlip';
import IconPen from './IconPen';
import IconTrash from './IconTrash';

const icons = {
  book: IconBook,
  close: IconClose,
  edit: IconEdit,
  flip: IconFlip,
  pen: IconPen,
  trash: IconTrash,
}

export default function Icon ({ icon, ...props }) {
  if (typeof icons[icon] !== 'undefined') {
    return React.createElement(icons[icon], props);
  }

  return React.createElement(
    () => <span>The icon {icon} not found!</span>,
  );
}
