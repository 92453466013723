import { cn } from '@/utils/utils';
import './headings.css';

export default function H1 ({ children, className }) {
  const excluded = /h[1-5]/.test(className);
  const computedClassName = excluded ? className : cn('h1', className);
  return (
    <h1 className={computedClassName}>{children}</h1>
  )
}
