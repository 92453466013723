import { useTranslation } from 'react-i18next';

import ProfileDetail from './ProfileDetail';

export default function Profile ({ className, user }) {
  const { t } = useTranslation();

  return (
    <div className="space-y-4">
      <ProfileDetail label={t('field.first_name')}>{user.first_name}</ProfileDetail>
      <ProfileDetail label={t('field.last_name')}>{user.last_name}</ProfileDetail>
      <ProfileDetail label={t('field.email')}>{user.email}</ProfileDetail>
      <ProfileDetail label={t('field.registered_at')}>{user.created_at}</ProfileDetail>
    </div>
  )
}
