import { useEffect } from "react";
import Highcharts from "highcharts";
import "./Charts.css";
function LineChart({sessionsButtonActive}) {
  useEffect(() => {
    Highcharts.chart("line-chart-container", {
      chart: {
        renderTo: "line-chart-container",
        type: "line",
        spacingBottom: -30,
        spacingTop: 20,
        spacingLeft: 0,
        borderRadius: 16,
      },
      plotOptions:{
        line:{
          marker:{
            fillColor:"#1E1E1E",
            radius:6,
          }
        }
      },
      xAxis: {
        categories: sessionsButtonActive==="week"?["M", "T", "W", "T", "F", "S", "S"]:["Week 1","Week 2","Week 3","Week 4","Week 5"],
        lineWidth: 0,
        labels: {
          distance: 14,
          style: {
            color: "#1E1E1E",
            fontSize: 16,
          },
        },
      },
      yAxis: {
        labels: {
          formatter: function () {
            // Total minutes from the data
            const totalMinutes = this.value;

            // Calculate hours and minutes
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;

            // Format as HH:mm
            if(hours!==0){

              return `${hours}h ${minutes}min`;
            }else{
              return `${minutes}min`
            }
          },
          style: {
            color: "#1E1E1E",
            fontSize: 16,
          },
        },
      },
      series: [
        {
          data: sessionsButtonActive==="week"?[10, 45, 90, 15, 120, 30, 10]:[30, 70, 150, 200, 160,],
          marker: {
            symbol: 'url(./chartNode.svg)',
            width: 12,
            height: 12
        },
        },
      ],
      tooltip: {
        enabled: false,
      },
    });
  }, [sessionsButtonActive]);

  return (
    <div className="line-chart">
      <div
        id="line-chart-container"
        style={{ width: "100%", height: "286px" }}
      ></div>
    </div>
  );
}

export default LineChart;
