import { cn } from '@/utils/utils';
import './panel.css';

export default function Panel({ children, className }) {
  return (
    <div className={cn('panel', className)}>
      {children}
    </div>
  );
}
