import { useTranslation } from 'react-i18next';

import AnswerListItem from '../Image&MultipleOpenEnded/AnswerListItem';
import Button from '@/components/buttons/Button';
import ButtonBlack from '@/components/buttons/ButtonBlack';

import { v4 as uuidv4 } from 'uuid';

export default function ExtraAnswers ({ disabled, options, onChange, onConfirm }) {
  const { t } = useTranslation();

  const add = () => onChange([ ...options, { id: uuidv4(), value: '', is_extra: true }]);
  const remove = (index) => onChange(options.filter((_, idx) => idx !== index));
  const update = (value, index) => onChange(options.map((option, idx) => idx === index ? { ...option, value } : option) );

  return (
    <>
      <div className="space-y-6 mb-6">
        {options ? options.map((options, index) => (
          options.is_extra ? (
            <AnswerListItem
              disabled={disabled}
              key={index}
              onChange={(value) => update(value, index)}
              onDelete={() => remove(index)}
              value={options.value}
            />
          ) : null
        )) : null}
      </div>

      {!disabled ? (
        <>
          <ButtonBlack className="block w-full mb-6" onClick={add}>
            + {t('button.add_answer')}
          </ButtonBlack>

          <Button className="block w-full" onClick={() => onConfirm()}>
            {t('button.confirm')}
          </Button>
        </>
      ) : null}
    </>
  )
}
