import { useEffect, useState } from 'react';
import axios from '@/services/axios';

export default function useUser () {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState(null);

  useEffect(() => {
    if (id === null) return;

    setLoading(true);

    const controller = new AbortController();

    axios.get(`/users/${id}`, { signal: controller.signal })
      .then((response) => {
        console.log('%cResponse', 'color: orange', response);
        setUser(response.data);
      })
      .catch((error) => {
        console.error('%cError', 'color: red', error);
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      controller.abort();
    }
  }, [id]);

  return {
    user,
    loading,
    setId
  }
}
