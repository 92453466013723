import Guard from "../components/Guard/Guard";
import CreatePassword from "../components/Guard/CreatePassword/CreatePassword";
import HOC from "../components/HOC/HOC";

const route = {
  path: "/create-password/:token",
  element: (
    <HOC>
      <Guard>
        <CreatePassword />
      </Guard>
    </HOC>
  ),
};

export default route;
