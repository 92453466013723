/**
   * Creates a form data object from the given data
   *
   * @param {Object} data
   *
   * @returns {FormData}
   */
export function createFormData (data) {
  console.log('%cPreparing form data', 'color: lime')
  const payload = {}
  for (let key in data) {
    let value = data[key]

    if (key === 'images' || key === 'files') {
      continue
    }

    payload[key] = value
  }

  const formData = new FormData()

  const imagesToDelete = []
  let index = 0
  if (data.images && data.images.length > 0) {
    data.images.forEach((image) => {
      console.log('%cPreparing image', 'color: lime')
      if (image instanceof File) {
        formData.append(`images[${index}]`, image)
        index++
        return
      }

      if (image.delete) {
        imagesToDelete.push(image.name)
      }
    });
  }

  if (imagesToDelete.length > 0) {
    payload.deleted_images = imagesToDelete
  }

  const filesToDelete = []
  index = 0
  if (data.files && data.files.length > 0) {
    console.log('%cPreparing file', 'color: lime')
    data.files.forEach((file) => {
      if (file instanceof File) {
        formData.append(`files[${index}]`, file)
        index++
        return
      }

      if (file.delete) {
        filesToDelete.push(file.name)
      }
    });
  }

  if (filesToDelete.length > 0) {
    payload.deleted_files = filesToDelete
  }

  formData.append('payload', JSON.stringify(payload))
  console.log('%cForm data ready', 'color: lime')
  return formData
}
