import addIcon from "./addIcon.svg";
import "./MediaSelectorButton.css";

function MediaSelectorButton({ onClick }) {
  return (
    <button type="button" className="media-selection-button" onClick={onClick}>
      <div className="add-media">
        <img src={addIcon} alt="" />
      </div>
    </button>
  );
}

export default MediaSelectorButton;
