import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useTranslatableContent from '@/hooks/useTranslatableContent'

import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog'
import Button from '@/components/buttons/Button'
import Icon from '@/components/Icons/Icon'

export default function ButtonDelete ({ name, onProceed }) {
  const [isOpened, setIsOpened] = useState(false)

  const { t } = useTranslation()
  const maybeTranslate = useTranslatableContent()

  return (
    <>
      <Button className="mx-auto" onClick={() => setIsOpened(true)}>
        <Icon className="w-5 h-5" icon="trash" />
        {t('button.delete_user')}
      </Button>
      <ConfirmationDialog
        isOpened={isOpened}
        onClose={() => setIsOpened(false)}
        onProceed={onProceed}
      >
        {t('confirm.delete_user', { name: maybeTranslate(name) })}
      </ConfirmationDialog>
    </>
  )
}
