import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Highcharts from "highcharts";
import "./Charts.css";
//Note to BE. 0 values should be returned as null
function ColumnChart({statsButtonActive}) {
  const [installDataset, setInstallDataset] = useState(undefined);
  const [uninstallDataset, setUninstallDataset] = useState(undefined);
  const [max, setMax] = useState(undefined);
  const {t} = useTranslation()
  useEffect(() => {
    //todo: Fetch from analytics

    let maxValueUninstall
    let maxValueInstall
    if(statsButtonActive==="week"){
      maxValueInstall = Math.max(...[1, null, 4, 6, 8, 5, 12]);
      maxValueUninstall = Math.max(...[5, 7, 3, 3, null, 4, 2]);
      setInstallDataset([1, null, 4, 6, 8, 5, 12]);
      setUninstallDataset([5, 7, 3, 3, null, 4, 2]);
    }else{
      maxValueInstall = Math.max(...[10, 30, 25, 40]);
      maxValueUninstall = Math.max(...[1, 3, 5, null, ]);
      setInstallDataset([10, 30, 25, 40]);
      setUninstallDataset([1, 3, 5, null, ]);
    }

    if (maxValueInstall > maxValueUninstall) {
      setMax(maxValueInstall);
    } else {
      setMax(maxValueUninstall);
    }
  }, [statsButtonActive]);

  useEffect(() => {
    if (installDataset && uninstallDataset && max) {
      console.log("Creating chart");
      Highcharts.chart("column-chart-container", {
        chart: {
          renderTo: "column-chart-container",
          type: "column",
          spacingBottom: -30,
          spacingTop: 20,
          spacingLeft: 0,
          borderRadius: 16,
          events: {
            render: function () {
              var chart = this;
              chart.series.forEach(function (series) {
                if (series.type === "column") {
                  series.points.forEach(function (point) {
                    var rect = point.graphic;
                    if (rect) {
                      var yAxis = chart.yAxis[0];
                      console.log(series.name,yAxis.height- (yAxis.top - point.plotY))
                      var radius = 5; // Radius for rounding
                      var width = rect.width;
                      var height = series.name.startsWith("Background")
                        ? yAxis.height- (yAxis.top - point.plotY)
                        : rect.height;
                      var x = rect.x;
                      var y = series.name.startsWith("Background")
                        ? yAxis.top
                        : rect.y;
                      var roundRect = [
                        "M",
                        x + radius,
                        y,
                        "L",
                        x + width - radius,
                        y,
                        "C",
                        x + width - radius / 2,
                        y,
                        x + width,
                        y + radius / 2,
                        x + width,
                        y + radius,
                        "L",
                        x + width,
                        y + height - radius,
                        "C",
                        x + width,
                        y + height - radius / 2,
                        x + width - radius / 2,
                        y + height,
                        x + width - radius,
                        y + height,
                        "L",
                        x + radius,
                        y + height,
                        "C",
                        x + radius / 2,
                        y + height,
                        x,
                        y + height - radius / 2,
                        x,
                        y + height - radius,
                        "L",
                        x,
                        y + radius,
                        "C",
                        x,
                        y + radius / 2,
                        x + radius / 2,
                        y,
                        x + radius,
                        y,
                        "Z",
                      ];

                      rect.attr({
                        d: roundRect.join(" "),
                      });
                    }
                  });
                }
              });
            },
          },
        },
        // legend: {
        //   align: "right",
        //   verticalAlign: "top",
        //   layout: "horizontal",
        //   floating: true,
        //   x: -10,
        //   y: -20,

        // },
        plotOptions: {
          column: {
            grouping: true,
            pointPadding: 0.1,
            borderWidth: 0,
            pointWidth: 16,
            borderRadius: 5,
          },
        },
        xAxis: {
          categories:statsButtonActive==="week"? [
            t('statistics.weekdays_monday_headline'),
            t('statistics.weekdays_tuesday_headline'),
            t('statistics.weekdays_wednesday_headline'),
            t('statistics.weekdays_thursday_headline'),
            t('statistics.weekdays_friday_headline'),
            t('statistics.weekdays_saturday_headline'),
            t('statistics.weekdays_sunday_headline')
          ]:[
            `${t('statistics.week')} 1`,
            `${t('statistics.week')} 2`,
            `${t('statistics.week')} 3`,
            `${t('statistics.week')} 4`
          ],
          lineWidth: 0,
          labels: {
            distance: 14,
            style: {
              color: "#1E1E1E",
              fontSize: 16,
            },
          },
        },
        yAxis: {
          max: max,

          labels: {
            align: "left",
            x: -10,
            style: {
              color: "#1E1E1E",
              fontSize: 16,
            },
          },
        },
        series: [
          {
            name: t('statistics.downloads'),
            data: installDataset,
            zIndex: 1,
          },
          {
            name: t('statistics.unistalls'),
            data: uninstallDataset,
            zIndex: 1,
          },
          {
            name: "Background 1",
            data: statsButtonActive==="week"?Array.from(Array(7).keys(), (_, index) => max):Array.from(Array(4).keys(), (_, index) => max),
            color: "#F1F1F1",
            borderColor: "transparent",
            enableMouseTracking: false,
            zIndex: 0,
            grouping: false,
            pointPlacement: -0.15,
            showInLegend:false,
          },
          {
            name: "Background 2",
            data: statsButtonActive==="week"?Array.from(Array(7).keys(), (_, index) => max):Array.from(Array(4).keys(), (_, index) => max),
            color: "#F1F1F1",
            borderColor: "transparent",
            enableMouseTracking: false,
            zIndex: 0,
            pointPlacement: 0.15,
            grouping: false,
            pointWidth: 16,
            showInLegend:false,
          },
        ],
        tooltip: {
          enabled: false,
        },
      });
    }
  }, [installDataset, uninstallDataset, max,statsButtonActive, t]);

  return (
    <div className="column-chart">
      <div
        id="column-chart-container"
        style={{ width: "100%", height: "286px" }}
      ></div>
    </div>
  );
}

export default ColumnChart;
