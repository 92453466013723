import {useNavigate} from "react-router-dom";

function ResetPassword() {
  const navigate = useNavigate();

  const backToLogin = (e) => {
    e.preventDefault();
    navigate("/login");
  }

  return (
    <div className="no-access child-component">
      <h1 className="section-title less-padding">NO ACCESS</h1>

      <div className="main-content">
        <span>You don't have permission to view this page.</span>
        <span>Please contact your supervisor.</span>
      </div>

      <div className="submit-button">
        <button type="submit" onClick={backToLogin} className="main-button">BACK TO LOG IN</button>
      </div>
    </div>
  )
}

export default ResetPassword;