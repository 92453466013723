import { useTranslation } from 'react-i18next';

export default function useTranslatableContent () {
  const { i18n } = useTranslation();

  return (text = null, locale = null, def = '') => {
    if (typeof text === 'object') {
      locale = locale || i18n.language
      return text?.[locale] || def
    }

    return text || def
  }
}

// if (text?.[locale] == null) {
//   return ''
// }
