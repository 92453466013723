import { useContext } from "react";
import { ModalContext, ParentIdContext, UserContext } from "@/App";
import { useNavigate } from "react-router-dom";
import AddTopic from "./TopicsModals/AddTopic/AddTopic";
import "./Topics.css";
import useTopics from "@/hooks/useTopics";
import { getIcon } from "./TopicsAssets/Utils/topicUtils";
import useTranslatableContent from "@hooks/useTranslatableContent";
import Header from "../../Header/Header";
import { useTranslation } from "react-i18next";
import ButtonMain from "@/components/buttons/ButtonMain";
import H1 from "@/components/headings/H1";
import HeaderActions from "@/components/Header/HeaderActions";

function Topics() {
	const topics = useTopics({ fullData: true });
	const { setModal } = useContext(ModalContext);
	const { currentUser } = useContext(UserContext);
	const navigate = useNavigate();
  const maybeTranslate = useTranslatableContent();
  const { t } = useTranslation();
  const { setParentId } = useContext(ParentIdContext);

	const openAddTopicView = (e) => {
		e.preventDefault();
    setParentId(null);
		setModal(<AddTopic />);
	};

	const openTopicCard = (topicId) => {
		navigate(`/topics/${topicId}`);
  };

	return (
		<div className="admin-panel-item">
      <Header>
        <H1>{t('heading.topics')} {topics?.length && <span className="font-normal">({topics.length})</span>}</H1>
        <HeaderActions>
          {currentUser?.userRole === "super-admin" || currentUser?.topicAdding
            ? (
              <ButtonMain onClick={openAddTopicView}>
                + {t('button.add_topic')}
              </ButtonMain>
            ) : null}
        </HeaderActions>
      </Header>

			{!topics || !topics.length ? (
				<div className="no-topics">
          <span>{t('text.no_topics')}</span>
				</div>
			) : (
				<div className="topic-grid">
					{topics.map((topic) => {
						return (
							<div
								key={topic.id}
								className="topic-card"
								onClick={() => openTopicCard(topic.id)}
							>
								<div className="topic-icon">
									<img src={getIcon(topic?.icon)} alt="" />
								</div>
								<div className="card-details">
									<span className="card-title">{maybeTranslate(topic.name)}</span>
                  <span className="number-of-topic-cards">
                    {t('count.subtopic', { count: topic?.subtopics_count || 0 })}
									</span>
								</div>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
}

export default Topics;
