import bacteria from "./Bacteria.png";
import bones from "./Bones.png";
import brain from "./Brain.png";
import crutches from "./Crutches.png";
import ear from "./Ear.png";
import eye from "./Eye.png";
import gene from "./Gene.png";
import gynaecology from "./Gynaecology.png";
import heart from "./Heart.png";
import kidney from "./Kidney.png";
import ligaments from "./Ligaments.png";
import liver from "./Liver.png";
import lungs from "./Lungs.png";
import nurse from "./Nurse.png";
import obstetrics from "./Obstetrics.png";
import pills from "./Pills.png";
import skin from "./Skin.png";
import stomach from "./Stomach.png";
import surgery from "./Surgery.png";
import teeth from "./Teeth.png";

export const icons = [
  { name: "bacteria", src: bacteria },
  { name: "bones", src: bones },
  { name: "brain", src: brain },
  { name: "crutches", src: crutches },
  { name: "ear", src: ear },
  { name: "eye", src: eye },
  { name: "gene", src: gene },
  { name: "gynaecology", src: gynaecology },
  { name: "heart", src: heart },
  { name: "kidney", src: kidney },
  { name: "ligaments", src: ligaments },
  { name: "liver", src: liver },
  { name: "lungs", src: lungs },
  { name: "nurse", src: nurse },
  { name: "obstetrics", src: obstetrics },
  { name: "pills", src: pills },
  { name: "skin", src: skin },
  { name: "stomach", src: stomach },
  { name: "surgery", src: surgery },
  { name: "teeth", src: teeth },
];
