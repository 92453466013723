import { getMediaLetter } from "../../../../CardAssets/QuestionOptions";
import { useTranslation } from "react-i18next";

import AnswerListItem from "../Image&MultipleOpenEnded/AnswerListItem";
import Button from "@/components/buttons/Button";

import { v4 as uuidv4 } from "uuid";
export default function AnswerList ({ disabled, onChange, onConfirm, options }) {
  const { t } = useTranslation();

  const remove = (idToRemove) => {
    // Filter out the option with the given id
    
    let updatedOptions = (options || []).filter(
      (option) => option.id !== idToRemove,
    );

    // Recalculate the `id` and `text` values for the remaining options
    updatedOptions = updatedOptions.map((option, index) => ({
      ...option,
      id: uuidv4(), // Assign a new unique id
      text: getMediaLetter(index + 1), // Update the text based on its new position
    }));

    // Trigger the onChange with updated options
    onChange(updatedOptions);
  };
  // const remove = (index) => onChange(options.filter((_, idx) => idx !== index));
  const update = (value, index) => onChange(options.map((option, idx) => idx === index ? { ...option, value } : option));

  return (
    <>
      <div className="space-y-6 mb-6">
        {options ? options.map((option, index) => (
          !option.is_extra ? (
            <AnswerListItem
              disabled={disabled}
              key={index}
              onChange={(value) => update(value, index)}
              onDelete={() => remove(option.id)}
              prepend={getMediaLetter(index + 1) + '.'}
              value={option.value}
            />
          ) : null
        )) : null}
      </div>

      {!disabled ? (
        <Button className="block w-full" onClick={() => onConfirm()}>
          {t('button.confirm')}
        </Button>
      ) : null}
    </>
  )
}
