import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Loading from "../../Loading/Loading";
import { ErrorContext } from "../../../App";
import axios from '@/services/axios';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(null);
  const { setErrors } = useContext(ErrorContext);
  const {t}=useTranslation()
  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post(`/reset-password`, {
        email,
      })
      .then((response) => {
        if (response.status === 200) {
          showInfoToUser();
        }
      })
      .catch(({ response }) => {
        // Input errors
        if (typeof response.data.errors !== "undefined") {
          let errs = [];

          for (const error in response.data.errors) {
            errs.push([t(...response.data.errors[error])]);
          }
          setErrors(errs);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const showInfoToUser = () =>
    toast.info("A password reset link was sent to your email address");

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="reset-password child-component">
      <h1 className="section-title">RESET PASSWORD</h1>

      <div className="input-section">
        <form method="post" onSubmit={onSubmit}>
          <div className="input-forms">
            <input
              type="email"
              name="email"
              placeholder="Email Address"
              onInput={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="submit-button">
            <button type="submit" className="main-button">
              RESET PASSWORD
            </button>
          </div>
        </form>
      </div>

      <div className="link-section">
        <Link to="/login">Back to Log in</Link>
      </div>
    </div>
  );
}

export default ResetPassword;
