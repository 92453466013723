import { cn } from '@/utils/utils';
import './container.css';

export default function Container ({ children, className }) {
  return (
    <div className={cn('container', className)}>
      {children}
    </div>
  );
}
