import { useContext, useEffect, useState } from "react";
import { FieldLanguageContext } from "@/context";
import { cn } from "@/utils/utils";
import "./fields.css";
import { ErrorContext } from "@/App";
function TextField({
  className,
  label,
  name,
  prepend = null,
  translatable = false,
  type = "text",
  value = "",
  setValue = null,
  index,
  ...props
}) {
  const { fieldLanguage } = useContext(FieldLanguageContext);
  const { errors } = useContext(ErrorContext);
  const [localError, setLocalError] = useState();
  useEffect(() => {
    console.log("All errors", errors);
    console.log("Field lang", fieldLanguage);
    console.log("Field type", name);
    if (errors && errors.length > 0) {
      if (name !== "option") {
        console.log(
          "Setting local error",
          errors.find((el) => el.field === name && el.lng === fieldLanguage),
        );
        setLocalError(
          errors.find((el) => el.field === name && el.lng === fieldLanguage),
        );
      } else {
        setLocalError(
          errors.find(
            (el) =>
              el.field === name &&
              el.lng === fieldLanguage &&
              el.index === index,
          ),
        );
      }
    }
  }, [errors, fieldLanguage,index,name]);
  function handleInput(e) {
    if (localError) {
      setLocalError(undefined);
    }
    setValue(
      translatable
        ? { ...value, [fieldLanguage]: e.target.value }
        : e.target.value,
    );
  }
  return (
    <div className="field-group relative">
      {label && <label htmlFor={name}>{label}</label>}

      {prepend ? (
        <span className="form-control__prepend">{prepend}</span>
      ) : null}

      <input
        className={`${cn("form-control", className)} ${localError && localError.lng === fieldLanguage ? "error" : ""}`}
        id={name}
        name={name}
        onInput={(e) => handleInput(e)}
        type={type}
        value={translatable ? value?.[fieldLanguage] || "" : value}
        {...props}
      />
    </div>
  );
}

export default TextField;
