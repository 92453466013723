import { useCallback, useContext, useEffect, useState } from "react";
import { UserContext } from "../App";
import axios from '@/services/axios';
import { useNavigate } from "react-router-dom";

function useAuthenticationStatus() {
  const [authenticationStatus, setAuthenticationStatus] = useState(null);
  const { authToken, setAuthToken, currentUser, setCurrentUser } =
    useContext(UserContext);
  const navigate = useNavigate();

  const getAuthenticationStatus = useCallback(() => {
    if (!authToken) {
      setAuthenticationStatus("NOT-OK");
    }

    if (authToken) {
      axios
        .post(`/validate-auth-token`, {
          auth_token: authToken,
        })
        .then((response) => {
          if (typeof response?.data?.success !== "undefined") {
            setAuthenticationStatus("OK");

            if (!currentUser) {
              setCurrentUser({
                ...currentUser,
                email: response.data.email,
                userRole: response.data.role,
                topicAdding: response.data.can_add_topic,
              });
            }
          }
        })
        .catch((_) => {
          localStorage.removeItem("auth_token");
          setAuthenticationStatus("NOT-OK");
          setAuthToken(null);
          navigate("/login");
        });
    }
  }, [authToken, setAuthToken, navigate, currentUser, setCurrentUser]);

  useEffect(() => {
    getAuthenticationStatus();
  }, [getAuthenticationStatus]);

  return authenticationStatus;
}

export default useAuthenticationStatus;
