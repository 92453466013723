import { useContext, useState } from "react";
import { TopicCardContext } from "../../../QuestionModalsContent";
import editIcon from "../../../../CardAssets/editIcon.svg";
import ButtonBlack from "@/components/buttons/ButtonBlack";
import { useTranslation } from "react-i18next";
import CorrectAnswers from "@/components/Question/CorrectAnswers";
import FileField from "@/components/Fields/FileField";
import { sourceUrl } from "@/utils/utils";
import Icon from "@/components/Icons/Icon";

function ChooseImage() {
  const {
    setShowManualField,
    isEditing,
    setIsEditing,
    questionCardDetails,
    setQuestionCardDetails,
  } = useContext(TopicCardContext);

  const [localError,setLocalError]=useState()
  const { t } = useTranslation();


  const handleEditMedia = () => {
    setShowManualField(false);
    setIsEditing(true);
  };

  const toggleCorrectAnswer = (index) => {
    if(localError){
      setLocalError(undefined)
    }
    setQuestionCardDetails((prev) =>
      ({ ...prev, options: prev.options.map((option, idx) => idx === index ? { ...option, is_correct: !option.is_correct } : option) })
    );
  };

  const updateFiles = (files) => {
    setQuestionCardDetails((prev) => {
      const options = [
        ...(prev?.options || []),
        ...files.map((file) => ({ value: null, url: URL.createObjectURL(file), is_correct: false }))
      ].slice(0, 9);

      return { ...prev, options, files };
    });
  }

  const removeOption = (index) => () => {
    setQuestionCardDetails((prev) => ({ ...prev, options: prev.options.filter((_, idx) => idx !== index) }))
  };

  return (
    <div className="card-type-component">
      <div className="upload-media-section">
        {isEditing ? (
          <div className="mb-4 font-medium">
            {t('heading.choose_multiple_images')}
          </div>
        ) : (
          <div className="confirmed-uploaded-media mb-4">
            <span>
              {t('heading.uploaded_media', { count: questionCardDetails?.options?.length || 0 })}
            </span>

            <button
              type="button"
              className="edit-answers"
              onClick={handleEditMedia}
            >
              <img src={editIcon} alt="" />
            </button>
          </div>
        )}

        <div className="mb-6">
          <FileField
            disabled={!isEditing}
            limit={9 - (questionCardDetails?.options?.length || 0)}
            multiple
            value={questionCardDetails?.files}
            setValue={updateFiles}
          >
            {questionCardDetails?.options?.map((option, index) => (
              <div key={index} className="file-field__preview">
                {option?.url || option?.value ? (
                  <>
                    {isEditing ? (
                      <button
                        className="absolute top-1.5 right-1.5 grid place-content-center w-6 h-6 rounded-full bg-black text-white"
                        onClick={removeOption(index)}
                        type="button"
                      >
                        <Icon icon="trash" />
                      </button>
                    ) : null}
                    <img src={sourceUrl(option?.url || option.value)} alt="" />
                  </>
                ) : null}
              </div>
            ))}

            {[...Array(9 - (questionCardDetails?.options?.length || 0))].map((_, index) => (
              <div key={index} className="file-field__preview"></div>
            ))}
          </FileField>
        </div>

        {isEditing ? (
          <ButtonBlack
            className="w-full"
            onClick={() => setIsEditing(false)}
          >
            {t('button.confirm')}
          </ButtonBlack>
        ) : null}
      </div>

      <CorrectAnswers
        options={questionCardDetails?.options}
        onChange={toggleCorrectAnswer}
        localError={localError}
      />
    </div>
  );
}

export default ChooseImage;
