import Modal from "../AdminPanel/AdminPanelAssets/Modal/Modal";
import { useContext } from "react";
import { ModalContext } from "../../App";

function HOC({ children }) {
  const { modal } = useContext(ModalContext);

  return (
    <>
      {children}

      <Modal modal={modal} />
    </>
  );
}

export default HOC;
