import "./Team.css";
import Table, { TableCol, TableRow } from "../AdminPanelAssets/Table/Table";
import AddMember from "./TeamModals/AddMember/AddMember";
import { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ErrorContext, ModalContext, UserContext } from "../../../App";
import TableTopicsAccess from "./TableTopicsAccess/TableTopicsAccess";
import axios from '@/services/axios';
import { ROLES } from "../AdminPanelAssets/Constants/Constants";
import useTopics from "../../../hooks/useTopics";
import { toast } from "react-toastify";
import Header from "@components/Header/Header";
import HeaderActions from "@/components/Header/HeaderActions";
import H1 from "@components/headings/H1";
import ButtonMain from "@components/buttons/ButtonMain";
import { useTranslation } from "react-i18next";

function Team() {
  const [teamMembers, setTeamMembers] = useState([]);
  const topics = useTopics({ fullData: false });
  const { setErrors } = useContext(ErrorContext);
  const navigate = useNavigate();
  const { setModal } = useContext(ModalContext);
  const { currentUser } = useContext(UserContext);
  const { t } = useTranslation();

  const showInfoToUser = (message) => toast.success(message);

  const openInviteMemberView = (e) => {
    e.preventDefault();
    setModal(<AddMember />);
  };

  const getTeamMembers = useCallback(() => {
    axios
      .get(`/users`, {
        params: { page: 1, per_page: 100, role: 'admin,super-admin' },
      })
      .then((response) => {
        setTeamMembers(response?.data?.users);
      })
      .catch(({ response }) => {
        if (typeof response?.data.errors !== "undefined") {
          let errs = [];

          for (const error in response?.data.errors) {
            errs.push([...response?.data.errors[error]]);
          }

          setErrors(errs);
        }

        if (response?.status === 401) {
          navigate("/login");
        }
      });
  }, [setErrors, navigate]);

  const getRole = (role) => {
    return ROLES.find((r) => r.value === role)?.label;
  };

  const handleMemberTopicAdding = (userId, canAddTopic) => {
    const memberToChange = teamMembers.find((member) => member.id === userId);
    const updatedMember = {
      ...memberToChange,
      can_add_topic: !memberToChange.can_add_topic,
    };

    submitMemberChanges(userId, updatedMember);
  };

  const handleMemberTopics = (e, userId, topicId, userTopics) => {
    e.preventDefault();

    const filteredTopics = userTopics.filter((topic) => topic.id !== topicId);
    const updatedTopics = filteredTopics.map((topic) => topic.id);

    const memberToChange = teamMembers.find((member) => member.id === userId);
    const updatedMember = {
      ...memberToChange,
      topics: updatedTopics,
    };

    submitMemberChanges(userId, updatedMember, filteredTopics);
  };

  const submitMemberChanges = useCallback(
    (id, updatedMember, filteredTopics) => {
      const updatedTopics = updatedMember?.topics.map((topic) => {
        if (topic?.id) return topic.id;

        return topic;
      });

      const memberDatToChange = { ...updatedMember, topics: updatedTopics };

      axios
        .post(`/team/member/${id}`, memberDatToChange, {
          params: { id },
        })
        .then((_) => {
          const updatedMembers = teamMembers.map((member) => {
            if (member.id === id) {
              return {
                ...updatedMember,
                topics: filteredTopics ?? updatedMember.topics,
              };
            }
            return member;
          });

          setTeamMembers(updatedMembers);

          showInfoToUser(t('team.change_access_success'));
        })
        .catch(({ response }) => {
          if (typeof response.data.errors !== "undefined") {
            let errs = [];

            for (const error in response.data.errors) {
              errs.push([...response.data.errors[error]]);
            }

            setErrors(errs);
          }

          if (response?.status === 401) {
            navigate("/login");
          } else {
            setErrors(["Something went wrong. Please, try again."]);
          }
        });
    },
    [setErrors, navigate, teamMembers, t],
  );

  useEffect(() => {
    if (!teamMembers?.length) {
      getTeamMembers();
    }
  }, [teamMembers, getTeamMembers]);

  return (
    <div className="admin-panel-item team">
      <Header>
        <H1>
          {t('heading.team')} {teamMembers?.length && <span className="length">{`(${teamMembers.length})`}</span>}
        </H1>
        <HeaderActions>
          {currentUser?.userRole === "super-admin"
            ? (
              <ButtonMain onClick={openInviteMemberView}>
                + {t('button.invite')}
              </ButtonMain>
            ) : null
          }
        </HeaderActions>
      </Header>

      {teamMembers?.length ? (
        <div className="members-table">
          <Table>
            <thead>
              <TableRow>
                <TableCol heading={true}>{t('list.team_member')}</TableCol>
                <TableCol heading={true}>{t('list.role')}</TableCol>
                <TableCol heading={true}>{t('list.email')}</TableCol>
                <TableCol heading={true} center={true}>{t('list.topic_adding')}</TableCol>
                <TableCol heading={true}>{t('list.topic_access')}</TableCol>
              </TableRow>
            </thead>

            <tbody>
              {teamMembers.map((member) => {
                let editTopicPermissionGranted = currentUser.userRole === "super-admin"
                if(!editTopicPermissionGranted){
                  editTopicPermissionGranted = member?.userRole === "super-admin" || member?.userRole === "admin"
                }
                return (
                  <TableRow key={member?.id}>
                    <TableCol>
                      {currentUser?.userRole === "super-admin" ? (
                        <Link to={`/team/member/${member?.id}`} className={member?.full_name && 'user-name-underlined'}>
                          {member?.full_name || '-'}
                        </Link>
                      ) : (
                        <span className="user-name">{member?.full_name || '-'}</span>
                      )}
                    </TableCol>
                    <TableCol>{getRole(member?.role)}</TableCol>
                    <TableCol>
                      <span className="user-email">
                        <a href={`mailto:${member?.email}`}>{member?.email}</a>
                      </span>
                    </TableCol>
                    <TableCol>
                      <div className="switch-column">
                        <div className="switch-container">
                          <label
                            className={`switch ${
                              !editTopicPermissionGranted ?
                              "switch-disabled" : ''
                            }`}
                          >
                            <input
                              type="checkbox"
                              defaultChecked={member?.can_add_topic}
                              onClick={(e) =>
                                handleMemberTopicAdding(
                                  member?.id,
                                  e.target.checked,
                                )
                              }
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </TableCol>
                    <TableCol>
                      <TableTopicsAccess
                        userTopics={member?.topics}
                        allTopicsLength={topics?.length}
                        userId={member?.id}
                        onTopicDelete={handleMemberTopics}
                      />
                    </TableCol>
                  </TableRow>
                )
              })}
            </tbody>
          </Table>
        </div>
      ) : (
        <div className="no-team">There are currently no team members.</div>
      )}
    </div>
  );
}

export default Team;
