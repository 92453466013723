import { useContext, useEffect, useRef, useState } from "react";
import editIcon from "../../../../CardAssets/editIcon.svg";
import { TopicCardContext } from "../../../QuestionModalsContent";
import { useTranslation } from "react-i18next";
import AnswerList from "./AnswerList";
import H3 from "@/components/headings/H3";
import ButtonBlack from "@/components/buttons/ButtonBlack";
import Icon from "@/components/Icons/Icon";
import FileField from "@/components/Fields/FileField";
import { sourceUrl } from "@/utils/utils";

function ImageMultipleOpenEnded() {
  const [mediaIsUploaded, setMediaIsUploaded] = useState(false);
  const {
    setShowManualField,
    isEditing,
    setIsEditing,
    questionCardDetails,
    setQuestionCardDetails,
  } = useContext(TopicCardContext);
  const filePickerRef = useRef(null);
  const { t } = useTranslation();

  const chooseMedia = () => {
    if (filePickerRef.current) {
      setShowManualField(false);
      filePickerRef.current.click();
    }
  };


  const confirm = () => {
    setShowManualField(true);
    setIsEditing(false);
  };

  const updateOptions = (options) => setQuestionCardDetails((prev) => ({ ...prev, options }));

  useEffect(() => {
    if (questionCardDetails && questionCardDetails?.options?.length > 0) {
      setMediaIsUploaded(true);
    }
  }, [questionCardDetails, setMediaIsUploaded]);

  const updateFiles = (files) => {
    setQuestionCardDetails((prev) => {
      setMediaIsUploaded(true);
      return {
        ...prev,
        media: files?.map((file) => URL.createObjectURL(file)) || [],
        files
      }
    });
  }

  return (
    <div className="card-type-component">
      <div className="upload-media-section">
      <p className="h3 mb-4">{t('heading.choose_image')}</p>

        <div className="media">
          {mediaIsUploaded ? (
            <div className="uploaded-media relative">
              <img
                src={sourceUrl(questionCardDetails?.media?.[0])}
                alt=""
              />
              <button type="button" onClick={chooseMedia}>
                <img src={editIcon} alt="" className="edit-media" />
              </button>
            </div>
          ) : (
              <FileField
                disabled={!isEditing}
                setValue={updateFiles}
              />
          )}
        </div>
      </div>

      {questionCardDetails?.media ? (
        <>
          <div className="flex items-center gap-6 mb-6">
            <H3>{t('heading.correct_answers')}</H3>
            {!isEditing && (
              <ButtonBlack className="w-7 h-7 p-2 text-xs" onClick={() => setIsEditing(true)}>
                <Icon icon="pen" />
              </ButtonBlack>
            )}
          </div>

          <AnswerList
            options={questionCardDetails.options}
            canAdd={true}
            disabled={!isEditing}
            onChange={updateOptions}
            onConfirm={confirm}
          />
        </>
      ) : null}
    </div>
  );
}

export default ImageMultipleOpenEnded;
