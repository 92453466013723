import { cn } from '@/utils/utils';

export default function Switch ({
  checked = false,
  className = null,
  disabled = false,
  onChange = () => { },
  ...props
}) {
  return (
    <label className={cn('switch', className, disabled ? 'switch-disabled' : '')}>
      <input
        checked={checked}
        type="checkbox"
        onChange={onChange}
        {...props}
      />
      <span className="slider round"></span>
    </label>
  )
}
