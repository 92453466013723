import { cn } from '@/utils/utils';
import './headings.css';

export default function H2 ({ children, className }) {
  const excluded = /h[1-5]/.test(className);
  const computedClassName = excluded ? className : cn('h2', className);
  return (
    <h2 className={computedClassName}>{children}</h2>
  )
}
