import { useTranslation } from "react-i18next";

import AnswerListItem from "./AnswerListItem";
import Button from "@/components/buttons/Button";
import ButtonBlack from "@/components/buttons/ButtonBlack";
import { useEffect } from "react";

export default function AnswerList ({ options, canAdd, disabled, onChange, onConfirm }) {
  const { t } = useTranslation();

  const add = () => onChange([...(options || []), { value: {} }]);
  const remove = (index) => {
    if(options&&options.length===1)return;
    onChange(options.filter((_, idx) => idx !== index))
  };
  const update = (value, index) => {
    onChange(options.map((option, idx) => idx === index ? { ...option, value } : option));
  }
  useEffect(()=>{
    if(!options||options.length===0)
    add()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[options])
  return (
    <>
      <div className="space-y-6 mb-6">
        {options ? options.map((option, index) => (
          <AnswerListItem
            disabled={disabled}
            key={index}
            onChange={(value) => update(value, index)}
            onDelete={() => remove(index)}
            value={option.value}
            index={index}
          />
        )) : null}
      </div>

      {!disabled ? (
        <>
          {canAdd ? (
            <ButtonBlack className="block w-full mb-6" onClick={add}>
              + {t('button.add_answer')}
            </ButtonBlack>
          ) : null}

          <Button className="block w-full" onClick={() => onConfirm()}>
            {t('button.continue')}
          </Button>
        </>
      ) : null}
    </>
  )
}
