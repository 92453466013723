import closeIcon from "../../../../../../AdminPanelAssets/Icons/closeIcon.svg";
import "./CardPreview.css";
import { useContext } from "react";
import { ModalContext } from "../../../../../../../../App";
import AddQuestionCard from "../../AddQuestion/AddQuestionCard";
import CardPhoneView from "../../../CardPhoneView/CardPhoneView";

function CardPreview({ card }) {
  const { setModal } = useContext(ModalContext);

  const closePreview = (e) => {
    e.preventDefault();

    setModal(<AddQuestionCard cardDetails={card} />);
  };

  return (
    <div className="card-preview">
      <div className="preview-title">
        <span>PREVIEW</span>
        <button
          type="button"
          className="close-preview"
          onClick={(e) => closePreview(e)}
        >
          <img src={closeIcon} alt="" />
        </button>
      </div>

      <div className="preview-content">
        <CardPhoneView card={card} />
      </div>
    </div>
  );
}

export default CardPreview;
