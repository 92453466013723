import "./CardsList.css";
import { getTypes } from "../CardAssets/QuestionOptions";
import { Draggable } from "react-drag-reorder";
import { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { TopicContext } from "../../Topic";
import axios from '@/services/axios';
import { useTranslation } from "react-i18next";
import useTranslatableContent from "@/hooks/useTranslatableContent";
import { Link } from "react-router-dom";
import { getDoubleDigitNumber } from "../CardAssets/utils";

function Card({ card }) {
  const { t } = useTranslation();
  const maybeTranslate = useTranslatableContent();
  const types = getTypes();

	const getCardType = () => {
		return types?.find((type) => type.value === card.type)?.label || '';
	};

	const getCardNumber = () => {
		return getDoubleDigitNumber(card?.id || 0);
	};

	const cardRightContent = () => {
		if (card.status === "draft"){
			return t('button.draft')
		}
		return getCardType()
	}

	const cardClassNames = () => {
		let mainClass = 'uppercase';
		if(cardRightContent() === getCardType()){
			return mainClass + ' activeCard'
		}

		return mainClass + ' draftCard'
	}

	return (
    <Link className="question-card" to={`/cards/${card.id}`}>
			<div className="content-left">
				<span className="question-index">{getCardNumber()}</span>
				<span className="question">
          {maybeTranslate(card?.question)}
				</span>
			</div>

			<div className="content-right">
				<span className={cardClassNames()}>{cardRightContent()}</span>
			</div>
		</Link>
	);
}

function CardsList({ activeCardType }) {
	const [cardsListKey, setCardsListKey] = useState(uuidv4());
	const { topic, setTopic } = useContext(TopicContext);

  const { t } = useTranslation();

	const getCardNumber = (cardId) => {
		let number;
		topic?.cards?.map((c, index) => {
			if (c.id === cardId) {
				number = index;
			}
			return null;
		});

		return getDoubleDigitNumber(number);
	};

	const cardsToShow = () => {
		if (activeCardType === "all") return topic.cards;

		if (activeCardType === "drafts"){
			return topic.cards.filter(
				(card) => card.status === "draft",
			);
		}

		return topic.cards.filter(
			(card) => card.type === activeCardType,
		);
	};

	const updateTopicCardsOrder = (cards) => {
		console.log(cards);
		axios.post(`/topic/${topic.id}/update-cards-order`, {
			items: cards.map((card, index) => {
				return {
					id: card.id,
					order: index
				}
			})
		})
			.then(response => console.log(response))
			.catch(error => console.log('error', error));
	};

	const onPosChange = (currentPos, newPos) => {
		const cards = topic.cards;
		const visibleCards = cardsToShow();
		const currentCard = visibleCards[currentPos];
		const newCard = visibleCards[newPos];
		const actualCurrentPos = topic.cards.findIndex(
			(card) => card.id === currentCard.id,
		);
		const actualNewPos = topic.cards.findIndex(
			(card) => card.id === newCard.id,
		);
		const card = topic.cards[actualCurrentPos];

		cards.splice(actualCurrentPos, 1);
		cards.splice(actualNewPos, 0, card);

		// update state and backend
		setTopic({ ...topic, cards });
		updateTopicCardsOrder(cards);
	};

	useEffect(() => {
		setCardsListKey(uuidv4());
	}, [activeCardType]);

	return (
		<div className="cards-list" key={cardsListKey}>
      {cardsToShow().length > 0 ? (
				<Draggable onPosChange={onPosChange}>
					{cardsToShow().map((card) => {
						return (
							<div className="card-row" key={card.id} draggable="true">
								<div className="card-drag-handle" />
								<Card card={card} cardNumber={getCardNumber(card.id)} />
							</div>
						);
					})}
				</Draggable>
			) : (
        <span>{t(activeCardType === "all" ? 'text.no_cards' : 'text.no_cards_for_type')}</span>
			)}
		</div>
	);
}

export default CardsList;
