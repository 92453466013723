import { useContext, useState, useEffect, useCallback } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import Loading from "../../Loading/Loading";
import { ErrorContext, UserContext } from "../../../App";
import axios from '@/services/axios';

function CreatePassword() {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const { setErrors } = useContext(ErrorContext);
  const { setAuthToken } = useContext(UserContext);
  const navigate = useNavigate();

  const { token } = useParams();

  // 84428a69-2e73-45ed-b5e0-015248d4af09
  const validateToken = useCallback(() => {
    axios
      .post(`/validate-remember-token`, {
        remember_token: token,
      })
      .catch(({ response }) => {
        if (response.status >= 400 && response.status < 500) {
          navigate("/403");
        }
      });
  }, [token, navigate]);

  useEffect(() => {
    validateToken();
  }, [validateToken]);

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post(`/change-password`, {
        remember_token: token,
        password,
        confirm_password: confirmPassword,
      })
      .then((response) => {
        if (typeof response.data.auth_token !== "undefined") {
          localStorage.setItem("auth_token", response.data.auth_token);
          setAuthToken(response.data.auth_token);
          navigate("/stats");
        }
      })
      .catch(({ response }) => {
        // Input errors
        if (typeof response.data.errors !== "undefined") {
          let errs = [];

          for (const error in response.data.errors) {
            errs.push([...response.data.errors[error]]);
          }

          setErrors(errs);
        }

        // Otherwise
        if (typeof response.data.error !== "undefined") {
          setErrors(["Something went wrong. Please try again."]);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="choose-password child-component">
      <h1 className="section-title">CREATE A NEW PASSWORD</h1>

      <div className="input-section">
        <form method="post" onSubmit={onSubmit}>
          <div className="input-forms">
            <input
              type="password"
              name="password"
              placeholder="New Password"
              onInput={(e) => setPassword(e.target.value)}
            />

            <input
              type="password"
              name="password"
              placeholder="Confirm Password"
              onInput={(e) => setConfirmPassword(e.target.value)}
            />
          </div>

          <div className="submit-button">
            <button type="submit" className="main-button">
              CREATE PASSWORD
            </button>
          </div>
        </form>
      </div>

      <div className="link-section">
        <Link to="/login">Back to Log in</Link>
      </div>
    </div>
  );
}

export default CreatePassword;
