import "./MemberModalContent.css";
import { useContext, useState } from "react";
import { ModalContext } from "@/App";
import Dropdown from "../../AdminPanelAssets/Dropdown/Dropdown";
import { ROLES } from "../../AdminPanelAssets/Constants/Constants";
import useTopics from "@/hooks/useTopics";
import TextField from "@/components/Fields/TextField";
import { useTranslation } from "react-i18next";
import ButtonMain from "@/components/buttons/ButtonMain";
import Button from "@/components/buttons/Button";

function MemberModalContent({ currentMember, onSubmit }) {
  const [teamMember, setTeamMember] = useState(currentMember ?? null);
  const topics = useTopics({ fullData: false });
  const { setModal } = useContext(ModalContext);

  const { t } = useTranslation();

  const handleTopicSelection = (topics) => {
    setTeamMember((prev) => ({ ...(prev || {}), topics }));
  };

  const handleRoleSelection = (selectedValues) => {
    setTeamMember((prev) => ({
      ...(prev || {}),
      role: selectedValues[0],
      can_add_topic: selectedValues[0] === "super-admin",
    }));
  };

  return (
    <div className="member-modal-content">
      <div className="input-section">
        {/* <pre>{JSON.stringify(teamMember, null, 2)}</pre> */}
        <form method="post" onSubmit={(e) => onSubmit(e, teamMember)}>
          <div className="input-forms mb-8 space-y-6">
            <TextField
              label={t('field.email')}
              name="email"
              setValue={(newValue) => setTeamMember((prev) => ({ ...prev, email: newValue }))}
              type="email"
              value={teamMember?.email ?? ""}
            />

            <TextField
              label={t('field.password')}
              name="password"
              placeholder={t('field.password')}
              setValue={(newValue) => setTeamMember((prev) => ({ ...prev, password: newValue }))}
              type="password"
              value={teamMember?.password ?? ""}
            />

            <TextField
              label={t('field.password_confirmation')}
              name="password_confirmation"
              placeholder={t('field.password_confirmation')}
              setValue={(newValue) => setTeamMember((prev) => ({ ...prev, password_confirmation: newValue }))}
              type="password"
              value={teamMember?.password_confirmation ?? ""}
            />

            <TextField
              label={t('field.first_name')}
              name="first-name"
              setValue={(newValue) => setTeamMember((prev) => ({ ...prev, first_name: newValue }))}
              value={teamMember?.first_name ?? ""}
            />

            <TextField
              label={t('field.last_name')}
              name="last-name"
              setValue={(newValue) => setTeamMember((prev) => ({ ...prev, last_name: newValue }))}
              value={teamMember?.last_name ?? ""}
            />

            <Dropdown
              options={ROLES}
              selectedValues={teamMember?.role ? [teamMember.role] : []}
              onChange={handleRoleSelection}
              allowMultipleSelections={false}
              defaultLabel={t('field.user_role')}
            />

            <div className="can-add-topics">
              <span>{t('field.topic_adding_check')}</span>

              <div className="switch-container">
                <label
                  className={`switch ${
                    teamMember?.role === "super-admin" && "switch-disabled"
                  }`}
                >
                  <input
                    key={Math.random()}
                    type="checkbox"
                    onClick={(e) =>
                      setTeamMember({
                        ...teamMember,
                        can_add_topic: e.target.checked,
                      })
                    }
                    defaultChecked={teamMember?.can_add_topic}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>

            <Dropdown
              options={topics}
              selectedValues={teamMember?.topics}
              onChange={handleTopicSelection}
              allowMultipleSelections={true}
              defaultLabel={t('field.topic_access_placeholder')}
            />
          </div>

          <ButtonMain className="w-full mb-4" type="submit">
            {t(currentMember ? 'button.save_changes' : 'button.add_member')}
          </ButtonMain>

          <div className="flex justify-center">
            <Button onClick={() => setModal(null)}>
              {t('button.cancel')}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default MemberModalContent;
