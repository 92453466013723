import { cn } from '@/utils/utils';
import './buttons.css';

export default function Button({ children, type='button', className, ...props }) {
  return (
    <button type={type} className={cn('button', className)} {...props}>
      {children}
    </button>
  );
}
