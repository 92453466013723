import MemberModalContent from "../MemberModalContent";
import { useContext } from "react";
import axios from '@/services/axios';
import { toast } from "react-toastify";
import { ErrorContext, ModalContext } from "@/App";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import H1 from "@/components/headings/H1";

function EditMember({ currentMember, updateMember }) {
  const { setModal } = useContext(ModalContext);
  const { setErrors } = useContext(ErrorContext);
  const navigate = useNavigate();

  const { t } = useTranslation();

  const updatedMemberTopics = () => {
    if (currentMember?.topics?.length) {
      return currentMember.topics.map((topic) => topic?.id ?? topic);
    }
  };

  const showInfoToUser = (message) => toast.success(message);

  const submitMemberChanges = (e, teamMember) => {
    e.preventDefault();

    axios
      .put(`/users/${teamMember.id}`, teamMember)
      .then(() => {
        showInfoToUser("The user data was successfully changed!");
        setModal(null);
        updateMember(teamMember);
      })
      .catch(({ response }) => {
        if (typeof response.data.errors !== "undefined") {
          let errs = [];

          for (const error in response.data.errors) {
            errs.push([...response.data.errors[error]]);
          }

          setErrors(errs);
        }

        if (response?.status === 401) {
          navigate("/login");
        } else if (response?.status === 403) {
          setErrors(["You are not authorized to perform this action."]);
        } else {
          setErrors(["Something went wrong. Please, try again."]);
        }
      });
  };

  return (
    <div className="modal-member-view content-view">
      <H1>{t('heading.edit_member')}</H1>

      <MemberModalContent
        currentMember={{ ...currentMember, topics: updatedMemberTopics() }}
        onSubmit={submitMemberChanges}
      />
    </div>
  );
}

export default EditMember;
