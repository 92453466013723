import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "@/components/Icons/Icon";
import { cn } from "@/utils/utils";

import "./DraggablePill.css";

function DraggablePill({ onChange, field, disabled,onRemove }) {
  const { t } = useTranslation();
  const [contextOpen, setContextOpen] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    setContextOpen(false)
  }, [field])
  
  const onPosChange = useCallback(
    (e, callback) => {
      const clonedField = { ...field };
      const actualOffsetY = ref.current.offsetTop + e.offsetY;
      const actualOffsetX = ref.current.offsetLeft + e.offsetX;

      // vertical
      if (
        actualOffsetY >= 0 &&
        actualOffsetY <= ref.current.parentElement.offsetHeight
      ) {
        const topPixel = actualOffsetY - ref.current.offsetHeight / 2;
        const imageHeight = ref.current.parentElement.offsetHeight;

        clonedField.top = `${(topPixel / imageHeight) * 100}%`;
      }

      // horizontal, left side
      if (
        field.direction === "left" &&
        actualOffsetX >= -(ref.current.offsetWidth + 30) &&
        actualOffsetX <= ref.current.parentElement.offsetWidth
      ) {
        const leftPixel = actualOffsetX - ref.current.offsetWidth / 2;
        const imageWidth = ref.current.parentElement.offsetWidth;

        clonedField.left = `${(leftPixel / imageWidth) * 100}%`;
      }

      // horizontal, right side
      if (
        field.direction === "right" &&
        actualOffsetX >= -(ref.current.offsetWidth + 30) &&
        actualOffsetX <=
          ref.current.parentElement.offsetWidth + ref.current.offsetWidth
      ) {
        const rightPixel =
          ref.current.parentElement.offsetWidth -
          actualOffsetX -
          ref.current.offsetWidth / 2;
        const imageWidth = ref.current.parentElement.offsetWidth;

        clonedField.right = `${(rightPixel / imageWidth) * 100}%`;
      }

      callback(clonedField);
    },
    [field],
  );

  const drag = useCallback(
    (e) => {
      onPosChange(e, (newField) => {
        ref.current.style.top = newField.top;

        if (newField.left) {
          ref.current.style.left = newField.left;
        }

        if (newField.right) {
          ref.current.style.right = newField.right;
        }
      });
    },
    [onPosChange],
  );

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnd = useCallback(
    (e) => {
      onPosChange(e, (newField) => {
        ref.current.style.top = newField.top;

        if (newField.left) {
          ref.current.style.left = newField.left;
        }

        if (newField.right) {
          ref.current.style.right = newField.right;
        }

        onChange(newField);
      });
    },
    [onPosChange, onChange],
  );

  useEffect(() => {
    const el = ref.current;

    if (el && !disabled) {
      // add drag listener
      //el.addEventListener("drag", drag);

      document.addEventListener("dragover", dragOver, false);

      // add drag end listener
      el.addEventListener("dragend", dragEnd);
    }

    return () => {
      if (el) {
        // remove drag listener
        //el.removeEventListener("drag", drag);

        document.removeEventListener("dragover", dragOver);

        // remove drag end listener
        el.removeEventListener("dragend", dragEnd);
      }
    };
  }, [ref, drag, dragEnd, disabled]);

  return (
    <span
      ref={ref}
      className={cn(
        "draggable-pill",
        field.direction,
        disabled ? "drag-not-allowed" : null,
      )}
      draggable="true"
      onClick={() => {
        if (!contextOpen&&!disabled) setContextOpen(true);
      }}
      style={{
        top: field.top,
        left: field.left,
        right: field.right,
      }}
    >
      {contextOpen ? (
        <div className="flex w-full justify-between items-center h-full">
          <div aria-label="Remove" aria-labelledby="Remove" onClick={()=>onRemove(field.id)} className="w-1/2 flex justify-center items-center cursor-pointer">
            <Icon icon="trash" />
          </div>
          <div className="w-px h-full block bg-black"></div>
          <div
            onClick={() => {
              if (contextOpen) setContextOpen(false);
            }}
            className="w-1/2 flex justify-center items-center cursor-pointer"
          >
            X
          </div>
        </div>
      ) : (
        <> {field.text || t("text.untitled")}</>
      )}
    </span>
  );
}

export default DraggablePill;
