import { useContext } from 'react';


import { UserContext } from '@/App';

import { Link } from 'react-router-dom';
import { TableCol, TableRow } from '@/components/AdminPanel/AdminPanelAssets/Table/Table';

export default function ListItem ({ user }) {
  const { currentUser } = useContext(UserContext);


  return (
    <TableRow key={user.id}>
      <TableCol></TableCol>
      <TableCol>
        {currentUser?.userRole === "super-admin" ? (
          <Link to={`/users/${user.id}`}>
            {user.full_name || '-'}
          </Link>
        ) : (
          <span className="user-name">{user.full_name || '-'}</span>
        )}
      </TableCol>
      <TableCol></TableCol>
      <TableCol>
        <span className="user-email">{user.email}</span>
      </TableCol>
      <TableCol>
        {user.created_at}
      </TableCol>
    </TableRow>
  )
};
