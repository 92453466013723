import { useTranslation } from "react-i18next";
import H1 from "@/components/headings/H1";

import "./Notifications.css";
import FeedbackTable from "../Stats/FeedbackTable/FeedbackTable";
function Notifications() {
  const { t } = useTranslation();

  return (
    <div className="admin-panel-item notifications">
      <header>
        <div className="section-name">
          <H1>{t('nav.notifications')}</H1>
          <div className="invisible h-[50px]"></div>
        </div>
      </header>
      <div className="feedback-container">
        <FeedbackTable />
      </div>
    </div>
  );
}

export default Notifications;
