import { cn } from '@/utils/utils';

import "./Table.css";

export function TableRow({ children }) {
  return <tr>{children}</tr>;
}

export function TableCol({ heading, children, center, messageColumn }) {
  if (heading) {
    return (
      <th className={cn(center ? 'center-th' : null, messageColumn ? 'table-message-column' : null)}>
        {children}
      </th>
    );
  }

  return (
    <td className={cn(messageColumn ? 'table-message-column' : null)}>{children}</td>
  );
}

export default function Table({ children }) {
  return (
    <div className="table-component">
      <table>{children}</table>
    </div>
  );
}
