import "./QuestionModalsContent.css";
import { createContext, useContext, useEffect, useState } from "react";
import { ErrorContext, ModalContext } from "../../../../../../App";
import Dropdown from "../../../../AdminPanelAssets/Dropdown/Dropdown";
import { getTypes } from "../CardAssets/QuestionOptions";
import ChooseText from "./ModalComponents/QuestionCardTypes/ChooseText/ChooseText";
import ChooseImage from "./ModalComponents/QuestionCardTypes/ChooseImage/ChooseImage";
import ImageMultipleOpenEnded from "./ModalComponents/QuestionCardTypes/Image&MultipleOpenEnded/Image&MultipleOpenEnded";
import eyeIcon from "../CardAssets/eyeIcon.svg";
import CardPreview from "./ModalComponents/CardPreview/CardPreview";
import DragDrop from "./ModalComponents/QuestionCardTypes/Drag&DropText/Drag&Drop";
import FillInBlanksImage from "./ModalComponents/QuestionCardTypes/FillInBlanksImage/FillInBlanksImage";
import axios from "@/services/axios";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../../../Loading/Loading";
import { toast } from "react-toastify";
import TextField from "@/components/Fields/TextField";
import { useTranslation } from "react-i18next";
import ButtonMain from "@/components/buttons/ButtonMain";
import TextArea from "@/components/Fields/TextArea";
import Button from "@/components/buttons/Button";
import { createFormData } from "@/utils/formData";

import { FieldLanguageContext } from "@/context";

export const TopicCardContext = createContext(null);

function QuestionModalsContent({ addCard, cardDetails }) {
  const [questionCardDetails, setQuestionCardDetails] = useState(cardDetails);
  const [isEditing, setIsEditing] = useState(true);
  const [isEditingExtraAnswers, setIsEditingExtraAnswers] = useState(true);
  const [showManualField, setShowManualField] = useState(false);
  const { modal, setModal } = useContext(ModalContext);
  const { id } = useParams();
  const { errors, setErrors } = useContext(ErrorContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const types = getTypes();
  const { fieldLanguage } = useContext(FieldLanguageContext);
  const { t } = useTranslation();

  const showInfoToUser = (message) => toast.success(message);
  const showErrorToUser=(message) => toast.error(message);
  const getCardFields = () => {
    switch (questionCardDetails?.type) {
      case "image-open-ended":
        return <ImageMultipleOpenEnded />;
      case "choose-image":
        return <ChooseImage />;
      case "drag-drop":
        return <DragDrop />;
      case "fill-blanks":
        return <FillInBlanksImage />;
      default:
        return <ChooseText />;
    }
  };

  const showCardPreview = (e) => {
    e.preventDefault();

    setModal(<CardPreview card={questionCardDetails} />);
  };

  const handleCardType = (selectedValues) => {
    if (selectedValues[0] === types?.type) return;

    setQuestionCardDetails({
      type: selectedValues[0],
    });
    setShowManualField(false);
    setIsEditing(true);
  };

  const updateManual = (manual) =>
    setQuestionCardDetails((prev) => ({ ...prev, manual }));

  const saveQuestion = (e, status) => {
    e.preventDefault();
    // setLoading(true);

    let errs = [];
    const card = {
      ...questionCardDetails,
      status,
      topic_id: id,
    };

    console.log('%cCard', 'color: orange;', card);

    const requiredLanguages = {
      en: false,
      et: false
    }

    Object.keys(requiredLanguages).forEach((lng) => {
      if (card.question?.[lng]) {
        requiredLanguages[lng] = true;
      }
    });

    if (!requiredLanguages.en && !requiredLanguages.et) {
      errs.push({ field: 'question', lng: 'en', msg: t('validation.required') });
      errs.push({ field: 'question', lng: 'et', msg: t('validation.required') });
    }

    if (card.type === 'image-open-ended') {
      card.options.forEach((option, index) => {
        Object.keys(requiredLanguages).forEach((lng) => {
          if (!requiredLanguages[lng]) return;

          if (!option.value[lng]) {
            errs.push({ field: 'option', index, lng, msg: t('validation.required') });
          }
        });
      });
    }

    if (card.type === 'choose-image') {
      if (!card.options || !card.options.find((card) => card.is_correct)) {
        errs.push({ field: 'option', msg: t('validation.correct_answer_required') });
      }
    } else if (card.type === 'choose-text') {
      card.options.forEach((option, index) => {
        Object.keys(requiredLanguages).forEach((lng) => {
          if (!requiredLanguages[lng]) return;

          if (!option.value?.[lng]) {
            errs.push({ field: 'option', index, lng, msg: t('validation.required') });
          }
        });
      });

      if (!card.options.find((card) => card.is_correct)) {
        errs.push({ field: 'option', msg: t('validation.correct_answer_required') });
      }
    } else if (card.type === 'drag-drop') {
      Object.keys(requiredLanguages).forEach((lng) => {
        if (!requiredLanguages[lng]) return;

        if (!card.answer.value?.[lng]) {
          errs.push({ field: 'text', lng, msg: t('validation.required') });
        }

        if (!card.options?.[lng]) {
          errs.push({ field: 'option', lng, msg: t('validation.required') });
        }

        if (!card.options[lng].find((card) => card.is_blank)) {
          errs.push({ field: 'option', lng, msg: t('validation.answer_required') });
        }

        if (!card.options[lng].find((card) => card.is_extra)) {
          card.options[lng].filter((option) => option.is_extra)
            .forEach((option, index) => {
              if (option.value.length === 0) {
                errs.push({ field: 'extra', index, lng, msg: t('validation.extra_required') });
              }
            });
        }
      });
    } else if (card.type === 'fill-blanks') {
      card.options.forEach((option, index) => {
        Object.keys(requiredLanguages).forEach((lng) => {
          if (!requiredLanguages[lng]) return;

          if (!option.value?.[lng]) {
            errs.push({ field: 'extra', index, lng, msg: t('validation.required') });
          }
        });
      });
    }

    console.log(errs);

    if (errs.length > 0) {
      showErrorToUser(t('notices.fields_required'));
      setErrors(errs);
      return;
    }

    const payload = createFormData(card);

    if (cardDetails?.id) {
      payload.append('_method', 'PUT')
    }

    const request = !cardDetails?.id
      ? axios.postForm(`/admin/cards`, payload)
      : axios.postForm(`/admin/cards/${cardDetails.id}`, payload);

    request
      .then((response) => {
        if (response?.status < 300) {
          if (addCard) {
            showInfoToUser("The card has been saved!");
            navigate(`/cards/${response?.data?.id}`);
            setModal(null);
            return;
          }

          // update state
          modal.onClose();
          setModal(null);
        }
      })
      .catch(({ response }) => {
        if (typeof response?.data.errors !== "undefined") {
          for (const error in response.data.errors) {
            errs.push([...response.data.errors[error]]);
          }

          setErrors(errs);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteCard = (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .delete(`/admin/cards/${cardDetails.id}`)
      .then(() => {
        showInfoToUser("The card has been deleted!");
        navigate(`/topics/${cardDetails.topic_id}`);
        setModal(null);
      })
      .catch(({ response }) => {
        if (typeof response?.data.errors !== "undefined") {
          let errs = [];

          for (const error in response.data.errors) {
            errs.push([...response.data.errors[error]]);
          }

          setErrors(errs);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!questionCardDetails && cardDetails) {
      setQuestionCardDetails({ ...cardDetails.data });
      setShowManualField(true);
    }
  }, [questionCardDetails, setQuestionCardDetails, cardDetails]);

  useEffect(() => {
    setShowManualField(!isEditing);
  }, [isEditing]);

  if (loading) return <Loading />;

  return (
    <TopicCardContext.Provider
      value={{
        questionCardDetails,
        setQuestionCardDetails,
        setShowManualField,
        isEditing,
        setIsEditing,
        isEditingExtraAnswers,
        setIsEditingExtraAnswers,
      }}
    >
      <div className={`input-section ${!addCard && "no-bottom-padding-card"}`}>
        <form method="post" onSubmit={(e) => saveQuestion(e, "active")}>
          <div className="input-forms">
            <div className="field-group">
              {questionCardDetails?.type && <label htmlFor="type">Type</label>}

              <Dropdown
                id="type"
                options={types}
                selectedValues={
                  questionCardDetails?.type ? [questionCardDetails?.type] : []
                }
                onChange={handleCardType}
                allowMultipleSelections={false}
                defaultLabel="Card Type"
              />
            </div>

            {questionCardDetails?.type && (
              <>
                <TextField
                  className="mb-8"
                  label={t("field.question")}
                  name="question"
                  placeholder={t("field.question")}
                  translatable
                  value={questionCardDetails?.question}
                  setValue={(newValue) => {
                    if (
                      errors &&
                      errors.length > 0 &&
                      errors.find(
                        (el) =>
                          el.field === "question" && el.lng === fieldLanguage,
                      )
                    ) {
                      setErrors(
                        errors.filter(
                          (el) =>
                            el.field !== "question" ||
                            (el.field === "question" &&
                              el.lng !== fieldLanguage),
                        ),
                      );
                    }
                    setQuestionCardDetails({
                      ...questionCardDetails,
                      question: newValue,
                    });
                  }}
                />

                {getCardFields()}
              </>
            )}

            {showManualField && (
              <TextArea
                className="mb-6"
                name="manual"
                onChange={updateManual}
                placeholder={t("list.manual_for_card")}
                translatable
                value={questionCardDetails?.manual}
              />
            )}
          </div>

          {showManualField && (
            <div
              className={`buttons space-y-6 ${
                addCard ? "add-card-buttons" : "edit-card-buttons"
              }`}
            >
              {addCard ? (
                <ButtonMain className="w-full" type="submit">
                  {t("button.save_card")}
                </ButtonMain>
              ) : (
                <ButtonMain className="w-full" type="submit">
                  {t("button.save_changes")}
                </ButtonMain>
              )}

              <div className="flex">
                {addCard ? (
                  <Button
                    className="mx-auto"
                    onClick={(e) => saveQuestion(e, "draft")}
                  >
                    {t("button.save_draft")}
                  </Button>
                ) : (
                  <Button className="mx-auto" onClick={() => setModal(null)}>
                    {t("button.discard_changes")}
                  </Button>
                )}
              </div>

              <div className="flex">
                {addCard ? (
                  <Button className="mx-auto" onClick={() => setModal(null)}>
                    {t("button.cancel")}
                  </Button>
                ) : (
                  <Button className="mx-auto" onClick={deleteCard}>
                    {t("button.delete_card")}
                  </Button>
                )}
              </div>
            </div>
          )}
        </form>
      </div>

      {showManualField && (
        <button
          type="button"
          className="view-card-icon"
          onClick={(e) => showCardPreview(e)}
        >
          <img src={eyeIcon} alt="" />
        </button>
      )}
    </TopicCardContext.Provider>
  );
}

export default QuestionModalsContent;
