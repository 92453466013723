import { createContext, Suspense, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FieldLanguageContext } from "./context";
import axios from '@/services/axios';
import "./i18n";
import i18n from "./i18n";
export const UserContext = createContext(null);
export const ErrorContext = createContext([]);
export const ModalContext = createContext(null);
export const IconModalContext = createContext(null);
export const ParentIdContext = createContext(null);

function App({ children }) {
  const [authToken, setAuthToken] = useState(
    localStorage.getItem("auth_token"),
  );
  const [errors, setErrors] = useState([]);
  const [modal, setModal] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [personalData, setPersonalData] = useState(null);
  const [parentId, setParentId] = useState(null);
  const [fieldLanguage, setFieldLanguage] = useState('et');

  useEffect(() => {
    const mappedErrors = errors.flatMap((error) => {
      return error;
    });

    mappedErrors.forEach((error) => toast.error(error));
  }, [errors]);
  useEffect(() => {
    if (window.location.hostname.includes("localhost")) {
      axios.defaults.headers.common['ngrok-skip-browser-warning'] = '11111';
    }
    let savedLng=localStorage.getItem("lng")
    if(savedLng){
      i18n.changeLanguage(savedLng)
    }
  }, [])

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <UserContext.Provider
        value={{
          authToken,
          setAuthToken,
          currentUser,
          setCurrentUser,
          personalData,
          setPersonalData,
        }}
      >
        <ErrorContext.Provider value={{ errors, setErrors }}>
          <ModalContext.Provider value={{ modal, setModal }}>
            <ParentIdContext.Provider value={{ parentId, setParentId }}>
              <FieldLanguageContext.Provider value={{ fieldLanguage, setFieldLanguage }}>
                {children}
                <ToastContainer autoClose={3000} />
              </FieldLanguageContext.Provider>
            </ParentIdContext.Provider>
          </ModalContext.Provider>
        </ErrorContext.Provider>
      </UserContext.Provider>
    </Suspense>
  );
}

export default App;
