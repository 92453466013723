import QuestionModalsContent from "../QuestionModalsContent";
import LanguageSwitcher from "@components/LanguageSwitcher/LanguageSwitcher";
import { useTranslation } from "react-i18next";

function AddQuestionCard ({ cardDetails }) {
  const { t } = useTranslation();
  return (
    <div className="modal-form-wrapper">
      <div className="col-start-2 question-card-modal content-view">
        <h1 className="uppercase">{t('heading.add_card')}</h1>
        <QuestionModalsContent addCard={true} cardDetails={cardDetails} />
      </div>
      <div className="col-start-3">
        <LanguageSwitcher />
      </div>
    </div>
  );
}

export default AddQuestionCard;
