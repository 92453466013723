import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import useUser from '@/features/user/useUser';

import AdminLayout from '@/components/AdminPanel/AdminPanel';
import Button from '@/components/buttons/Button';
import Container from '@/components/Container/Container';
import { useNavigate } from "react-router-dom";
import H1 from '@/components/headings/H1';
import H2 from '@/components/headings/H2';
import axios from '@/services/axios';
import Header from '@/components/Header/Header';
import HeaderActions from '@/components/Header/HeaderActions';
import Panel from '@/components/Panel/Panel';
import Profile from '@/features/user/Profile';
import EditMember from "@/components/AdminPanel/Team/TeamModals/EditMember/EditMember";
import ButtonDelete from '@/features/user/ButtonDelete';
import { ErrorContext, ModalContext } from "@/App";
import editIcon from "@/components/AdminPanel/AdminPanelAssets/Icons/editIcon.svg"


export default function User () {
  const { id } = useParams();

  const { t } = useTranslation();
  const { loading, setId } = useUser();
  const { setModal } = useContext(ModalContext);
  const [memberData, setMemberData] = useState(null);
  const { setErrors } = useContext(ErrorContext);
  const navigate = useNavigate();

  useEffect(() => {
    setId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onMemberUpdate = (updatedMember) => setMemberData(updatedMember);

  const getMember = useCallback(() => {
    axios
      .get(`/users/${id}`, {
      })
      .then((response) => {
        setMemberData(response?.data);
      })
      .catch(({ response }) => {
        if (typeof response.data.errors !== "undefined") {
          let errs = [];

          for (const error in response.data.errors) {
            errs.push([...response.data.errors[error]]);
          }

          setErrors(errs);
        }

        if (response?.status === 401) {
          navigate("/login");
        }
      });
  }, [id, setErrors, navigate]);

  useEffect(() => {
    if (!memberData?.id) {
        getMember();
    }
  }, [memberData?.id, getMember]);

  return (
    <AdminLayout>
      <Container>
        <Header className="mb-16" loading={loading}>
          <H1>{t('heading.user')} {<span className="font-normal">{memberData?.full_name}</span>}</H1>
          <HeaderActions />
        </Header>

        <div className="grid grid-cols-3 gap-4">
          <Panel className="flex flex-col min-h-[75dvh] col-span-2">
            <div className="flex gap-4 items-center justify-between mb-6">
              <H2>{t('heading.personal_data')}</H2>
              <Button
                onClick={() =>
                  setModal(
                    <EditMember
                      currentMember={memberData}
                      updateMember={onMemberUpdate}
                    />,
                  )
                }
              >
                <img src={editIcon} alt="" />
                {t('button.edit')}
              </Button>
            </div>
            
            {memberData &&  <Profile user={memberData} />          }
           
            <div className="grid mt-auto">
              <ButtonDelete name={memberData?.full_name} onProceed={() => {}} />
            </div>
          </Panel>
        </div>
      </Container>
    </AdminLayout>
  );
}
