import { cn } from '@/utils/utils';

import './Header.css';

function Header ({ className, children }) {
  return (
    <header className={cn('page-header', className)}>
      {children}
    </header>
  )
}

export default Header;
