import MemberModalContent from "../MemberModalContent";
import axios from '@/services/axios';
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { ErrorContext, ModalContext } from "@/App";
import { useTranslation } from "react-i18next";
import H1 from "@/components/headings/H1";

function AddMember() {
  const navigate = useNavigate();
  const { setModal } = useContext(ModalContext);
  const { setErrors } = useContext(ErrorContext);

  const { t } = useTranslation();

  const saveTeamMember = (e, teamMember) => {
    e.preventDefault();

    axios
      .post(`/users`, teamMember)
      .then((response) => {
        if (response.status < 300) {
          const id = response.data.user_id;
          setModal(null);
          navigate(`/team/member/${id}`);
        }
      })
      .catch(({ response }) => {
        if (typeof response.data.errors !== "undefined") {
          let errs = [];

          for (const error in response.data.errors) {
            errs.push([...response.data.errors[error]]);
          }

          setErrors(errs);
        }

        if (response?.status === 401) {
          navigate("/login");
        } else if (response?.status === 403) {
          setErrors(["You are not authorized to perform this action."]);
        }
      });
  };

  return (
    <div className="add-member-view content-view">
      <H1>{t('heading.add_member')}</H1>

      <MemberModalContent onSubmit={saveTeamMember} />
    </div>
  );
}

export default AddMember;
