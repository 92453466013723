import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldLanguageContext } from '@/context';
import { cn } from '@/utils/utils';
import './LanguageSwitcher.css';

const languages = {
  en: { nativeName: 'English', code: 'en', text: 'ENG' },
  et: { nativeName: 'Eesti', code: 'et', text: 'EST' },
};

function LanguageSwitcher ({ className, global = false}) {
  const { i18n } = useTranslation();
  const { fieldLanguage, setFieldLanguage } = useContext(FieldLanguageContext);

  return (
    <div className={cn('language-switcher', className)}>
      {Object.keys(languages).map((lang) => (
        <button
          className="language-button"
          data-active={global ? i18n.language === lang : fieldLanguage === lang}
          key={lang}
          onClick={() => global ? i18n.changeLanguage(lang) : setFieldLanguage(lang)}
          type="button"
        >
          {languages[lang].text}
        </button>
      ))}
    </div>
  );
}

export default LanguageSwitcher;
