import CardPhoneView from "../CardPhoneView/CardPhoneView";
import "./Card.css";
import deleteIcon from "../../../../AdminPanelAssets/Icons/deleteIcon.svg";
import editIcon from "../../../../AdminPanelAssets/Icons/editIcon.svg";
import { useNavigate, useParams } from "react-router-dom";
import CardDetails from "./CardDetails/CardDetails";
import axios from '@/services/axios';
import { useCallback, useContext, useEffect, useState } from "react";
import { ErrorContext, ModalContext } from "../../../../../../App";
import { toast } from "react-toastify";
import Loading from "../../../../../Loading/Loading";
import { getDoubleDigitNumber } from "../CardAssets/utils";
import EditQuestionCard from "../CardModals/EditQuestion/EditQuestionCard";
import { useTranslation } from "react-i18next";
import Container from "@/components/Container/Container";
import Header from "@/components/Header/Header";
import ButtonBack from "@/components/buttons/ButtonBack";
import H1 from "@/components/headings/H1";
import HeaderActions from "@/components/Header/HeaderActions";
import Button from "@/components/buttons/Button";
import Toggle from "@/components/Fields/Toggle";

function Card() {
  const { id } = useParams();
  const [card, setCard] = useState(null);
  const { setErrors } = useContext(ErrorContext);
  const { setModal } = useContext(ModalContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { t } = useTranslation();

  const showInfoToUser = (message) => toast.success(message);

  const getCard = useCallback(() => {
    setLoading(true);

    axios
      .get(`/admin/cards/${id}`)
      .then((response) => {
        setCard(response.data);
      })
      .catch(({ response }) => {
        if (typeof response?.data.errors !== "undefined") {
          let errs = [];

          for (const error in response.data.errors) {
            errs.push([...response.data.errors[error]]);
          }

          setErrors(errs);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, setLoading, setCard, setErrors]);

  const openEditCard = () => {
    setModal({
      component: <EditQuestionCard cardDetails={card} />,
      onClose: () => {
        setModal(null);
        getCard();
      },
    });
  };

  const deleteCard = () => {
    setLoading(true);

    axios
      .delete(`/card/${card.id}`)
      .then(() => {
        showInfoToUser("The card has been deleted!");
        navigate(`/topics/${card.topic_id}`);
      })
      .catch(({ response }) => {
        if (typeof response?.data.errors !== "undefined") {
          let errs = [];

          for (const error in response.data.errors) {
            errs.push([...response.data.errors[error]]);
          }

          setErrors(errs);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editCardStatus = (e, status) => {
    e.preventDefault();

    const action = status === 'draft' ? 'deactivate' : 'activate';

    axios
      .put(`/admin/cards/${card.id}/${action}`)
      .then((response) => {
        setCard((prev) => ({ ...prev, status: response.data.status }));
        showInfoToUser("The card status was successfully changed!");
      })
      .catch(({ response }) => {
        if (typeof response?.data.errors !== "undefined") {
          let errs = [];

          for (const error in response.data.errors) {
            errs.push([...response.data.errors[error]]);
          }

          setErrors(errs);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const backToTopic = () => {
    navigate(`/topics/${card.topic_id}`);
  };

  const getCardNumber = () => {
    // let number;
    // topics
    //   ?.find((topic) => topic.id === card.topic_id)
    //   ?.cards?.map((c, index) => {
    //     if (c.id === card.id) {
    //       number = index;
    //     }
    //     return null;
    //   });

    return getDoubleDigitNumber(card?.id || 0);
  };

  useEffect(() => {
    if (!card && id) {
      getCard();
    }
  }, [card, getCard, id]);

  const toggleStatus = (e) => {
    e.preventDefault();
    editCardStatus(e, card?.status === "active" ? "draft" : "active");
  };

  if (loading) return <Loading />;

  return (
    <Container>
      <Header>
        <ButtonBack onClick={backToTopic} />
        <H1>{t('heading.question', { number: getCardNumber() })}</H1>
        <HeaderActions>
          <Button onClick={deleteCard}>
            <img src={deleteIcon} alt="" />
            {t('button.delete_card')}
          </Button>
          <Button onClick={openEditCard}>
            <img src={editIcon} alt="" />
            <span>{t('button.edit')}</span>
          </Button>
          <Toggle
            labelFalse={t('button.draft')}
            labelTrue={t('button.active')}
            value={card?.status === 'active'}
            onChange={toggleStatus}
          />
        </HeaderActions>
      </Header>

      <div className="grid grid-cols-3 gap-8">
        <div className="grid justify-end">
          <CardPhoneView card={card} />
        </div>

        <div className="col-span-2">
          <CardDetails card={card} />
        </div>
      </div>
    </Container>
  );
}

export default Card;
