import "../TopicModalContent/ModalContent.css";
import ModalContent from "../TopicModalContent/ModalContent";
import axios from '@/services/axios';
import { useContext } from "react";
import { ErrorContext, ModalContext, ParentIdContext } from "@/App";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "@/components/LanguageSwitcher/LanguageSwitcher";

function AddTopic({ createdTopic }) {
  const { setErrors } = useContext(ErrorContext);
  const { setModal } = useContext(ModalContext);
  const { parentId } = useContext(ParentIdContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const saveTopic = (e, topic, status) => {
    e.preventDefault();

    const topicToSave = { ...topic, parent_id: parentId, status };

    axios
      .post(`/topics`, topicToSave)
      .then((response) => {
        const id = response.data.topic.id;

        if (response.status < 300) {
          setModal(null);
          navigate(`/topics/${id}`);
        }
      })
      .catch(({ response }) => {
        if (typeof response.data.errors !== "undefined") {
          let errs = [];

          for (const error in response.data.errors) {
            errs.push([...response.data.errors[error]]);
          }

          setErrors(errs);
        }

        if (response?.status === 401) {
          setModal(null);
          navigate("/login");
        }
      });
  };

  return (
    <div className="modal-form-wrapper">
      <div className="col-start-2 modal-member-view content-view">
          <h1>{t(parentId ? 'heading.add_subtopic' : 'heading.add_topic')}</h1>

          <ModalContent
            buttonText={"save new topic"}
            onSubmit={saveTopic}
            createdTopic={createdTopic}
          />
      </div>
      <div className="col-start-3">
        <LanguageSwitcher />
      </div>
    </div>
  );
}

export default AddTopic;
