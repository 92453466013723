import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import useUsers from '@/features/user/useUsers';

import { UserContext } from '@/App';

import AdminLayout from '@/components/AdminPanel/AdminPanel';
import ButtonMain from '@/components/buttons/ButtonMain';
import H1 from '@/components/headings/H1';
import Header from '@/components/Header/Header';
import HeaderActions from '@/components/Header/HeaderActions';
import selectArrow from "../components/AdminPanel/AdminPanelAssets/Icons/selectArrow.svg";


import UserList from '@/features/user/List';

export default function UserIndex () {
  const { t } = useTranslation();

  const { currentUser } = useContext(UserContext);

  const { users, total, page, perPage, setPage } = useUsers();

  const totalPages = Math.ceil(total / perPage);

  return (
    <AdminLayout>
      <div className="admin-panel-item">
        <Header>
          <H1>{t('heading.users')} {total && <span className="font-normal">({total})</span>}</H1>
          <HeaderActions>
            {currentUser?.userRole === "super-admin" || currentUser?.topicAdding
              ? (
                <ButtonMain>
                  + {t('button.add_user')}
                </ButtonMain>
              ) : null}
          </HeaderActions>
        </Header>

        <UserList users={users} />
        {total > perPage && (
          <div className="pagination">
            <button
              type="button"
              onClick={() => setPage(page - 1)} 
              disabled={page === 1}
            >
              <img src={selectArrow} alt="" className="back-arrow" />
              <span>BACK</span>
            </button>

            <button
              type="button"
              onClick={() => setPage(page + 1)} 
              disabled={page === totalPages}
            >
              <span>NEXT</span>
              <img src={selectArrow} alt="" className="next-arrow" />
            </button>
          </div>
        )}
      </div>
    </AdminLayout>
  );
}
