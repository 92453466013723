import "./SelectIcon.css";
import MediaSelectorButton from "../../TopicsAssets/MediaSelectorButton/MediaSelectorButton";
import axios from '@/services/axios';
import { useContext, useRef, useState } from "react";
import { ErrorContext } from "../../../../../App";
import selectedIcon from "./selected.svg";
import H2 from "@/components/headings/H2";
import { useTranslation } from "react-i18next";
import ButtonMain from "@/components/buttons/ButtonMain";
import Button from "@/components/buttons/Button";

function SelectIcon({ onIconSelect, icons, topic, selectedColor }) {
  const [selected, setSelected] = useState(topic?.icon ?? null);
  const [uploadedIcon, setUploadedIcon] = useState(false);
  const filePickerRef = useRef(null);
  const { setErrors } = useContext(ErrorContext);
  const { t } = useTranslation();

  const selectIcon = (iconName) => {
    setSelected(iconName);
    setUploadedIcon(false);
  };

  const chooseIcon = () => {
    if (filePickerRef.current) {
      filePickerRef.current.click();
    }
  };

  const uploadIcon = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("icon", file);

    axios
      .post(`/topics/upload-icon`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        setSelected(response?.data?.path);
        setUploadedIcon(true);
      })
      .catch((_) => {
        setErrors(["Something went wrong ... please try again."]);
      });
  };

  // Function to map the selected color to a corresponding CSS class
  const getColorClass = (color) => {
    switch (color) {
      case "red":
        return "icon-background-red";
      case "pink":
        return "icon-background-pink";
      case "yellow":
        return "icon-background-yellow";
      default:
        return ""; // No class if no color is selected or color is not mapped
    }
  };

  return (
    <div className="select-icon-component">
      <div className="icon-component-title">
        <H2 className="h1">{t('heading.choose_topic_icon')}</H2>
      </div>

      <ul className="icons-list mb-auto">
        {icons.map((icon, index) => {
          return (
            <li key={index}>
              <Button
                className={`icon-to-select ${getColorClass(selectedColor)}`}
                onClick={() => selectIcon(icon.name)}
              >
                <img src={icon.src} alt={icon.name} />

                {selected === icon.name && (
                  <div className="selected">
                    <img src={selectedIcon} alt="" />
                  </div>
                )}
              </Button>
            </li>
          );
        })}

        <li>
          {uploadedIcon ? (
            <div className={`icon-to-select ${getColorClass(selectedColor)}`}>
              <img src={`${window.STORAGE_URL}/${selected}`} alt={selected} />

              <div className="selected">
                <img src={selectedIcon} alt="" />
              </div>
            </div>
          ) : (
            <>
              <input
                type="file"
                ref={filePickerRef}
                onChange={uploadIcon}
                style={{ display: "none" }}
                accept=".jpg, .jpeg, .png"
              />

              <MediaSelectorButton onClick={chooseIcon} />
            </>
          )}
        </li>
      </ul>

      <div className="mt-auto">
        <ButtonMain
          className="w-full mt-6"
          onClick={() => onIconSelect(topic, selected)}
        >
          {t('button.confirm')}
        </ButtonMain>
      </div>
    </div>
  );
}

export default SelectIcon;
