import { useEffect, useState } from 'react';
import axios from '@/services/axios';

export default function useUsers () {
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState([]);

  useEffect(() => {
    setLoading(true);

    const controller = new AbortController();

    axios.get('/users', {
      params: {
        page,
        per_page: perPage,
        role: role.join(','),
      },
      signal: controller.signal
    })
      .then((response) => {
        console.log('%cResponse', 'color: orange', response);
        setUsers(response.data.users);
        setTotal(response.data.total);
      })
      .catch((error) => {
        console.error('%cError', 'color: red', error);
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      controller.abort();
    }
  }, [page, perPage, role]);

  return {
    users: users,

    loading: loading,
    total: total,

    page: page,
    perPage: perPage,
    role: role,

    setPage: setPage,
    setPerPage: setPerPage,
    setRoles: setRole
  }
}
