import "./TableTopicsAccess.css";
import downArrow from "./down-arrow.svg";
import closeIcon from "../../AdminPanelAssets/Icons/closeIcon.svg";
import { useContext, useState } from "react";
import { UserContext } from "../../../../App";
import useTranslatableContent from "@hooks/useTranslatableContent";
import { useTranslation } from "react-i18next";

function TableTopicsAccess({
  userTopics,
  allTopicsLength,
  userId,
  onTopicDelete,
}) {
  const [showAllTopics, setShowAllTopics] = useState(false);
  const { currentUser } = useContext(UserContext);
  const { t } = useTranslation();

  const maybeTranslate = useTranslatableContent();

  return (
    <div className="table-topics-access-component">
      {!userTopics?.length ? (
        <span className="no-topic-access">{t('tableTopicsAccess.no_topics')}</span>
      ) : userTopics?.length === allTopicsLength ? (
        <span className="all-topics">{t('tableTopicsAccess.all_topics')}</span>
      ) : (
        <div className={`topics-access ${!showAllTopics && "has-more-topics"}`}>
          {userTopics?.map((topic, index) => {
            if (index > 2 && !showAllTopics) return null;

            return (
              <div className="topic-access" key={topic.id}>
                <span className="topic-name">{maybeTranslate(topic.name)}</span>

                {currentUser?.userRole === "super-admin" && (
                  <button
                    type="button"
                    onClick={(e) =>
                      onTopicDelete(e, userId, topic.id, userTopics)
                    }
                  >
                    <img src={closeIcon} alt="" />
                  </button>
                )}
              </div>
            );
          })}

          {userTopics.length > 3 && !showAllTopics && (
            <div className="hidden-topics">{`+${userTopics.length - 3}`}</div>
          )}
        </div>
      )}

      {userTopics.length > 3 && !showAllTopics && (
         <div className="show-more">
          <div
            className={`show-more-button ${showAllTopics && "open-button"}`}
            role="button"
            onClick={() => setShowAllTopics(!showAllTopics)}
          >
            <img src={downArrow} alt="" />
          </div>
        </div>
      )}
     
    </div>
  );
}

export default TableTopicsAccess;
