import { useContext,useState,useEffect } from 'react';
import { FieldLanguageContext } from '@/context';
import { cn } from '@/utils/utils';
import './fields.css';
import { ErrorContext } from '@/App';
export default function TextArea ({ className, label, name, translatable = false, value = null, onChange = null,index, ...props }) {
  const { fieldLanguage } = useContext(FieldLanguageContext);
  const { errors } = useContext(ErrorContext);
  const [localError, setLocalError] = useState();
  useEffect(() => {
    console.log("All errors", errors);
    console.log("Field lang", fieldLanguage);
    console.log("Field type", name);
    if (errors && errors.length > 0) {
        setLocalError(
          errors.find(
            (el) =>
              el.field === name &&
              el.lng === fieldLanguage
          ),
        );
      
    }
  }, [errors, fieldLanguage,index,name]);
  function handleInput(e) {
    if (localError) {
      setLocalError(undefined);
    }
    onChange(translatable ? { ...value, [fieldLanguage]: e.target.value } : e.target.value)
  }
  return (
    <div className="field-group">
      {label && <label htmlFor={name}>{label}</label>}

      <textarea
        className={`${cn("form-control", className)} ${localError && localError.lng === fieldLanguage ? "error" : ""}`}
        id={name}
        name={name}
        onInput={(e) => handleInput(e)}
        value={translatable ? value?.[fieldLanguage] || '' : value}
        {...props}
      />
    </div>
  );
}
