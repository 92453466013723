import Button from './Button';
import backArrow from "@components/AdminPanel/AdminPanelAssets/Icons/backArrow.svg";
import { cn } from '@/utils/utils';

export default function ButtonBack (props) {
  return (
    <Button {...props} className={cn('button--back', props.className)}>
      <img src={backArrow} alt="" />
    </Button>
  );
}
