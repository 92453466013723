import LineChart from "./LineChart";
import ColumnChart from "./ColumnChart";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import H2 from "@/components/headings/H2";

function Charts() {
  const [sessionsButtonActive, setSessionsButtonActive] = useState("week");
  const [statsButtonActive, setStatsButtonActive] = useState("week");
  const {t} = useTranslation()

  return (
    <div className="charts">
      <div className="sessions-time">
        <header>
          <H2>{t('statistics.average_session_time')}</H2>

          <div className="filter-buttons">
            <button
              type="button"
              className={
                sessionsButtonActive === "month" ? "active-button" : null
              }
              onClick={() => setSessionsButtonActive("month")}
            >
              {t('statistics.last_month')}
            </button>
            <button
              type="button"
              className={
                sessionsButtonActive === "week" ? "active-button" : null
              }
              onClick={() => setSessionsButtonActive("week")}
            >
              {t('statistics.last_7_days')}
            </button>
          </div>
        </header>

        <div className="chart-section">
          <LineChart sessionsButtonActive={sessionsButtonActive} />
        </div>
      </div>

      <div className="usage">
        <header>
          <H2>{t('statistics.usage_statistics')}</H2>

          <div className="filter-buttons">
            <button
              type="button"
              className={statsButtonActive === "month" ? "active-button" : null}
              onClick={() => setStatsButtonActive("month")}
            >
              {t('statistics.last_month')}
            </button>
            <button
              type="button"
              className={statsButtonActive === "week" ? "active-button" : null}
              onClick={() => setStatsButtonActive("week")}
            >
              {t('statistics.last_7_days')}
            </button>
          </div>
        </header>

        <div className="chart-section relative">
          <div className="absolute flex right-6 top-3 gap-3 items-center">
            <div className="flex items-center gap-1">
              <div className="block rounded-full w-3 h-3 bg-[#D7DF23]"></div>
              <p className="text-xs text-black">{t('statistics.downloads')}</p>
            </div>
            <div className="block w-px h-2 bg-[#18181B]"></div>
            <div className="flex items-center gap-1">
              <div className="block rounded-full w-3 h-3 bg-[#FFBBD3]"></div>
              <p className="text-xs text-black">{t('statistics.unistalls')}</p>
            </div>
          </div>
          <ColumnChart statsButtonActive={statsButtonActive}/>
        </div>
      </div>
    </div>
  );
}

export default Charts;
