import useTranslatableContent from "@/hooks/useTranslatableContent";

import H2 from "@/components/headings/H2";

import "./card.css";

export default function PreviewManual({ card, isOpen }) {
  const maybeTranslate = useTranslatableContent();
  console.log(card);
  return isOpen ? (
    <div className="preview__modal preview__modal--manual">
      <H2 className="mb-8 text-center text-white">
        {maybeTranslate(card?.topic_name)}
      </H2>
      <p style={{ whiteSpace: "pre-line" }}>{maybeTranslate(card?.manual)}</p>
    </div>
  ) : null;
}
