import index from "./routes/index";
import login from "./routes/login";
import resetPassword from "./routes/resetPassword";
import createPassword from "./routes/createPassword";
import noAccess from "./routes/noAccess";
import notFound from "./routes/notFound";
import stats from "./routes/stats";
import topics from "./routes/topics";
import notifications from "./routes/notifications";
import settings from "./routes/settings";
import team from "./routes/team";
import topic from "./routes/topic";
import teamMember from "./routes/teamMember";
import card from "./routes/card";
import user from "./routes/user";
import users from "./routes/users";

const routes = [
  index,
  login,
  resetPassword,
  createPassword,
  noAccess,
  stats,
  topics,
  settings,
  team,
  notifications,
  topic,
  teamMember,
  card,
  users,
  user,
  notFound,
];

export default routes;
