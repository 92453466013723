import Button from './Button';
import { cn } from '@/utils/utils';
import './buttons.css';

export default function ButtonBlack (props) {
  return (
    <Button {...props} className={cn('button--black', props.className)}>
      {props.children}
    </Button>
  );
}
