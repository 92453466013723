import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { TopicCardContext } from '@components/AdminPanel/Topics/Topic/Cards/CardModals/QuestionModalsContent';

import Button from '@components/buttons/Button';
import ButtonBlack from '@components/buttons/ButtonBlack';
import editIcon from "@components/AdminPanel/Topics/Topic/Cards/CardAssets/editIcon.svg";
import H3 from '@components/headings/H3';
import QuestionFormOption from './QuestionFormOption';

export default function QuestionFormOptions ({ options, onAdd, onChange, onDelete }) {
  const { isEditing, setIsEditing, setShowManualField } = useContext(TopicCardContext);

  const { t } = useTranslation();

  const add = () => onAdd();
  const remove = (index) => onDelete(index);
  const update = (index, value) => onChange(index, value);

  const edit = () => {
    if (!isEditing) {
      setIsEditing(true);
      setShowManualField(false);
    }
  };

  return (
    <>
      <div className="flex items-center gap-6 mb-6">
        <H3 onClick={edit}>
          {isEditing ? t('heading.add_answers') : t('heading.answers_number', { number: options?.length })}
        </H3>
        <Button
          className="edit-answers"
          onClick={() => setIsEditing(true)}
        >
          <img src={editIcon} alt="" />
        </Button>
      </div>

      <div className="space-y-4 mb-8">
        {options && options?.length > 0
          ? options?.map((option, index) => (
            <QuestionFormOption
              disabled={!isEditing}
              key={index}
              value={option.value}
              onChange={(value) => update(index, value)}
              onDelete={() => remove(index)}
              index={index}
            />
          ))
          : null
        }
      </div>

      {isEditing
        ? (
          <>
            <ButtonBlack
              className="w-full mb-4"
              onClick={add}
            >
              + {t('button.add_answer')}
            </ButtonBlack>
            <Button
              className="flex mx-auto"
              onClick={() => setIsEditing(false)}
            >
              {t('button.confirm')}
            </Button>
          </>
        ) : null}
    </>
  );
}
