import "./TopicsAccess.css";
import useTranslatableContent from "@hooks/useTranslatableContent";
import useTopics from "@hooks/useTopics";
import Checkbox from "@/components/Fields/Checkbox";
import List from "@/components/List/List";
import ListItem from "@/components/List/ListItem";
import { useTranslation } from "react-i18next";

function TopicsAccess ({ selected, onChange }) {
  const topics = useTopics({ fullData: false });
  const maybeTranslate = useTranslatableContent();
  const allSelected = topics?.every(
    (topic) => selected?.includes(topic.value) && topic.subtopics?.every((subtopic) => selected?.includes(subtopic.value))
  );
  const { t } = useTranslation();

  const select = (value) => {
    if (selected?.includes(value)) {
      onChange(selected.filter((v) => v !== value));
    } else {
      onChange([...selected, value]);
    }
  };

  const selectAll = () => allSelected
    ? onChange([])
    : onChange(topics.reduce((acc, topic) => [
      ...acc,
      topic.value,
      ...topic.subtopics?.map((subtopic) => subtopic.value) ?? [],
    ], []));

  return topics?.length
    ? (
        <List className="topics-list">
          <ListItem key="all-topics">
            <Checkbox
              checked={allSelected}
              label={t('button.all')}
              name="topic"
              onChange={selectAll}
            />
          </ListItem>
          {topics.map((topic) => (
            <ListItem key={topic.value}>
              <Checkbox
                checked={selected?.includes(topic.value)}
                label={maybeTranslate(topic.label)}
                name="topic"
                onChange={() => select(topic.value)}
              />
              {topic?.subtopics?.length ? <List className="mt-3 ml-3">
                {topic.subtopics.map((subtopic) => (
                  <ListItem key={subtopic.value}>
                    <Checkbox
                      checked={selected?.includes(subtopic.value)}
                      label={maybeTranslate(subtopic.label)}
                      name="topic"
                      onChange={() => select(subtopic.value)}
                    />
                  </ListItem>))}
              </List> : null}
            </ListItem>
          ))}
        </List>
    )
    : (
      <span className="no-topics-created">
        There are no topics created.
      </span>
    )
};

export default TopicsAccess;
