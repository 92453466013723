import { useTranslation } from "react-i18next";

import BaseDialog from "./BaseDialog";

import './dialogs.css';
import ButtonMain from "../buttons/ButtonMain";

export default function ConfirmationDialog ({ children, onClose, onProceed, ...props }) {
  const { t } = useTranslation();

  const proceedAndClose = () => {
    onProceed();
    onClose();
  }

  return (
    <BaseDialog
      onClose={onClose}
      size="sm"
      {...props}
    >
      <div className="mb-8">
        {children}
      </div>

      <ButtonMain className="w-full mt-auto" onClick={proceedAndClose}>{t('button.confirm')}</ButtonMain>
    </BaseDialog>
  );
}
