import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Table, { TableCol, TableRow } from "../../AdminPanelAssets/Table/Table";
import axios from '@/services/axios';
import { ErrorContext } from "../../../../App";
import MessageColumn from "./MessageColumn";
import selectArrow from "../../AdminPanelAssets/Icons/selectArrow.svg";


function FeedbackTable() {
  const [usersFeedback, setUsersFeedback] = useState([]);
  const [feedbackPage, setFeedbackPage] = useState(1);
  const [oldFeedbackPage, setOldFeedbackPage] = useState(feedbackPage);
  const [buttonIsDisabled, setButtonIsDisabled] = useState(false);
  const { setErrors } = useContext(ErrorContext);
  const { t } = useTranslation();

  const getDate = (dateString) => {
    const fullDate = new Date(dateString);
    const day = fullDate.getDate();
    const month = fullDate.getMonth() + 1;
    const year = fullDate.getFullYear();

    return `${("0" + day).slice(-2)}/${("0" + month).slice(-2)}/${year}`;
  };

  const getUsersFeedback = useCallback(
    (oldPage, newPage) => {
      axios
        .get(`/feedback`, {
          params: { page: newPage },
        })
        .then((response) => {
          console.log(response.data)
          if (!response?.data?.length) {
            setFeedbackPage(oldPage);
            setButtonIsDisabled(true);
            return;
          }

          if (response?.data?.length < 10) {
            setButtonIsDisabled(true);
          } else {
            setButtonIsDisabled(false);
          }

          setOldFeedbackPage(newPage);
          setUsersFeedback(response?.data);
        })
        .catch(({ response }) => {
          if (typeof response?.data?.errors !== "undefined") {
            let errs = [];

            for (const error in response.data.errors) {
              errs.push([...response.data.errors[error]]);
            }

            setErrors(errs);
          }
        });
    },
    [setErrors],
  );

  useEffect(() => {
    if (!usersFeedback?.length || feedbackPage !== oldFeedbackPage) {
      getUsersFeedback(oldFeedbackPage, feedbackPage);
    }
  }, [usersFeedback, getUsersFeedback, feedbackPage, oldFeedbackPage]);

  return (
    <div className="feedback">
      {/* <H2>USERS FEEDBACK</H2> */}

      <div className="users-table">
        {!usersFeedback.length && feedbackPage === 1 ? (
          "There is currently no users feedback."
        ) : (
          <Table>
            <thead>
              <TableRow>
                <TableCol heading={true}>{t('notifications.user')}</TableCol>
                <TableCol heading={true}>{t('notifications.role')}</TableCol>
                <TableCol heading={true}>{t('notifications.email')}</TableCol>
                <TableCol heading={true}>{t('notifications.date')}</TableCol>
                <TableCol heading={true}>{t('notifications.topic')}</TableCol>
                <TableCol heading={true} messageColumn={true}>
                  {t('notifications.message')}
                </TableCol>
              </TableRow>
            </thead>

            <tbody>
              {usersFeedback?.map((user) => (
                <TableRow key={user?.id}>
                  <TableCol>{user?.user ?? "-"}</TableCol>
                  <TableCol>{user?.role ?? "-"}</TableCol>
                  <TableCol>{user?.email ?? "-"}</TableCol>
                  <TableCol>
                    {user?.created_at ? getDate(user?.created_at) : "-"}
                  </TableCol>
                  <TableCol>{user?.topic ?? "-"}</TableCol>
                  <TableCol messageColumn={true}>
                    <MessageColumn message={user?.message ?? "-"} />
                  </TableCol>
                </TableRow>
              ))}
            </tbody>
          </Table>
        )}
      </div>

      {usersFeedback?.length ? (
        <div className="pagination">
          <button
            type="button"
            disabled={feedbackPage === 1}
            onClick={() => setFeedbackPage(feedbackPage - 1)}
          >
            <img src={selectArrow} alt="" className="back-arrow" />
            <span>{t('notifications.back')}</span>
          </button>

          <button
            type="button"
            onClick={() => setFeedbackPage(feedbackPage + 1)}
            disabled={buttonIsDisabled}
          >
            <span>{t('notifications.next')}</span>
            <img src={selectArrow} alt="" className="next-arrow" />
          </button>
        </div>
      ) : null}
    </div>
  );
}

export default FeedbackTable;
