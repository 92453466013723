import ButtonBlack from "@/components/buttons/ButtonBlack";
import TextField from "@/components/Fields/TextField";
import Icon from "@/components/Icons/Icon";

export default function AnswerListItem({
  disabled,
  value,
  onChange,
  onDelete,
  prepend,
  index
}) {
  return (
    <div className="flex gap-2">
      <TextField
        disabled={disabled}
        prepend={prepend}
        setValue={onChange}
        translatable
        value={value}
        name={"answer"}
        index={index}
      />
      {!disabled && (
        <div>
          <ButtonBlack className="w-6 h-6 p-1 rounded" onClick={onDelete}>
            <Icon icon="trash" />
          </ButtonBlack>
        </div>
      )}
    </div>
  );
}
