import AdminPanel from "../components/AdminPanel/AdminPanel";
import TeamMember from "../components/AdminPanel/Team/TeamMember/TeamMember";
import HOC from "../components/HOC/HOC";

const route = {
  path: "/team/member/:id",
  element: (
    <HOC>
      <AdminPanel>
        <TeamMember />
      </AdminPanel>
    </HOC>
  ),
};

export default route;
