import { getMediaLetter } from "../../CardAssets/QuestionOptions";
import useTranslatableContent from "@/hooks/useTranslatableContent";

function ChooseTextPreview ({ card }) {
  const maybeTranslate = useTranslatableContent();
  return (
    <div className="preview-option">
      <div className="answers-preview">
        {card?.options?.map((option, index) => {
          return (
            <div key={index} className="answer-preview">
              <span>{getMediaLetter(index + 1).toLowerCase()}.</span>
              <span className="answer-value">
                {maybeTranslate(option?.value)}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
}
export default ChooseTextPreview;
