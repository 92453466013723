import { useRef /* , useEffect, useState */ } from 'react';
// import Icon from '@/components/Icons/Icon';
import addIcon from "@/components/AdminPanel/Topics/TopicsAssets/MediaSelectorButton/addIcon.svg";
import './fields.css'

export default function FileField ({
  accept = "image/*",
  children = null,
  disabled = false,
  // limit = 0,
  multiple = false,
  // value = null,
  setValue = null,
}) {
  // const [previews, setPreviews] = useState([]);
  const fileInputRef = useRef(null);

  const handleChange = (event) => {
    update(Array.from(event.target.files));
  }

  const handleClick = () => {
    fileInputRef.current.click();
  }

  const handleDragOver = (event) => {
    event.preventDefault();
  }

  const handleDrop = (event) => {
    event.preventDefault();
    update(Array.from(event.dataTransfer.files));
  }

  // const remove = (index) => () => {
  //   if (!setValue) {
  //     return;
  //   }

  //   setValue(value.filter((_, i) => i !== index));
  // }

  const update = (files) => {
    if (disabled || !setValue) {
      return;
    }

    if (!multiple) {
      setValue([files[0]]);
    }

    setValue(files);
  }

  // useEffect(() => {
  //   const newPreviews = [];

  //   if (value && value.length) {
  //     value.forEach((file) => {
  //       newPreviews.push(URL.createObjectURL(file));
  //     });
  //   }

  //   const start = newPreviews.length;

  //   for (let i = start; i < limit; i++) {
  //     newPreviews.push(null);
  //   }

  //   setPreviews(newPreviews);
  // }, [limit, value])

  return (
    <>
      <div
        className="file-field__group"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <button
          className="file-field__control"
          disabled={disabled}
          onClick={handleClick}
          type="button"
        >
          <div className="file-field__button">
            <img src={addIcon} alt="" />
          </div>
          <input
            accept={accept}
            disabled={disabled}
            multiple={multiple}
            onChange={handleChange}
            ref={fileInputRef}
            style={{ display: "none" }}
            type="file"
          />
        </button>

        {children}

        {/* previews?.map((file, index) => (
          <div key={index} className="file-field__preview">
            {file !== null ? (
              <>
                <button
                  className="absolute top-1.5 right-1.5 grid place-content-center w-6 h-6 rounded-full bg-black text-white"
                  onClick={remove(index)}
                  type="button"
                >
                  <Icon icon="trash" />
                </button>
                <img src={file} alt="" />
              </>
            ) : null}
          </div>
        )) */}
      </div>
    </>
  )
}
