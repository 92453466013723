import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { TopicCardContext } from "@components/AdminPanel/Topics/Topic/Cards/CardModals/QuestionModalsContent";

import { cn } from "@/utils/utils";
import { getMediaLetter } from "@components/AdminPanel/Topics/Topic/Cards/CardAssets/QuestionOptions";

import H3 from "@components/headings/H3";

export default function CorrectAnswers({ onChange, options,localError }) {
  const { isEditing, setShowManualField } = useContext(TopicCardContext);
  const { t } = useTranslation();
  useEffect(() => {
    setShowManualField(options && options.some((option) => option.is_correct));
  }, [options, setShowManualField]);

  
  return (
    <div className="answers-section">
      {!isEditing && options?.length > 0 ? (
        <div className="field-group">
          <H3 className="mb-6">{t("heading.correct_answers")}</H3>
          {localError && (
            <p className="text-red-700">
              Please choose correct answer/s
            </p>
          )}
          <div className="correct-answer-options">
            {options?.map((option, index) => (
              <button
                className={cn(
                  "correct-answer",
                  option.is_correct ? "selected" : null,
                )}
                key={index}
                onClick={() => onChange(index)}
                type="button"
              >
                {getMediaLetter(index + 1)}
              </button>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
}
