import { useContext, useEffect, useState } from "react";
import { TopicCardContext } from "../../../QuestionModalsContent";
import "./FillInBlanksImage.css";

import DraggablePills from "./DraggablePills";

import { useTranslation } from "react-i18next";
import H3 from "@/components/headings/H3";
import ButtonBlack from "@/components/buttons/ButtonBlack";
import Icon from "@/components/Icons/Icon";
import AnswerList from "./AnswerList";
import ExtraAnswers from "./ExtraAnswers";

import { v4 as uuidv4 } from 'uuid';
import FileField from "@/components/Fields/FileField";
import { sourceUrl } from "@/utils/utils";

function FillInBlanksImage() {
  const [mediaIsUploaded, setMediaIsUploaded] = useState(false);
  const [isEditingBlankFields, setIsEditingBlankFields] = useState(false);

  const { t } = useTranslation();

  const {
    setShowManualField,
    isEditing,
    setIsEditing,
    questionCardDetails,
    setQuestionCardDetails,
    isEditingExtraAnswers,
    setIsEditingExtraAnswers,
  } = useContext(TopicCardContext);

  useEffect(() => {
    if (questionCardDetails.media?.[0]) {
      setMediaIsUploaded(true);
    }
  }, [questionCardDetails.media]);

  const editMedia = () => {
    setShowManualField(false);
    setMediaIsUploaded(false);
  };

  const editBlankFields = () => {
    setIsEditingBlankFields(true);
    setShowManualField(false);
    setIsEditing(true);
  };

  const hasExtraAnswers = (questionCardDetails?.options || []).some((option) => option?.is_extra);

  const updateOptions = (options) => setQuestionCardDetails((prev) => ({ ...prev, options }));

  const handleBlankConfirmation = () => {
    setIsEditingBlankFields(false);
    setIsEditingExtraAnswers(true);
    setShowManualField(false);
  };

  const handleAnswersConfirm = () => {
    if (!hasExtraAnswers) {
      updateOptions([...questionCardDetails.options, { id: uuidv4(), value: '', is_extra: true }]);
    }
    setIsEditing(false);
    setShowManualField(false);
  };

  const editAnswers = () => {
    setIsEditing(true);
    setShowManualField(false);
    setIsEditingExtraAnswers(true);
  };

  const editExtraAnswers = () => {
    setIsEditingExtraAnswers(true);
    setShowManualField(false);
  };

  const handleExtraAnswersConfirm = () => {
    setShowManualField(true);
    setIsEditingExtraAnswers(false);
  };

  const updateFiles = (files) => {
    setQuestionCardDetails((prev) => {
      setMediaIsUploaded(true);
      setIsEditingBlankFields(true);
      return {
        ...prev,
        media: files?.map((file) => URL.createObjectURL(file)) || [],
        files
      }
    });
  }

  return (
    <div className="card-type-component">
      <div className="upload-media-section">
        {!mediaIsUploaded
          ? (
            <H3 className="mb-6">{t('heading.the_media')}</H3>
          )
          : (
            <div className="flex items-center gap-6 mb-6">
              <H3>{t('heading.the_media')}</H3>
              <ButtonBlack className="w-7 h-7 p-2 text-xs" onClick={editMedia}>
                <Icon icon="pen" />
              </ButtonBlack>
            </div>
          )
        }

        <div className="media">

          {mediaIsUploaded ? (
            <div className="uploaded-media">
              <img
                src={sourceUrl(questionCardDetails?.media?.[0])}
                alt=""
              />
            </div>
          ) : (
            <FileField
              disabled={!isEditing}
              setValue={updateFiles}
            />
          )}
        </div>
      </div>

      {mediaIsUploaded ? (
        <>
          {isEditingBlankFields ? (
            <H3 className="mb-6">
              {t('heading.blanks')}
            </H3>
          ) : (
            <>
              <div className="flex items-center gap-6 mb-6">
                <H3>{t('heading.blanks')}</H3>
                <ButtonBlack className="w-7 h-7 p-2 text-xs" onClick={editBlankFields}>
                  <Icon icon="pen" />
                </ButtonBlack>
              </div>
            </>
          )}

          <DraggablePills
            disabled={!isEditingBlankFields}
            image={sourceUrl(questionCardDetails?.media?.[0])}
            options={questionCardDetails?.options}
            onChange={updateOptions}
            onConfirm={handleBlankConfirmation}
          />
        </>
      ) : null}

      {!isEditingBlankFields && mediaIsUploaded && questionCardDetails?.options
        ? (
          <>
            {isEditing ? (
              <H3 className="mb-6">
                {t('heading.type_answers')}
              </H3>
            ) : (
              <>
                <div className="flex items-center gap-6 mb-6">
                  <H3>{t('heading.answers')}</H3>
                  <ButtonBlack className="w-7 h-7 p-2 text-xs" onClick={editAnswers}>
                    <Icon icon="pen" />
                  </ButtonBlack>
                </div>
              </>
            )}

            <AnswerList
              options={questionCardDetails.options}
              disabled={!isEditing}
              onChange={updateOptions}
              onConfirm={handleAnswersConfirm}
            />
          </>
        ) : null
      }

      {!isEditing && mediaIsUploaded && (
        <div className="answers-section">
          {isEditingExtraAnswers ? (
            <H3 className="mb-6">
              {t('heading.add_extra_answer')}
            </H3>
          ) : (
            <>
              <div className="flex items-center gap-6 mb-6">
                <H3>{t('heading.extra_answers')}</H3>
                  <ButtonBlack className="w-7 h-7 p-2 text-xs" onClick={editExtraAnswers}>
                  <Icon icon="pen" />
                </ButtonBlack>
              </div>
            </>
          )}

          {isEditingExtraAnswers || hasExtraAnswers ? (
            <ExtraAnswers
              disabled={!isEditingExtraAnswers}
              options={questionCardDetails?.options || []}
              onChange={updateOptions}
              onConfirm={handleExtraAnswersConfirm}
            />
          ) : null}
        </div>
      )}
    </div>
  );
}

export default FillInBlanksImage;
