import H2 from "@/components/headings/H2";
import QuestionModalsContent from "../QuestionModalsContent";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "@/components/LanguageSwitcher/LanguageSwitcher";

function EditQuestionCard ({ cardDetails }) {
  const { t } = useTranslation();
  return (
    <div className="grid grid-cols-3">
      <div className="col-start-2">
        <H2 className="h1 mb-6 text-center">{t('heading.edit_card')}</H2>
        <QuestionModalsContent addCard={false} cardDetails={cardDetails} />
      </div>
      <div>
        <div className="flex justify-end">
          <LanguageSwitcher className="mr-20" />
        </div>
      </div>
    </div>
  );
}

export default EditQuestionCard;
