import { useContext, useEffect, useState } from "react";
import { TopicCardContext } from "../../../QuestionModalsContent";
import QuestionFormOptions from "@/components/Question/QuestionFormOptions";
import CorrectAnswers from "@/components/Question/CorrectAnswers";
import { ErrorContext } from "@/App";

function ChooseText() {
  const { questionCardDetails, setQuestionCardDetails } = useContext(TopicCardContext);
  const [localError,setLocalEror]=useState()
  const {errors,setErrors}=useContext(ErrorContext)
  useEffect(() => {
    if(errors.find(el=>el.field==="answer")){
      setLocalEror(errors.find(el=>el.field==="answer"))
    }
  }, [errors])
  
  useEffect(() => {
    if (!questionCardDetails?.options) {
      setQuestionCardDetails({
        ...questionCardDetails,
        options: [{
          value: null,
          is_correct: false,
        }],
      });
    }
  }, [questionCardDetails, setQuestionCardDetails]);

  const addOption = () => setQuestionCardDetails((prev) => ({ ...prev, options: [...prev.options, { value: null, is_correct: false }] }));
  const removeOption = (index) => setQuestionCardDetails((prev) => ({ ...prev, options: prev.options.filter((_, idx) => idx !== index )}));
  const updateOption = (index, value) =>
    setQuestionCardDetails((prev) => ({ ...prev, options: prev.options.map((option, idx) => idx === index ? { ...option, value } : option) }));

  const toggleCorrectAnswer = (index) => {
    if(localError){
      setLocalEror(undefined)
      setErrors(errors.filter((el)=>el.field!=="answer"))
    }
    setQuestionCardDetails((prev) =>
      ({ ...prev, options: prev.options.map((option, idx) => idx === index ? { ...option, is_correct: !option.is_correct } : option) })
    );
  };

  return (
    <div className="card-type-component mb-6">
      <QuestionFormOptions
        options={questionCardDetails?.options}
        onAdd={addOption}
        onChange={updateOption}
        onDelete={removeOption}
      />

      <CorrectAnswers
        options={questionCardDetails?.options}
        onChange={toggleCorrectAnswer}
        localError={localError}
      />
    </div>
  );
}

export default ChooseText;
