import i18n from "@/i18n";
import "./PersonalData.css";
import { useTranslation } from "react-i18next";

function PersonalData({ personalData, email }) {
  const { t } = useTranslation();
  return (
    <div className="user-details">
      <div className="details-item">
        <span className="label">{t("settings.personal_data.first_name")}</span>
        <span className="value">
          {personalData && personalData.firstName
            ? personalData.firstName
            : "-"}
        </span>
      </div>

      <div className="details-item">
        <span className="label">{t("settings.personal_data.last_name")}</span>
        <span className="value">
          {personalData && personalData.lastName ? personalData.lastName : "-"}
        </span>
      </div>

      <div className="details-item">
        <span className="label">{t("settings.personal_data.position")}</span>
        <span className="value">
          {personalData && personalData.position ? personalData.position : "-"}
        </span>
      </div>

      <div className="details-item">
        <span className="label">{t("settings.personal_data.email")}</span>
        <span className="value">{email ?? "-"}</span>
      </div>
      <div className="details-item">
        <span className="label">{t("settings.personal_data.language")}</span>
        <span className="value">
          {i18n.language === "en"
            ? t("settings.personal_data.en")
            : t("settings.personal_data.et")}
        </span>
      </div>
    </div>
  );
}

export default PersonalData;
