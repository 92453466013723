import "./TopicQuestionCards.css";
import { useContext, useState } from "react";
import AddQuestionCard from "./CardModals/AddQuestion/AddQuestionCard";
import { ModalContext } from "@/App";
import CardsList from "./CardsList/CardsList";
import { TopicContext } from "../Topic";
import { useTranslation } from "react-i18next";
import Button from "@/components/buttons/Button";
import { strReplace } from "@/utils/utils";
import ButtonMain from "@/components/buttons/ButtonMain";

function TopicQuestionCards() {
  const [activeCardType, setActiveCardType] = useState("all");
  const { setModal } = useContext(ModalContext);
  const { topic } = useContext(TopicContext);

  const { t } = useTranslation();

  const openAddCardView = (e) => {
    e.preventDefault();
    setModal(<AddQuestionCard topic={topic} />);
  };

  const classes = (type) => `filter-button ${activeCardType === type ? ' active' : ''}`;

  const FilterButton = ({ type }) => {
    return type && (
      <Button
        className={classes(type)}
        onClick={() => setActiveCardType(type)}
      >
        {t(`button.${strReplace(type, '-', '_')}`)}
      </Button>
    )
  };


  return (
    <>
      <header className="mb-6">
        {topic?.cards?.length > 0 && (
          <div className="question-filter">
            <Button
              className={classes("all")}
              onClick={() => setActiveCardType("all")}
            >
              {t('button.all_cards')}
            </Button>

            <FilterButton type="image-open-ended" />
            <FilterButton type="choose-image" />
            <FilterButton type="choose-text" />
            <FilterButton type="drag-drop" />
            <FilterButton type="fill-blanks" />
            <FilterButton type="drafts" />
          </div>
        )}

        <div className={!topic?.cards_count ? 'w-full' : ''}>
          <ButtonMain
            className={!topic?.cards_count ? 'w-full' : ''}
            onClick={openAddCardView}
          >
            + {t('button.add_card')}
          </ButtonMain>
        </div>
      </header>

      {topic?.cards?.length > 0 && (
        <CardsList activeCardType={activeCardType} />
      )}
    </>
  );
}

export default TopicQuestionCards;
