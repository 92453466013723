import { useContext, useState } from "react";
import { TopicCardContext } from "../../../QuestionModalsContent";
import "./Drag&Drop.css";
import H3 from "@/components/headings/H3";
import ButtonBlack from "@/components/buttons/ButtonBlack";
import Icon from "@/components/Icons/Icon";
import { useTranslation } from "react-i18next";
import TextArea from "@/components/Fields/TextArea";
import Button from "@/components/buttons/Button";
import BlankWordSelect from "./BlankWordSelect";
import ExtraWords from "./ExtraWords";
import { v4 as uuidv4 } from "uuid";

function DragDrop() {
  const {
    setShowManualField,
    questionCardDetails,
    setQuestionCardDetails,
    isEditingExtraAnswers,
    setIsEditingExtraAnswers,
  } = useContext(TopicCardContext);

  const [isEditingText, setIsEditingText] = useState(true);
  const [isEditingBlanks, setIsEditingBlanks] = useState(true);

  const { t } = useTranslation();

  const text = questionCardDetails?.answer || {};

  const editText = () => {
    setIsEditingText(true);
    setShowManualField(false);
    setIsEditingBlanks(false);
  };
  const editBlanks = () => {
    setIsEditingBlanks(true);
    setShowManualField(false);
    setIsEditingExtraAnswers(false);
  };

  const updateText = (answer) => {
    setQuestionCardDetails((prev) => ({ ...prev, answer }));
  };

  const confirmText = () => {
    if(!questionCardDetails?.answer)return;
    setIsEditingText(false);
    setIsEditingBlanks(true);

    Object.keys(questionCardDetails?.answer).forEach((key) => {
      if (!questionCardDetails?.answer[key]?.length) return;

      const options = questionCardDetails?.options?.[key] || [];
      const extras = options.filter((option) => option.is_extra);
      const words = questionCardDetails.answer[key].trim().split(/[\s\n]+/)

      const newOptions = words.map((word, index) => {
        const option = options[index] || {};
        return {
          id: option?.id || uuidv4(),
          value: (word || '').replace(/[^\w+À-ÖØ-öø-ÿ]+/g, ""),
          original: word,
          is_blank: option?.is_blank || false,
        };
      });

      if (extras.length) {
        newOptions.push(...extras);
      }

      setQuestionCardDetails((prev) => ({ ...prev, options: { ...prev.options, [key]: newOptions } }));
    });
  };

  const confirmBlanksSelection = () => {
    setIsEditingBlanks(false);
    setIsEditingExtraAnswers(true);
  };

  const handleAnswersConfirm = () => {
    setShowManualField(true);
    setIsEditingExtraAnswers(false);
  };

  const editAnswers = () => {
    setIsEditingExtraAnswers(true);
    setShowManualField(false);
  };

  return (
    <>
      {isEditingText
        ? (
          <H3 className="mb-4">{t('heading.type_text')}</H3>
        )
        : (
          <div className="flex items-center gap-6 mb-4">
            <H3>{t('heading.the_text')}</H3>
            <ButtonBlack className="w-7 h-7 p-2 text-xs" onClick={editText}>
              <Icon icon="pen" />
            </ButtonBlack>
          </div>
        )
      }

      <TextArea
        className="mb-8"
        disabled={!isEditingText}
        name="text"
        onChange={updateText}
        translatable
        value={text}
      />

      {isEditingText ? (
        <div className="flex">
          <Button className="mx-auto" onClick={confirmText}>
            {t('button.confirm')}
          </Button>
        </div>
      ) : null}

      {questionCardDetails?.answer && !isEditingText ? (
        <>
          {isEditingBlanks
            ? (
              <H3 className="mb-4">{t('heading.highlight_blanks')}</H3>
            )
            : (
              <div className="flex items-center gap-6 mb-4">
                <H3>{t('heading.choose_blanks')}</H3>
                <ButtonBlack className="w-7 h-7 p-2 text-xs" onClick={editBlanks}>
                  <Icon icon="pen" />
                </ButtonBlack>
              </div>
            )
          }

          <BlankWordSelect
            disabled={!isEditingBlanks}
            options={questionCardDetails?.options}
            onChange={(newOptions) => setQuestionCardDetails((prev) => ({ ...prev, options: newOptions }))}
            onConfirm={confirmBlanksSelection}
          />
        </>
      ) : null}

      {questionCardDetails?.answer && !isEditingText && !isEditingBlanks
        ? (
          <>
            {isEditingExtraAnswers
              ? (
                <H3 className="mt-8 mb-4">{t('heading.answers')}</H3>
              )
              : (
                <div className="flex items-center gap-6 mt-8 mb-4">
                  <H3>{t('heading.add_extra_answers')}</H3>
                  <ButtonBlack className="w-7 h-7 p-2 text-xs" onClick={editAnswers}>
                    <Icon icon="pen" />
                  </ButtonBlack>
                </div>
              )
            }

            <ExtraWords
              className="mb-6"
              disabled={!isEditingExtraAnswers}
              onChange={(newOptions) => setQuestionCardDetails((prev) => ({ ...prev, options: newOptions }))}
              onConfirm={handleAnswersConfirm}
              options={questionCardDetails?.options}
            />
          </>
        ) : null}
    </>
  );
}

export default DragDrop;
