import "./Settings.css";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "@/services/axios";
import { ErrorContext, UserContext } from "../../../App";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../../Loading/Loading";
import PersonalData from "./PersonalData/PersonalData";
import editIcon from "../AdminPanelAssets/Icons/editIcon.svg";
import H1 from "@/components/headings/H1";
import i18n from "@/i18n";
import { useTranslation } from "react-i18next";

function Settings() {
  const {
    setAuthToken,
    currentUser,
    setCurrentUser,
    personalData,
    setPersonalData,
  } = useContext(UserContext);
  const [localPersonalData, setLocalPersonalData] = useState(null);
  const [newEmail, setNewEmail] = useState(currentUser?.email);
  const [editPersonalData, setEditPersonalData] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { setErrors } = useContext(ErrorContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const showInfoToUser = (message) => toast.success(message);
  const {t}=useTranslation()
  const handleLogout = (e) => {
    e.preventDefault();

    localStorage.setItem("auth_token", null);
    setAuthToken(null);
    setCurrentUser(null);
    setPersonalData(null);
    navigate("/login");
  };

  useEffect(() => {
    if (!localPersonalData && personalData) {
      setLocalPersonalData(personalData);
    }
  }, [personalData, localPersonalData]);

  const getDataToChange = () => {
    return Object.entries(localPersonalData).map((entry) => {
      return {
        name: entry[0],
        entry:
          typeof entry[1] === "string" && entry[1].length ? entry[1] : null,
      };
    });
  };

  const confirmPersonalDataChanges = (e) => {
    e.preventDefault();

    setLoading(true);

    if (newEmail !== currentUser.email) {
      confirmEmailChange();
    }

    const dataToChange = getDataToChange();
    const formData = new FormData(e.target);
    const entries = [...formData.entries()];
    let language = "en";
    entries.forEach((entry) => {
      if (entry[0] === "language") {
        language = entry[1];
      }
      if (language !== i18n.language) {
        i18n.changeLanguage(language);
        localStorage.setItem("lng", language);
      }
    });
    if (
      !dataToChange.length ||
      JSON.stringify(personalData) === JSON.stringify(localPersonalData)
    ) {
      setLoading(false);
      setEditPersonalData(false);
      return;
    }

    axios
      .post(`/account/change-info`, dataToChange)
      .then((response) => {
        if (response.status === 200) {
          showInfoToUser("Your personal data was successfully changed!");
          setPersonalData(localPersonalData);
        }
      })
      .catch(({ response }) => {
        if (typeof response.data.errors !== "undefined") {
          let errs = [];

          for (const error in response.data.errors) {
            errs.push([...response.data.errors[error]]);
          }

          setErrors(errs);
          setLocalPersonalData(personalData);
        }
      })
      .finally(() => {
        setLoading(false);
        setEditPersonalData(false);
      });
  };

  const confirmEmailChange = () => {
    axios
      .post(`/account/change-email`, {
        email: newEmail,
      })
      .then((response) => {
        if (response.status === 200) {
          showInfoToUser("Your email was successfully changed!");
          setCurrentUser({ ...currentUser, email: newEmail });
        }
      })
      .catch(({ response }) => {
        if (typeof response.data.errors !== "undefined") {
          let errs = [];

          for (const error in response.data.errors) {
            errs.push([...response.data.errors[error]]);
          }

          setErrors(errs);
          setNewEmail(currentUser?.email);
        }
      });
  };

  const confirmPasswordChange = (e) => {
    e.preventDefault();

    setLoading(true);

    axios
      .post(`/account/change-password`, {
        password: currentPassword,
        new_password: newPassword,
        confirm_new_password: confirmPassword,
      })
      .then((response) => {
        if (response.status === 200) {
          showInfoToUser("The password was successfully changed!");
        }
      })
      .catch(({ response }) => {
        if (typeof response.data.errors !== "undefined") {
          let errs = [];

          for (const error in response.data.errors) {
            errs.push([...response.data.errors[error]]);
          }

          setErrors(errs);
        }
      })
      .finally(() => {
        setLoading(false);
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
      });
  };

  if (loading) return <Loading />;

  return (
    <div className="admin-panel-item">
      <header>
        <div className="section-name">
          <H1>{t("settings.title")}</H1>
          <div className="invisible h-[50px]"></div>
        </div>
      </header>

      <div className="settings-content">
        <div className={`${editPersonalData && "is-editing"} personal-data`}>
          <div className="personal-data-heading">
            <h2>{t("settings.personal_data.title")}</h2>

            {!editPersonalData && (
              <button
                type="button"
                className="edit-button"
                onClick={() => setEditPersonalData(true)}
              >
                <img src={editIcon} alt="" />
                <span>{t("button.edit")}</span>
              </button>
            )}
          </div>

          {editPersonalData ? (
            <form
              id="settings-form"
              method="post"
              onSubmit={confirmPersonalDataChanges}
            >
              <div className="input-forms">
                <input
                  type="text"
                  name="first-name"
                  placeholder={t("settings.personal_data.first_name")}
                  defaultValue={
                    localPersonalData && localPersonalData.firstName
                      ? localPersonalData.firstName
                      : ""
                  }
                  onChange={(e) =>
                    setLocalPersonalData({
                      ...localPersonalData,
                      firstName: e.target.value.length ? e.target.value : null,
                    })
                  }
                />

                <input
                  type="text"
                  name="last-name"
                  placeholder={t("settings.personal_data.last_name")}
                  defaultValue={
                    localPersonalData && localPersonalData.lastName
                      ? localPersonalData.lastName
                      : ""
                  }
                  onChange={(e) =>
                    setLocalPersonalData({
                      ...localPersonalData,
                      lastName: e.target.value.length ? e.target.value : null,
                    })
                  }
                />

                <input
                  type="text"
                  name="position"
                  placeholder={t("settings.personal_data.position")}
                  defaultValue={
                    localPersonalData && localPersonalData.position
                      ? localPersonalData.position
                      : ""
                  }
                  onChange={(e) =>
                    setLocalPersonalData({
                      ...localPersonalData,
                      position: e.target.value.length ? e.target.value : null,
                    })
                  }
                />

                <input
                  type="email"
                  name="email"
                  placeholder={t("settings.personal_data.email")}
                  defaultValue={
                    newEmail
                      ? newEmail
                      : currentUser?.email
                        ? currentUser?.email
                        : ""
                  }
                  onChange={(e) => setNewEmail(e.target.value)}
                />
                <select name="language" id="uiLanguage" className="w-full border rounded-md px-4 h-12 border-black">
                  <option selected={i18n.language==="en"} value="en">{t("settings.personal_data.en")}</option>
                  <option selected={i18n.language==="et"} value="et">{t("settings.personal_data.et")}</option>
                </select>
              </div>

              <button className="confirm-button settings-button">
              {t("button.confirm")}
              </button>
            </form>
          ) : (
            <PersonalData
              personalData={personalData}
              email={currentUser?.email}
            />
          )}

          {!editPersonalData && (
            <button className="settings-button" onClick={handleLogout}>
              {t("button.log_out")}
            </button>
          )}
        </div>

        <div className="change-password">
          <h2>{t("settings.change_password.title")}</h2>
          <form
            id="settings-form"
            method="post"
            onSubmit={confirmPasswordChange}
          >
            <div className="input-forms">
              <input
                type="password"
                name="current-password"
                placeholder={t("settings.change_password.current")}
                defaultValue={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />

              <input
                type="password"
                name="new-password"
                placeholder={t("settings.change_password.new")}
                defaultValue={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />

              <input
                type="password"
                name="confirm-password"
                placeholder={t("settings.change_password.repeat")}
                defaultValue={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>

            <button className="confirm-button settings-button">{t("button.confirm")}</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Settings;
