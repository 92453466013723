import "./Modal.css";
import closeIcon from "../Icons/closeIcon.svg";
import { useContext } from "react";
import { ErrorContext, ModalContext } from "../../../../App";
import EditTopic from "../../Topics/TopicsModals/EditTopic/EditTopic";
import AddTopic from "../../Topics/TopicsModals/AddTopic/AddTopic";

function Modal({ modal }) {
  const { setModal } = useContext(ModalContext);
  const {errors,setErrors}=useContext(ErrorContext)
  const getComponent = () => {
    if ("component" in modal) {
      return modal.component;
    }

    return modal;
  };

  const closeModal = () => {
    if(errors&&errors.length>0){
      setErrors([])
    }
    if ("onClose" in modal) {
      modal.onClose();
      return;
    }

    if (modal?.props?.parent === "add") {
      setModal(<AddTopic createdTopic={{ ...modal.props.topic }} />);
    } else if (modal?.props?.parent === "edit") {
      setModal(
        <EditTopic
          topic={modal.props.topic}
          updateTopic={modal.props.updateTopic}
        />,
      );
    } else {
      setModal(null);
    }
  };

  if (!modal) {
    return null;
  }

  return (
    <div className="modal-view">
      <div className="modal">
        <div className="modal-content">{getComponent()}</div>

        <div className="close-modal">
          <button type="submit" onClick={closeModal} className="close-button">
            <img src={closeIcon} alt="" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Modal;
