import { useTranslation } from "react-i18next";

import { getMediaLetter } from "../../../../CardAssets/QuestionOptions";

import Button from "@/components/buttons/Button";
import DraggablePill from "./DraggablePill";
import { toast } from "react-toastify";
import leftBlank from "./leftBlank.svg";
import rightBlank from "./rightBlank.svg";

import { v4 as uuidv4 } from "uuid";
import { sourceUrl } from "@/utils/utils";

export default function DraggablePills({
  disabled,
  image,
  options,
  onChange,
  onConfirm,
}) {
  const { t } = useTranslation();

  const pills = options?.filter((option) => !option.is_extra) || [];

  const add = (direction) => {
    const newOptions = [...(options || [])];
    if ((options || []).length + 1 > 26) {
      toast.info(t("notices.limit_reached"));
      return;
    }
    newOptions.push({
      id: uuidv4(),
      text: getMediaLetter(pills.length + 1),
      value: {},
      direction,
      top: 0,
      left: direction === "left" ? 0 : null,
      right: direction === "right" ? 0 : null,
    });
    onChange(newOptions);
  };
  const remove = (idToRemove) => {
    // Filter out the option with the given id

    let updatedOptions = (options || []).filter(
      (option) => option.id !== idToRemove,
    );

    // Recalculate the `id` and `text` values for the remaining options
    updatedOptions = updatedOptions.map((option, index) => ({
      ...option,
      id: uuidv4(), // Assign a new unique id
      text: getMediaLetter(index + 1), // Update the text based on its new position
    }));

    // Trigger the onChange with updated options
    onChange(updatedOptions);
  };
  const update = (index, newOption) => {

    const newOptions = [...options];
    newOptions[index] = newOption;
    onChange(newOptions);
  };

  return (
    <div className="image-blank-section">
      <div className="image-section mb-6">
        <div className="image-container">
          <img src={sourceUrl(image)} alt="" />

          {pills.map((option, index) => (
            <DraggablePill
              disabled={disabled}
              field={option}
              key={index}
              onChange={(newOption) => update(index, newOption)}
              onRemove={(id) => remove(id)}
            />
          ))}
        </div>

        {!disabled && (
          <div className="blank-container">
            <div className="blank-item">
              <div className="item-top">
                <button type="button" onClick={() => add("left")}>
                  <img src={leftBlank} alt="" />
                </button>
              </div>
              <div className="item-bottom">
                <span>{t("button.add_left_blank")}</span>
              </div>
            </div>
            <div className="blank-item">
              <div className="item-top">
                <button type="button" onClick={() => add("right")}>
                  <img src={rightBlank} alt="" />
                </button>
              </div>
              <div className="item-bottom">
                <span>{t("button.add_right_blank")}</span>
              </div>
            </div>
          </div>
        )}
      </div>

      {!disabled && (
        <div className="flex">
          <Button className="mx-auto" onClick={onConfirm}>
            {t("button.blanks_confirm")}
          </Button>
        </div>
      )}
    </div>
  );
}
