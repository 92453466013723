import { useState, useEffect, useRef } from "react";
import "./Dropdown.css";
import selectArrow from "../Icons/selectArrow.svg";
import { useTranslation } from "react-i18next";

function DropdownOption({ value, onClick, checkbox, selected, children }) {
  return (
    <button
      className="dropdown-option"
      onClick={() => onClick(value)}
      type="button"
    >
      {checkbox && <input type="checkbox" defaultChecked={selected} />}
      <span className="dropdown-option-label">{children}</span>
    </button>
  );
}

function Dropdown({
  options,
  selectedValues,
  onChange,
  allowMultipleSelections,
  defaultLabel,
  isTeamData,
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null); // Use ref to track the dropdown element

  const onSelect = (value) => {
    if (!allowMultipleSelections) {
      onChange([value]);
      setOpen(false);
      return;
    }

    if (selectedValues?.includes(value)) {
      onChange(selectedValues.filter((v) => v !== value));
    } else {
      onChange([...(selectedValues || []), value]);
    }
  };

  const selectAll = () => {
    if (options?.every((option) => selectedValues?.includes(option.value))) {
      onChange([]);
    } else {
      onChange(options.map((option) => option.value));
    }
  };

  const allSelected = () => {
    return options?.every((option) => selectedValues?.includes(option.value));
  };

  const dropdownLabel = () => {
    if (!allowMultipleSelections) {
      return options.find((option) => option.value === selectedValues[0])
        ?.label;
    }

    if (options?.every((option) => selectedValues?.includes(option.value))) {
      return "All selected";
    }

    return `${selectedValues.length} selected`;
  };

  // Close dropdown if click is outside of dropdown
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    // Add event listener to detect outside clicks
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up the event listener when the component is unmounted
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`dropdown ${isTeamData && "team-data-dropdown"}`}
      key={JSON.stringify(selectedValues)}
      ref={dropdownRef} // Assign the ref to the container div
    >
      <button
        onClick={() => setOpen(!open)}
        className={`dropdown-trigger ${!options?.length && "button-disabled"}`}
        type="button"
        disabled={!options?.length}
      >
        <span>{selectedValues?.length ? dropdownLabel() : defaultLabel}</span>
        <img
          src={selectArrow}
          className={`dropdown-arrow ${open && "open-arrow"}`}
          alt=""
        />
      </button>

      {open && options?.length && (
        <div className="dropdown-options">
          {allowMultipleSelections && (
            <DropdownOption
              onClick={selectAll}
              checkbox={true}
              selected={allSelected()}
            >
              {t('field.all')}
            </DropdownOption>
          )}
          {options.map((option, index) => (
            <DropdownOption
              key={index}
              onClick={onSelect}
              checkbox={allowMultipleSelections}
              selected={selectedValues?.includes(option.value)}
              isTeamData={isTeamData}
              {...option}
            >
              {option.label}
            </DropdownOption>
          ))}
        </div>
      )}
    </div>
  );
}

export default Dropdown;
