import { cn } from '@/utils/utils';
import './headings.css';

export default function H3 ({ children, className }) {
  const excluded = /h[1-5]/.test(className);
  const computedClassName = excluded ? className : cn('h3', className);
  return (
    <h3 className={computedClassName}>{children}</h3>
  )
}
