export const COLORS = [
  { value: "blue", label: "Blue", selected: false },
  { value: "pink", label: "Pink", selected: false },
  { value: "yellow", label: "Yellow", selected: false },
];

export const TYPES = [
  { value: "normal", label: "Normal", selected: false },
  { value: "premium", label: "Premium", selected: false },
];
