import { useContext, useState } from "react";
import axios from '@/services/axios';
import { ErrorContext, UserContext } from "../../../App";
import { useNavigate, Link } from "react-router-dom";
import Loading from "../../Loading/Loading";

function Login() {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [loading, setLoading] = useState(false);
  const { setAuthToken } = useContext(UserContext);
  const { setErrors } = useContext(ErrorContext);
  const navigate = useNavigate();

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post(`/authenticate`, {
        email,
        password,
      })
      .then((response) => {
        if (typeof response?.data.auth_token !== "undefined") {
          localStorage.setItem("auth_token", response.data.auth_token);
          setAuthToken(response.data.auth_token);

          navigate("/stats");
        }
      })
      .catch(({ response }) => {
        console.log('%cERROR: ', 'color: tomato; font-weight: bold;', response);
        // Input errors
        if (typeof response?.data?.errors !== "undefined") {
          let errs = [];

          for (const error in response.data.errors) {
            errs.push([...response.data.errors[error]]);
          }

          setErrors(errs);
        }

        if (typeof response?.data?.error !== "undefined") {
          // No access
          if (response?.data?.error === "no-access") {
            navigate("/403");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="login child-component">
      <h1 className="section-title">LOG IN</h1>

      <div className="input-section">
        <form method="post" onSubmit={onSubmit}>
          <div className="input-forms">
            <input
              autoComplete="email"
              type="email"
              name="email"
              defaultValue={email ? email : ""}
              placeholder="Email Address"
              onInput={(e) => setEmail(e.target.value)}
            />

            <input
              autoComplete="current-password"
              type="password"
              name="password"
              placeholder="Password"
              onInput={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className="submit-button">
            <button type="submit" className="main-button">
              LOG IN
            </button>
          </div>
        </form>
      </div>

      <div className="link-section">
        <Link to="/reset-password">Forgot your password?</Link>
      </div>
    </div>
  );
}

export default Login;
