import { useContext } from "react";
import { getIcon } from "../../TopicsAssets/Utils/topicUtils";
import { TopicContext } from "../Topic";
import { useNavigate } from "react-router-dom";
import SubTopicHeader from "./SubTopicHeader";
import useTranslatableContent from "@hooks/useTranslatableContent";
import { useTranslation } from "react-i18next";

function SubTopics () {
  const { topic } = useContext(TopicContext);
  const { t } = useTranslation();

  const navigate = useNavigate();
  const maybeTranslate = useTranslatableContent();

  const openTopicCard = (topicId) => {
    navigate(`/topics/${topicId}`);
  };

  if (!topic?.subtopics || !topic?.subtopics?.length) {
    return (
      <>
        <SubTopicHeader />
        <div className="no-topics">
          <span>There are no subtopics yet.</span>
        </div>
      </>
    )
  }

  return (
    <>
      <SubTopicHeader />
      <div className="topic-grid">
        {topic?.subtopics?.map((subtopic) => {
          return (
            <div
              key={subtopic.id}
              className="topic-card"
              onClick={() => openTopicCard(subtopic.id)}
            >
              <div className="topic-icon">
                <img src={getIcon(subtopic?.icon)} alt="" />
              </div>
              <div className="card-details">
                <span className="card-title">{maybeTranslate(subtopic.name)}</span>
                <span className="number-of-topic-cards">
                  {t("count.card", { count: subtopic?.cards_count || 0 })}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default SubTopics;
