export function cn (...args) {
  return args.filter(Boolean).join(' ');
}

export function strReplace (str, search, replace) {
  try {
    return str.replace(new RegExp(search, 'g'), replace);
  } catch (e) {
    console.error(e);
    return str;
  }
}

export function sourceUrl (path) {
  if (path.startsWith('blob:')) return path;
  if (path.startsWith('http')) return path;
  return `${window.STORAGE_URL}/${path}`
}
