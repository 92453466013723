import "./CardDetails.css";
import { getMediaLetter } from "../../CardAssets/QuestionOptions";
import DraggablePill from "../../CardModals/ModalComponents/QuestionCardTypes/FillInBlanksImage/DraggablePill";
import { useTranslation } from "react-i18next";
import useTranslatableContent from "@/hooks/useTranslatableContent";
import { sourceUrl, strReplace } from "@/utils/utils";
import i18n from "@/i18n";
import { useEffect, useState } from "react";
function ImageOpenEndedDetails({ card }) {
  const { t } = useTranslation();
  const maybeTranslate = useTranslatableContent();
  return (
    <>
      <div className="data-item">
        <span className="row-label">{t("list.media")}:</span>
        <img src={sourceUrl(card.media?.[0])} alt="" />
      </div>

      <div className="data-item">
        <span className="row-label">{t("list.number_of_answers")}:</span>
        <span>{card?.options?.length}</span>
      </div>

      <div className="data-item">
        <span className="row-label">{t("list.correct_answers")}:</span>

        <div className="card-answers">
          {card?.options?.map((option, index) => {
            return (
              <div key={index} className="card-answer">
                <span>{index + 1}.</span>
                <span>{maybeTranslate(option.value) || "-"}</span>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

function ChooseImageDetails({ card }) {
  const { t } = useTranslation();
  const [correctAnswers, setCorrectAnswers] = useState([]);
  useEffect(() => {
    if (!card) return;
    let answerArray = [];
    card.options.forEach((answer, index) => {
      if (answer.is_correct) {
        answerArray.push({ ...answer, index });
      }
    });
    setCorrectAnswers(answerArray);
  }, [card]);

  return (
    <>
      <div className="data-item choose-image">
        <span className="row-label">{t("list.media_answer_boxes")}:</span>
        <div className="card-images">
          {card?.options?.map((option, index) => (
            <div className="card-image" key={index}>
              <img src={sourceUrl(option?.url || option?.value)} alt="" />
              <span>{getMediaLetter(index + 1).toLowerCase()}</span>
            </div>
          ))}
        </div>
      </div>

      <div className="data-item">
        <span className="row-label">
          {t("list.number_of_correct_answers")}:
        </span>
        {correctAnswers && <span>{correctAnswers.length}</span>}
      </div>

      <div className="data-item">
        <span className="row-label">{t("list.correct_answers")}:</span>

        <div className="card-answers">
          {correctAnswers?.map((ans, index) => (
            <div key={index}>
              <span>{getMediaLetter(ans.index + 1).toLowerCase()}.</span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

function ChooseTextDetails({ card }) {
  const { t } = useTranslation();
  const maybeTranslate = useTranslatableContent();
  return (
    <>
      <div className="data-item">
        <span className="row-label">
          {t("list.number_of_correct_answers")}:
        </span>
        <span>{card?.correct_answer_count}</span>
      </div>

      <div className="data-item">
        <span className="row-label">{t("list.correct_answers")}:</span>
        <div className="card-answers">
          {card?.options?.map((option, index) => {
            if (!option.is_correct) {
              return null;
            }
            return (
              <div key={index} className="card-answer">
                <span>{getMediaLetter(index + 1).toLowerCase()}.</span>
                <span>{maybeTranslate(option.value) || "-"}</span>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

function DragDropDetails({ card }) {
  const { i18n, t } = useTranslation();
  const [correctAnswers, setCorrectAnswers] = useState([]);
  useEffect(() => {
    if (!card) return;
    let answerArray = [];
    card.options[i18n.language].forEach((answer, index) => {
      if (answer.is_blank) {
        answerArray.push({ ...answer, index });
      }
    });
    setCorrectAnswers(answerArray);
  }, [card, i18n.language]);
  return (
    <>
      <div className="data-item">
        <span className="row-label">
          {t("list.number_of_propsal_answers")}:
        </span>
        <span>
          {
            card.options[i18n.language].filter(
              (el) => el.is_blank || el.is_extra,
            ).length
          }
        </span>
      </div>

      <div className="data-item">
        <span className="row-label">{t("list.proposal_answers")}:</span>
        <div className="card-answers">
          {card.options[i18n.language]
            .filter((el) => el.is_blank || el.is_extra)
            .map((option, index) => {
              return (
                <div key={index} className="card-answer">
                  <span>{index + 1}.</span>
                  <span>{option.value}</span>
                </div>
              );
            })}
        </div>
      </div>

      <div className="data-item">
        <span className="row-label">{t("list.correct_answers")}:</span>
        <div className="card-answers">
          {correctAnswers.map((option, index) => {
            return (
              <div key={index} className="card-answer">
                <span>{index + 1}.</span>
                <span>{option.value}</span>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

function FillInBlanksImageDetails({ card }) {
  const { t } = useTranslation();
  const maybeTranslate = useTranslatableContent();

  const pills = card?.options?.filter((option) => !option?.is_extra);
  const [correctAnswers, setCorrectAnswers] = useState([]);
  useEffect(() => {
    if (!card) return;
    let answerArray = [];
    card.options.forEach((answer, index) => {
      if (!answer.is_extra) {
        answerArray.push({ ...answer, index });
      }
    });
    setCorrectAnswers(answerArray);
  }, [card]);
  return (
    <>
      <div className="data-item fill-blanks">
        <span className="row-label">{t("list.media")}:</span>
        <div className="image-section">
          <div className="image-container">
            <img src={sourceUrl(card.media?.[0])} alt="" />
            {pills.map((option, index) => (
              <DraggablePill disabled={true} key={index} field={option} />
            ))}
          </div>
        </div>
      </div>

      <div className="data-item">
        <span className="row-label">{t("list.number_of_answers")}:</span>
        <span>{card?.options?.length}</span>
      </div>

      <div className="data-item">
        <span className="row-label">{t("list.correct_answers")}:</span>

        <div className="card-answers">
          {correctAnswers.map((option, index) => {
            return (
              <div key={index} className="card-answer">
                <span>{option.text}.</span>
                <span>{maybeTranslate(option.value) || "-"}</span>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

function CardDetails({ card }) {
  const { t } = useTranslation();
  const maybeTranslate = useTranslatableContent();

  const getCardDetails = () => {
    switch (card?.type) {
      case "image-open-ended":
        return <ImageOpenEndedDetails card={card} />;
      case "choose-image":
        return <ChooseImageDetails card={card} />;
      case "drag-drop":
        return <DragDropDetails card={card} />;
      case "fill-blanks":
        return <FillInBlanksImageDetails card={card} />;
      default:
        return <ChooseTextDetails card={card} />;
    }
  };

  return (
    <div className="details-component">
      <div className="data-item">
        <span className="row-label">{t("list.topic")}:</span>
        <span className="uppercase">{maybeTranslate(card?.topic_name)}</span>
      </div>

      <div className="data-item">
        <span className="row-label">{t("list.type")}:</span>
        <span>
          {t(`type.${strReplace(card?.type || "unknown", "-", "_")}`)}
        </span>
      </div>

      <div className="data-item">
        <span className="row-label">{t("list.question")}:</span>
        <span>{maybeTranslate(card?.question) || "-"}</span>
      </div>

      {getCardDetails()}

      <div className="data-item">
        <span className="row-label">{t("list.manual_for_card")}:</span>
        <div></div>
        <span style={{whiteSpace: "pre-line"}}>
          {card?.manual[i18n.language]?.length ? (
            <>
              {maybeTranslate(card.manual[i18n.language])
                }
            </>
          ) : (
            "-"
          )}
        </span>
      </div>
    </div>
  );
}

export default CardDetails;
