import { Link } from "react-router-dom";
import "./Guard.css";
import logo from "./logo_multi.svg";

function Guard ({ children }) {
  return (
    <div className="guard">
      <Link className="logo-section" to="/">
        <img className="logo" src={logo} alt="Learn Med Pro" />
      </Link>

      <div className="guard-content">
        {children}
      </div>
    </div>
  )
}
export default Guard;
