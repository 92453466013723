import { sourceUrl } from "@/utils/utils";
import DraggablePill from "../../CardModals/ModalComponents/QuestionCardTypes/FillInBlanksImage/DraggablePill";
import useTranslatableContent from "@/hooks/useTranslatableContent";

function FillInBlanksImagePreview ({ card }) {
  const maybeTranslate = useTranslatableContent();

  const pills = card?.options?.filter((option) => !option?.is_extra);

  return (
    <div className="preview-option">
      <div className="fill-blanks-container">
        <div className="image-section">
          <div className="image-container">
            <img src={sourceUrl(card?.media?.[0])} alt="" />

            {pills.map((option, index) => (
              <DraggablePill disabled={true} key={index} field={option} />
            ))}
          </div>
        </div>

        <div className="preview-blanks">
          {card?.options?.map((option, index) => {
            return (
              <div key={index} className="preview-blank">
                {maybeTranslate(option.value) || "-"}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default FillInBlanksImagePreview;
