import i18n from '@/i18n';

export const getTypes = () => [
  { label: i18n.t('type.image_open_ended'), value: "image-open-ended" },
  { label: i18n.t('type.choose_image'), value: "choose-image" },
  { label: i18n.t('type.choose_text'), value: "choose-text" },
  { label: i18n.t('type.drag_drop'), value: "drag-drop" },
  { label: i18n.t('type.fill_blanks'), value: "fill-blanks" },
];

export const getMediaLetter = (num) => {
  if (num < 1 || num > 26 || typeof num !== "number") {
    return -1;
  }

  const leveller = 64;
  return String.fromCharCode(num + leveller);
};
