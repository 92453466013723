import { useTranslation } from "react-i18next";
import H2 from "@/components/headings/H2";
import "./card.css";
import { useEffect, useState } from "react";
import i18n from "@/i18n";
import { getMediaLetter } from "@/components/AdminPanel/Topics/Topic/Cards/CardAssets/QuestionOptions";
import { sourceUrl } from "@/utils/utils";

export default function PreviewAnswer({ card, isOpen }) {
  const { t } = useTranslation();
  const [answers, setAnswers] = useState([]);

  // console.log(card.options)
  useEffect(() => {
    function handleImageOpenEndedAnswers() {
      if (!card) return;
      let answerArray = [];
      card.options.forEach((option) => {
        answerArray.push(option.value[i18n.language]);
      });
      setAnswers(answerArray);
    }
    function handeChooseImageAnswers() {
      if (!card) return;
      let answerArray = [];
      card.options.forEach((answer, index) => {
        if (answer.is_correct) {
          answerArray.push({ ...answer, index });
        }
      });
      setAnswers(answerArray);
    }
    function handeChooseTextAnswers() {
      if (!card) return;
      let answerArray = [];
      card.options.forEach((answer, index) => {
        if (answer.is_correct) {
          answerArray.push({ ...answer, index });
        }
      });
      setAnswers(answerArray);
    }
    function handeDragDropAnswers() {
      if (!card) return;
      let answerArray = [];
      card.options[i18n.language].forEach((answer, index) => {
        if (!answer.is_extra) {
          answerArray.push({ ...answer, index });
        }
      });
      setAnswers(answerArray);
    }
    function handleFillBlankAnswers() {
      if (!card) return;
      let answerArray = [];
      card.options.forEach((answer, index) => {
        if (!answer.is_extra) {
          answerArray.push({ ...answer, index });
        }
      });
      setAnswers(answerArray);
    }
    if (!card) return;
    switch (card.type) {
      case "image-open-ended":
        handleImageOpenEndedAnswers();
        break;
      case "choose-image":
        handeChooseImageAnswers();
        break;
      case "choose-text":
        handeChooseTextAnswers();
        break;
      case "drag-drop":
        handeDragDropAnswers();
        break;
      case "fill-blanks":
        handleFillBlankAnswers();
        break;
      default:
        break;
    }
  }, [card]);

  return (
    <div className="preview__modal preview__modal--answer">
      <H2 className="mb-8 text-center">{t("heading.correct_answer_is")}</H2>
      <div className="preview__explanation-wrapper">
        {card && answers && (
          <div className="preview__explanation">
            {card.type === "image-open-ended" && (
              <>
                {answers.map((answer, index) => {
                  return (
                    <div
                      className="flex flex-row items-start gap-2"
                      key={"AnswerPreviewIOE" + index}
                    >
                      <p>{index + 1}.</p>
                      <div className="w-full">
                        <p className="">{answer}</p>
                        <div className="block w-full h-px bg-black"></div>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
            {card.type === "choose-image" && (
              <div className="flex flex-row flex-wrap">
                {answers.map((answer, index) => {
                  return (
                    <div
                      className="flex flex-col items-center gap-2 w-1/2"
                      key={"AnswerPreviewCI" + index}
                    >
                      <img
                        className=" h-auto rounded-[13px] w-1/2 border border-black"
                        src={sourceUrl(answer?.url || answer.value)}
                        alt=""
                      />
                      <p className="">
                        {getMediaLetter(answer.index + 1).toLowerCase()}
                      </p>
                    </div>
                  );
                })}
              </div>
            )}
            {card.type === "choose-text" && (
              <div className="flex flex-col gap-2">
                {answers.map((answer, index) => {
                  return (
                    <div
                      className="flex flex-row items-center  w-full bg-[#D7DF23] border border-black  min-h-[44px] rounded-lg gap-[5px] px-4"
                      key={"AnswerPreviewCI" + index}
                    >
                      <p className="">
                        {getMediaLetter(answer.index + 1).toLowerCase()}.
                      </p>
                      <p className="">{answer.value[i18n.language]}</p>
                    </div>
                  );
                })}
              </div>
            )}
            {card.type === "drag-drop" && (
              <div className="">
                <div className="">
                  {answers.map((answer, index) => {
                    return (
                      <>
                      <span className={`${answer.is_blank?"bg-black text-white  rounded-md p-1 overflow-hidden inline-flex":"text-black underline"}`}>{answer.original}</span>
                      <span> </span>
                      </>
                    );
                  })}
                </div>
              </div>
            )}
            {card.type === "fill-blanks" && (
              <div className="flex flex-col gap-2">
                {answers.map((answer, index) => {
                  return (
                    <div
                      className="flex flex-row items-center  w-full bg-[#1E1E1E] border border-black  min-h-[44px] rounded-lg gap-[5px] px-4"
                      key={"AnswerPreviewCI" + index}
                    >
                      <p className="text-white">{answer.text}.</p>
                      <p className="text-white">
                        {answer.value[i18n.language]}
                      </p>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
