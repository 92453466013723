import { useEffect, useRef } from 'react';

import { cn } from '@/utils/utils';

import Button from '../buttons/Button';
import H2 from '@/components/headings/H2';
import Icon from '@/components/Icons/Icon';

import './dialogs.css';

const isClickInsideRectangle = (e, element) => {
  const r = element.getBoundingClientRect();

  return (
    e.clientX > r.left &&
    e.clientX < r.right &&
    e.clientY > r.top &&
    e.clientY < r.bottom
  );
};

export default function BaseDialog ({ className, children, isOpened, onClose, size, title }) {
  const ref = useRef(null);

  useEffect(() => {
    if (isOpened) {
      ref.current?.showModal();
      document.body.classList.add('overflow-hidden');
    } else {
      ref.current?.close();
      document.body.classList.remove('overflow-hidden');
    }
  }, [isOpened]);

  return (
    <dialog
      ref={ref}
      className="dialog__wrapper"
      onCancel={onClose}
      onClick={(e) => ref.current && !isClickInsideRectangle(e, ref.current) && onClose()}
    >
      <div className={cn(
        'dialog',
        size === 'sm' ? 'dialog--small' : null,
        className
      )}>
        <div className={cn('dialog__content')}>
          {title ? <H2 className="h1 mb-6">{title}</H2> : null}

          {children}
        </div>
        <Button className="dialog__close" onClick={onClose}>
            <Icon icon="close" />
        </Button>
      </div>
    </dialog>
  );
}
