import React, { useState } from "react";
import screenIcon from "./PhoneIcons/screenIcon.svg";
import ImageMultipleOpenEndedPreview from "./ContentOptions/Image&MultipleOpenEndedPreview";
import ChooseImagePreview from "./ContentOptions/ChooseImagePreview";
import DragDropPreview from "./ContentOptions/DragDropPreview";
import FillInBlanksImagePreview from "./ContentOptions/FillInBlanksImagePreview";
import ChooseTextPreview from "./ContentOptions/ChooseTextPreview";
import "./CardPhoneView.css";
import useTranslatableContent from "@/hooks/useTranslatableContent";
import PreviewFooter from "@/features/card/PreviewFooter";
import PreviewAnswer from "@/features/card/PreviewAnswer";
import PreviewManual from "@/features/card/PreviewManual";

function CardPhoneView({ card }) {
  const maybeTranslate = useTranslatableContent();
  const [isFlipped, setIsFlipped] = useState(false);
  const [isManualOpened, setIsManualOpened] = useState(false);

  const getCardFields = () => {
    switch (card?.type) {
      case "image-open-ended":
        return <ImageMultipleOpenEndedPreview card={card} />;
      case "choose-image":
        return <ChooseImagePreview card={card} />;
      case "drag-drop":
        return <DragDropPreview card={card} />;
      case "fill-blanks":
        return <FillInBlanksImagePreview card={card} />;
      default:
        return <ChooseTextPreview card={card} />;
    }
  };

  // Manually concatenate classes
  const flipContainerClass = `card-flip-container ${isFlipped ? 'flipped' : ''}`;

  return (
    <div className="phone-view">
      <img src={screenIcon} alt="" />
      <div className="phone-content">
        <header>
          <span className="uppercase">{maybeTranslate(card?.topic_name)}</span>
        </header>

        <div className={flipContainerClass}>
          <div className="card-flip">
            <div className="card-front card-details relative">
              <span className="card-type">{maybeTranslate(card?.question)}</span>
              {getCardFields()}
              {isManualOpened && <PreviewManual card={card} isOpen={isManualOpened} />}
            </div>

            <div className="card-back">
              <PreviewAnswer card={card} isOpen={isFlipped} />
            </div>

          </div>
          <PreviewFooter
              setIsFlipped={setIsFlipped}
              isFlipped={isFlipped}
              setIsManualOpened={setIsManualOpened}
              isManualOpened={isManualOpened}
            />
        </div>


      </div>
    </div>
  );
}

export default CardPhoneView;
