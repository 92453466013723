import { useContext } from "react";
import { ModalContext, ParentIdContext, UserContext } from "@/App";
import { TopicContext } from "../Topic";
import { useTranslation } from "react-i18next";
import AddTopic from "../../TopicsModals/AddTopic/AddTopic";
import H2 from "@/components/headings/H2";

function SubTopicHeader () {
  const { setModal } = useContext(ModalContext);
  const { topic } = useContext(TopicContext);
  const { currentUser } = useContext(UserContext);
  const { setParentId } = useContext(ParentIdContext);

  const { t } = useTranslation();

  const openAddTopicView = (e) => {
    e.preventDefault();
    setParentId(topic.id);
    setModal(<AddTopic />);
  };

  return (
    <header className="flex justify-between">
      <div className="section-name">
        <H2>
          {t('heading.subtopics')} <span className="font-normal">({topic?.subtopics?.length || 0})</span>
        </H2>
      </div>

      {(currentUser?.userRole === "super-admin" ||
        currentUser?.topicAdding) && (
          <div className="add-topic-button">
            <button
              type="button"
              className="uppercase add-button"
              onClick={openAddTopicView}
            >
              + {t('button.add_subtopic')}
            </button>
          </div>
        )}
    </header>
  )
}

export default SubTopicHeader;
