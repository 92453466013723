import useTranslatableContent from "@/hooks/useTranslatableContent";
import "./PreviewContent.css";
import { sourceUrl } from "@/utils/utils";

function ImageMultipleOpenEndedPreview ({ card }) {
  const maybeTranslate = useTranslatableContent();
  return (
    <div className="preview-option">
      <div className="preview-image-section">
        <img src={sourceUrl(card?.media?.[0])} alt="" />
      </div>

      <div className="preview-answers-section">
        {card?.options?.map((option, index) => {
          return (
            <div className="answer" key={index}>
              <span className="answer-number">{index + 1 + "."}</span>
              <span className="answer-value">
                {maybeTranslate(option.value) || "-"}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ImageMultipleOpenEndedPreview;
