import AdminPanel from "../components/AdminPanel/AdminPanel";
import Card from "../components/AdminPanel/Topics/Topic/Cards/Card/Card";
import HOC from "../components/HOC/HOC";

const route = {
  path: "/cards/:id",
  element: (
    <HOC>
      <AdminPanel>
        <Card />
      </AdminPanel>
    </HOC>
  ),
};

export default route;
