import Button from '@/components/buttons/Button';
import Icon from '@/components/Icons/Icon';
import { cn } from '@/utils/utils';

export default function PreviewFooter({
  setIsFlipped,
  isFlipped,
  setIsManualOpened,
  isManualOpened,
}) {
  const openAnswers = () => {
    setIsFlipped(prev => !prev);
    setIsManualOpened(false); // Close manual view if flipping
  };

  const openManual = () => {
    setIsFlipped(false); // Ensure the card is not flipped when showing manual
    setIsManualOpened(prev => !prev);

  };

  return (
    <footer className="z-10">
      <Button
        className={cn('footer-icon', isFlipped ? 'active' : '')}
        onClick={openAnswers}
      >
        <Icon icon="flip" />
      </Button>

      <Button
        className={cn('footer-icon', isManualOpened ? 'active' : '')}
        onClick={openManual}
      >
        <Icon icon="book" />
      </Button>
    </footer>
  );
}
