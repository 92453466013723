import "./TeamMember.css";
import { useCallback, useContext, useEffect, useState } from "react";
import PersonalData from "./PersonalData/PersonalData";
import { useNavigate, useParams } from "react-router-dom";
import Dropdown from "../../AdminPanelAssets/Dropdown/Dropdown";
import { ROLES } from "../../AdminPanelAssets/Constants/Constants";
import axios from '@/services/axios';
import { ErrorContext, ModalContext } from "@/App";
import TopicsAccess from "./TopicsAccess/TopicsAccess";
import { toast } from "react-toastify";
import deleteIcon from "../../AdminPanelAssets/Icons/deleteIcon.svg";
import editIcon from "../../AdminPanelAssets/Icons/editIcon.svg";
import EditMember from "../TeamModals/EditMember/EditMember";
import Header from "@/components/Header/Header";
import HeaderActions from "@/components/Header/HeaderActions";
import H1 from "@/components/headings/H1";
import ButtonBack from "@/components/buttons/ButtonBack";
import Panel from "@/components/Panel/Panel";
import Container from "@/components/Container/Container";
import { cn } from "@/utils/utils";
import H2 from "@/components/headings/H2";
import { useTranslation } from "react-i18next";
import Button from "@/components/buttons/Button";

function TeamMember () {
  const { id } = useParams();
  const [memberData, setMemberData] = useState(null);
  const [editPersonalData, setEditPersonalData] = useState(false);
  const [changedTopics, setChangedTopics] = useState(false);
  const { setErrors } = useContext(ErrorContext);
  const { setModal } = useContext(ModalContext);
  const navigate = useNavigate();

  const { t } = useTranslation();

  const name = `${memberData?.first_name || ''} ${memberData?.last_name || ''}`.trim() || t('heading.team_member');
  const topics = (memberData?.topics || []).map((topic) => topic?.id || topic);

  const backToTeam = () => {
    navigate("/team");
  };

  const showInfoToUser = (message) => toast.success(message);

  const handleTopicSelection = (selectedValues) => {
    setMemberData((prev) => ({ ...prev, topics: selectedValues }));
    setChangedTopics(true);
  };

  const handleRoleChange = (selectedValues) => {
    setMemberData({
      ...memberData,
      role: selectedValues[0],
      can_add_topic: selectedValues[0] === "super-admin",
    });
  };

  const getMember = useCallback(() => {
    axios
      .get(`/users/${id}`, {
      })
      .then((response) => {
        setMemberData(response?.data);
      })
      .catch(({ response }) => {
        if (typeof response.data.errors !== "undefined") {
          let errs = [];

          for (const error in response.data.errors) {
            errs.push([...response.data.errors[error]]);
          }

          setErrors(errs);
        }

        if (response?.status === 401) {
          navigate("/login");
        }
      });
  }, [id, setErrors, navigate]);

  const deleteUser = () => {
    axios
      .delete(`/team/member/${id}`)
      .then((response) => {
        if (response) {
          showInfoToUser("The user has been deleted!");
        }
      })
      .catch(({ response }) => {
        if (typeof response.data.errors !== "undefined") {
          let errs = [];

          for (const error in response.data.errors) {
            errs.push([...response.data.errors[error]]);
          }

          setErrors(errs);
        }

        if (response?.status === 401) {
          navigate("/login");
        }
      })
      .finally(() => {
        backToTeam();
      });
  };

  const submitMemberChanges = useCallback(
    (e) => {
      if (e) e.preventDefault();

      const payload = { ...memberData, topics };

      axios
        .put(`/users/${id}`, payload)
        .then(() => {
          showInfoToUser("The user data was successfully changed!");
          setEditPersonalData(false);
        })
        .catch(({ response }) => {
          if (typeof response.data.errors !== "undefined") {
            let errs = [];

            for (const error in response.data.errors) {
              errs.push([...response.data.errors[error]]);
            }

            setErrors(errs);
          }

          if (response?.status === 401) {
            navigate("/login");
          } else {
            setErrors(["Something went wrong. Please, try again."]);
          }
        })
        .finally(() => {
          setChangedTopics(false);
        });
    },
    [
      id,
      memberData,
      topics,
      setEditPersonalData,
      setErrors,
      navigate,
    ],
  );

  const onMemberUpdate = (updatedMember) => setMemberData(updatedMember);

  const updateCanAddTopic = (canAddTopic) => {
    console.log('%cCan add topic:', 'color: orange;', canAddTopic);
    setMemberData((prev) => ({ ...prev, can_add_topic: canAddTopic }));
    setChangedTopics(true);
  }

  useEffect(() => {
    if (!memberData?.id) {
      getMember();
    }
  }, [memberData?.id, getMember]);

  useEffect(() => {
    if (changedTopics) {
      submitMemberChanges();
    }
  }, [changedTopics, submitMemberChanges]);

  return (
    <Container>
      <Header>
        <ButtonBack onClick={backToTeam} />
        <H1>{name}</H1>
        <HeaderActions />
      </Header>

      <div className="grid grid-cols-3 gap-4 h-full">
        <Panel className={cn('col-span-2', editPersonalData ? 'is-editing' : null)}>
          <div className="personal-data-heading">
            <H2 className="mb-6">{t('heading.personal_data')}</H2>

            <Button
              onClick={() =>
                setModal(
                  <EditMember
                    currentMember={memberData}
                    updateMember={onMemberUpdate}
                  />,
                )
              }
            >
              <img src={editIcon} alt="" />
              {t('button.edit')}
            </Button>
          </div>

          {editPersonalData ? (
            <form
              id="members-form"
              method="post"
              onSubmit={submitMemberChanges}
            >
              <div className="form-user-data">
                <div className="input-forms">
                  <input
                    type="text"
                    name="first-name"
                    placeholder="First name"
                    defaultValue={memberData?.first_name ?? ""}
                    onChange={(e) =>
                      setMemberData({
                        ...memberData,
                        first_name: e.target.value.length
                          ? e.target.value
                          : null,
                      })
                    }
                  />

                  <input
                    type="text"
                    name="last-name"
                    placeholder="Last name"
                    defaultValue={memberData?.last_name ?? ""}
                    onChange={(e) =>
                      setMemberData({
                        ...memberData,
                        last_name: e.target.value.length
                          ? e.target.value
                          : null,
                      })
                    }
                  />

                  <Dropdown
                    options={ROLES}
                    selectedValues={memberData?.role ? [memberData.role] : []}
                    onChange={handleRoleChange}
                    allowMultipleSelections={false}
                    isTeamData={true}
                  />

                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    defaultValue={memberData?.email ?? ""}
                    onChange={(e) =>
                      setMemberData({
                        ...memberData,
                        email: e.target.value.length ? e.target.value : null,
                      })
                    }
                  />
                </div>

                <div className="topic-adding-section">
                  <span>Topic Adding</span>

                  <div className="switch-container">
                    <label
                      className={`switch ${memberData.role === "super-admin" && "switch-disabled"
                        }`}
                    >
                      <input
                        key={Math.random()}
                        type="checkbox"
                        onClick={(e) =>
                          setMemberData({
                            ...memberData,
                            can_add_topic: e.target.checked,
                          })
                        }
                        defaultChecked={memberData?.can_add_topic}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>

              <button className="confirm-button user-button">CONFIRM</button>
            </form>
          ) : (
            <div className="user-data h-full">
              <PersonalData
                memberData={memberData}
                  roles={ROLES}
                  setCanAddTopic={updateCanAddTopic}
              />

              <div className="mt-auto delete-user">
                <Button onClick={deleteUser}>
                  <img src={deleteIcon} alt="" />
                  {t('button.delete_user')}
                </Button>
              </div>
            </div>
          )}
        </Panel>

        <Panel>
          <H2 className="mb-6">{t('heading.topic_access')} <span>{`(${memberData?.topics?.length})`}</span></H2>
          <TopicsAccess
            selected={topics}
            onChange={handleTopicSelection}
          />
        </Panel>
      </div>
    </Container>
  );
}

export default TeamMember;
