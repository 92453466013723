import "./Topic.css";
import { useNavigate, useParams } from "react-router-dom";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { ErrorContext, ModalContext } from "@/App";
import Loading from "../../../Loading/Loading";
import TopicInfo from "./TopicInfo/TopicInfo";
import TopicQuestionCards from "./Cards/TopicQuestionCards";
import deleteIcon from "../../AdminPanelAssets/Icons/deleteIcon.svg";
import editIcon from "../../AdminPanelAssets/Icons/editIcon.svg";
import EditTopic from "../TopicsModals/EditTopic/EditTopic";
import axios from '@/services/axios';
import { getIcon } from "../TopicsAssets/Utils/topicUtils";
import { toast } from "react-toastify";
import SubTopics from "./SubTopics/SubTopics";
import useTranslatableContent from "@hooks/useTranslatableContent";
import { useTranslation } from "react-i18next";
import Header from "@components/Header/Header";
import HeaderActions from "@/components/Header/HeaderActions";
import H1 from "@/components/headings/H1";
import ButtonBack from "@/components/buttons/ButtonBack";
import Button from "@/components/buttons/Button";
import Container from "@/components/Container/Container";
import ConfirmationDialog from "@/components/Dialogs/ConfirmationDialog";

export const TopicContext = createContext(null);

function Topic() {
  const { id } = useParams();
  const [topic, setTopic] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [changedStatus, setChangedStatus] = useState(false);
  const { setModal } = useContext(ModalContext);
  const { setErrors } = useContext(ErrorContext);
  const navigate = useNavigate();

  const { t } = useTranslation();

  const maybeTranslate = useTranslatableContent();

  const showInfoToUser = (message) => toast.success(message);

  const getTopic = useCallback(async () => {
    if (!id) {
      return;
    }

    setLoading(true);

    axios
      .get(`/admin/topics/${id}`)
      .then((response) => {
        if (response.status === 200) {
          setTopic(response.data);
        }
      })
      .catch(({ response }) => {
        if (typeof response?.data.errors !== "undefined") {
          let errs = [];

          for (const error in response.data.errors) {
            errs.push([...response.data.errors[error]]);
          }

          setErrors(errs);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, setTopic, setErrors]);

  const backToTopics = () => navigate(topic?.parent_id ? `/topics/${topic?.parent_id}` : '/topics')

  const onTopicUpdate = (updatedTopic) => {
    setTopic(updatedTopic);
  };

  const openEditTopic = () => {
    setModal(<EditTopic topic={topic} updateTopic={onTopicUpdate} />);
  };

  const updateStatus = (status) => {
    const action = status === 'draft' ? 'deactivate' : 'activate';

    axios
      .put(`/admin/topics/${topic.id}/${action}`)
      .then((response) => {
        setTopic((prev) => ({ ...prev, status: response.data.status }));
        showInfoToUser("The card status was successfully changed!");
      })
      .catch(({ response }) => {
        if (typeof response?.data.errors !== "undefined") {
          let errs = [];

          for (const error in response.data.errors) {
            errs.push([...response.data.errors[error]]);
          }

          setErrors(errs);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const saveTopicChanges = useCallback(() => {
    axios
      .post(`/admin/topics/${id}`, topic)
      .then((response) => {
        if (response?.status === 200) {
          showInfoToUser("The topic status was successfully changed!");
        }
      })
      .catch((err) => {
        if (typeof err.response === "undefined") {
          setErrors(["Something went wrong ... please try again."]);
        }

        if (typeof err.response?.data.errors !== "undefined") {
          let errs = [];

          for (const error in err.response.data.errors) {
            errs.push([...err.response.data.errors[error]]);
          }

          setErrors(errs);
        }
      })
      .finally(() => {
        setChangedStatus(false);
      });
  }, [id, topic, setErrors]);

  const deleteTopic = () => {
    axios
      .delete(`/admin/topics/${id}`)
      .then(() => {
        showInfoToUser("The topic was successfully deleted!");
        backToTopics();
      })
      .catch((err) => {
        if (typeof err?.response?.data.errors !== "undefined") {
          let errs = [];

          for (const error in err.response.data.errors) {
            errs.push([...err.response.data.errors[error]]);
          }

          setErrors(errs);
        } else {
          setErrors(["Something went wrong ... please try again."]);
        }
      });
  };

  useEffect(() => {
    getTopic();
  }, [getTopic]);

  useEffect(() => {
    if (!changedStatus) {
      return;
    }
    saveTopicChanges();
  }, [changedStatus, saveTopicChanges]);

  if (loading) return <Loading />;

  if (!topic) return (
    <div>Not found</div>
  );

  return (
    <Container>
      <Header>
        <ButtonBack onClick={backToTopics} />
        <H1>
          {topic ? maybeTranslate(topic?.name) : t('heading.new_topic')}
          <span className="font-normal">{topic?.parent_id ? ` (${topic?.cards_count || 0})` : ` (${topic?.subtopics_count || 0})`}</span>
        </H1>
        <HeaderActions>
          <Button onClick={() => setIsOpened(true)}>
            <img src={deleteIcon} alt="" />
            {t(topic?.parent_id ? 'button.delete_subtopic' : 'button.delete_topic')}
          </Button>
          <Button onClick={openEditTopic}>
            <img src={editIcon} alt="" />
            {t(topic?.parent_id ? 'button.edit_subtopic' : 'button.edit_topic')}
          </Button>
        </HeaderActions>
      </Header>

      <TopicInfo
        className="mb-10"
        topic={topic}
        icon={getIcon(topic?.icon)}
        onStatusChange={updateStatus}
      />

      <TopicContext.Provider value={{ topic, setTopic }}>
        {topic.parent_id ? <TopicQuestionCards /> : <SubTopics />}
      </TopicContext.Provider>

      <ConfirmationDialog
        isOpened={isOpened}
        onClose={() => setIsOpened(false)}
        onProceed={deleteTopic}
      >
        {t(topic?.parent_id ? 'confirm.delete_subtopic' : 'confirm.delete_topic', { name: maybeTranslate(topic?.name) })}
      </ConfirmationDialog>
    </Container>
  );
}

export default Topic;
