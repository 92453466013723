import axios from 'axios'

const baseUrl = process.env.REACT_APP_BASE_URL
const api = axios.create({
  baseURL: baseUrl,
  storageUrl: process.env.REACT_APP_STORAGE_URL
})

api.interceptors.request.use((config) => {
  if (!config.headers.Authorization) {
    const token = localStorage.getItem('auth_token');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
  }

  if ((config.method === 'post' || config.method === 'put') && !config.headers['Content-Type']) {
    config.headers['Content-Type'] = 'application/json'
  }

  config.headers['Accept'] = 'application/json'

  return config
}, (error) => {
  console.log('%cREQUEST ERROR:', 'color: orange;', error)
  return Promise.reject(error)
})

export default api
