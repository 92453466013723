import { cn } from '@/utils/utils';
import './fields.css';

export default function Checkbox ({ checked, className, label, name, onChange }) {
  return (
    <label className={cn('form-group', className)}>
      <input
        checked={checked}
        className="form-control"
        name={name}
        onChange={onChange}
        type="checkbox"
      />
      {label}
    </label>
  );
}
